import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationEnd, NavigationExtras, NavigationStart, Router, UrlTree } from '@angular/router';
import { Log, Path } from '@s8l/client-tree-lib';
import { filter, map } from 'rxjs/operators';

import { TreeService } from './tree.service';
import { JourneyType } from './user-journey.service';
import { LayoutType, MenuTypes } from '../models/menu';
import { MenuMethod, Method, Methods } from '../models/methods';

export interface AppNavigateOpts {
  // quiz params
  opponent?: string;
  invite?: boolean;
  invited?: boolean;
  events?: boolean;
  game?: string;

  // overlay params
  layout?: LayoutType;
  journey?: string;
  journeyStep?: string;
  overlayPath?: Path;

  // forwareded to angular router
  fragment?: string;
  replaceUrl?: boolean;
  skipLocationChange?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppRouterService {
  public methodsTexts = [
    { method: Methods.Legal, url: ['wiki', 'legal'] },
    { method: Methods.WikiArticle, url: ['wiki', 'wiki', 'article'] },
    { method: Methods.Glossary, url: ['wiki', 'glossary', 'article'] },
    { method: Methods.Impuls, url: ['wiki', 'impuls', 'article'] },
    { method: Methods.Casestudy, url: ['casestudy', 'article'] },
    { method: Methods.CasestudyIndex, url: ['casestudy', 'index'] },
    { method: Methods.Journey, url: ['user', 'journey'] },
    { method: Methods.Achievements, url: ['user', 'achievements'] },
    { method: Methods.Podcast, url: ['media', 'podcast'] },
    { method: Methods.Document, url: ['media', 'document'] },
    { method: Methods.Video, url: ['media', 'video'] },
    { method: Methods.QuizMp, url: ['mp'] },
    { method: Methods.QuizSp, url: ['quiz'] },
    { method: Methods.Flashcard, url: ['flashcard'] }
  ];

  constructor(private tree: TreeService, private _router: Router, private _location: Location) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router.routerState.root),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary' && !!route.component),
        map(route => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          const pageTitle = route.component['pageTitle'];
          if (pageTitle && pageTitle instanceof Function) {
            return pageTitle();
          }
          return;
        })
      )
      .subscribe((title: string) => {
        this._pageTitle.emit(title);
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._navigationStarts.emit(event);
      }
    });
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router.routerState.root),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary')
      )
      .subscribe(event => {
        this._navigationEnds.emit(event);
      });
  }

  private _pageTitle = new EventEmitter<string>();
  public get pageTitle() {
    return this._pageTitle.asObservable();
  }

  private _navigationStarts = new EventEmitter<any>();
  public get navigationStarts() {
    return this._navigationStarts.asObservable();
  }

  private _navigationEnds = new EventEmitter<any>();
  public get navigationEnds() {
    return this._navigationEnds.asObservable();
  }

  public get router() {
    return this._router;
  }

  public getMethodFromURL(): Method | undefined {
    for (const m of this.methodsTexts) {
      if (this.router.url.includes(m.url.join('/'))) {
        return m.method;
      }
    }
    return undefined;
  }

  public navigateBack() {
    // Provide the back animation.
    this._location.back();
  }

  public navigateHome() {
    return this._router.navigate(['/menu']);
  }

  public navigateRaw(url: any[], extras?: NavigationExtras) {
    return this._router.navigate(url, extras);
  }

  public navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions) {
    return this._router.navigateByUrl(url, extras);
  }

  public async navigatePath(path, method: Method = null) {
    const url = await this.getMenuURL(path, method);
    return this.router.navigate(url);
  }

  public async navigate(method: MenuMethod, opts: AppNavigateOpts = {}) {
    let destination = [];
    const params: any = {};

    opts = opts || {};

    switch (method.page) {
      case 'legal':
        destination = ['wiki', 'legal', 'article', method.path.uri()];
        break;
      case 'wiki':
        destination = ['wiki', 'wiki', method.path.uri()];
        break;
      case 'article':
        destination = ['wiki', 'wiki', 'article', method.path.uri()];
        break;
      case 'mp':
        destination = ['mp'];
        if (opts.opponent && opts.invite) {
          opts = { ...opts, layout: LayoutType.App };
          destination = ['mp', 'invite', opts.opponent];
          break;
        }
        if (opts.opponent && opts.invited) {
          opts = { ...opts, layout: LayoutType.App };
          destination = ['mp', 'y', opts.opponent];
          break;
        }
        if (opts.game) {
          opts = { ...opts, layout: LayoutType.App };
          destination = ['mp', opts.game];
          break;
        }
        break;
      case 'podcast':
        destination = ['media', 'podcast', method.path.uri()];
        break;
      case 'document':
        destination = ['media', 'document', method.path.uri()];
        break;
      case 'video':
        destination = ['media', 'video', method.path.uri()];
        break;
      case 'glossary':
        destination = ['wiki', 'glossary', 'article', method.path.uri()];
        break;
      case 'achievements':
        destination = ['user', 'achievements'];
        if (opts.events) {
          destination.push('events');
        }
        opts = { ...opts, layout: LayoutType.App };
        break;
      case 'impuls':
        destination = ['wiki', 'impuls', 'article', method.path.uri()];
        break;
      case 'casestudy':
        destination = ['casestudy', 'article', method.path.uri()];
        break;
      case 'casestudy-index':
        destination = ['casestudy', 'index', method.path.uri()];
        break;
      case 'journey':
        destination = ['user', 'journey'];
        if (opts.journey) {
          destination.push(JourneyType.Journey);
          destination.push(opts.journey);
        } else if (method.path) {
          destination.push(JourneyType.Node);
          destination.push(method.path.uri());
        }
        break;
      case 'ranking':
        destination = ['user', 'ranking'];
        if (method.path) {
          destination.push(method.path.uri());
        }
        if (opts.invite) {
          destination.push('invite');
        }
        break;
      case 'review':
        if (opts.game) {
          destination = ['quiz', 'review', 'question', method.path?.uri(), opts.game];
          opts.layout = LayoutType.App;
          break;
        } else {
          destination = ['quiz', 'review', method.path?.uri()];
          break;
        }
      case 'exam':
        destination = ['quiz', 'question', 'exam', method.path?.uri(), opts.game];
        opts.layout = LayoutType.App;
        break;

      case 'quiz':
        if (opts.game) {
          destination = ['quiz', 'question', 'sp', method.path?.uri(), opts.game];
          opts.layout = LayoutType.App;
          break;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        destination = [method.page, method.path?.uri()];
        break;
    }

    let type: MenuTypes | LayoutType;
    if (opts.layout != undefined) {
      type = opts.layout;
    } else if (method.path) {
      type = await this.getMenuType(method.path);
    }
    if (opts.journey) {
      type = LayoutType.Overlay;
    }
    switch (type) {
      case LayoutType.Overlay:
        if (opts.journey) {
          destination = ['overlay', 'journey', opts.journey, opts.journeyStep || 0, ...destination];
        } else {
          destination = ['overlay', 'node', (opts.overlayPath || method.path).uri(), ...destination];
        }
        break;
      default:
        break;
    }

    if (opts.replaceUrl) {
      params.replaceUrl = opts.replaceUrl || true;
    }
    if (opts.skipLocationChange) {
      params.replaceUrl = opts.skipLocationChange;
    }
    if (opts.fragment) {
      params.fragment = opts.fragment;
    }

    Log.log('Service navigates to', destination);

    return this._router.navigate(destination, params);
  }

  public getMenuType(path: Path): Promise<MenuTypes | LayoutType> {
    if (!this.tree?.config) {
      return Promise.resolve(MenuTypes.Tree);
    }
    for (let i = path.length; i >= 0; i--) {
      const rule = this.tree.config.findRuleForLevel(i, []);
      if (rule.menu) {
        return Promise.resolve(<MenuTypes | LayoutType>rule.menu);
      }
    }
    return Promise.resolve(MenuTypes.Tree);
  }

  private async getMenuURL(path: Path, method: Method = null) {
    const type = await this.getMenuType(path);
    switch (type) {
      case LayoutType.Overlay:
        return ['overlay', 'node', path.uri(), 'wiki', 'wiki', 'article', path.uri()];
      default: {
        const url = ['menu'];
        if (method) {
          url.push(method.name);
        }
        url.push(path.uri());
        return url;
      }
    }
  }
}
