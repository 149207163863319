<ng-container *transloco="let t;">
  <div class="mt-6" *ngIf="scoreMax" aria-hidden="true">
    <div class="overflow-hidden rounded-full bg-light-grey">
      <div
        class="overflow-hidden rounded-full bg-gradient-to-r from-primary to-secondary p-1 text-center text-primary-contrast"
        [style.width]="scoreScale"
      >
        {{score}} / {{scoreMax}} {{t('quiz.points')}}
      </div>
    </div>
    <div class="mt-2 hidden grid-cols-3 text-sm font-medium text-grey md:grid">
      <h4 class="text-primary">{{t('quiz.beginner')}}</h4>
      <h4 class="text-primary">{{t('quiz.intermediate')}}</h4>
      <h4 class="text-primary">{{t('quiz.expert')}}</h4>
    </div>
  </div>
  <h3 class="mt-7">{{t('quiz.last-games')}}</h3>
  <div class="mb-5 flex w-full flex-col items-center gap-x-4 md:flex-row" *ngFor="let score of lastScores">
    <div class="flex w-full items-center gap-3 md:w-1/3">
      <fa-icon
        class="text-3xl"
        *ngIf="score.type === 'default' || score.type === 'casestudy' || score.type === 'multiplayer'"
        [icon]="['fal', 'question-circle']"
      ></fa-icon>
      <fa-icon class="text-3xl" *ngIf="score.type === 'exam'" [icon]="['fal', 'graduation-cap']"></fa-icon>
      <span class="capitalize">{{score.finish}} - {{score.title}}</span>
    </div>
    <div class="my-3 h-[32px] w-full overflow-hidden rounded-full bg-light-grey md:w-2/3" lines="none">
      <div
        class="h-[32px] rounded-full bg-gradient-to-r from-primary to-secondary p-1 text-center text-primary-contrast"
        [style.width]="score.score.precentage + '%'"
      >
        {{score.score.score}} / {{score.score.total_score}} {{t('quiz.points')}}
      </div>
    </div>
  </div>
</ng-container>
