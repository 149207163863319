<div class="relative z-50" @popupAnimation *ngIf="pop.loading && pop.loading.shown" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-grey bg-opacity-75"></div>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center text-center sm:items-center">
      <div class="rounded-[30px] bg-white p-6 shadow-xl">
        <span class="text-base font-semibold leading-6 text-prose">
          <fa-icon class="mr-2" [icon]="['fal','spinner-third']" animation="spin" size="lg"></fa-icon>
          {{ pop.loading.opts.message || 'Loading...' }}
        </span>
        <div class="mt-5 sm:mt-6" *ngIf="pop.loading.opts.cancel">
          <button
            class="s8l-button s8l-b-clear inline-flex w-full justify-center shadow-md"
            *transloco="let t"
            type="button"
            (click)="onLoadingDismiss()"
          >
            {{ t('app.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative z-50" @popupAnimation *ngIf="pop.alert && pop.alert.shown" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-grey bg-opacity-75"></div>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden rounded-[30px] bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
      >
        <div class="text-center">
          <h3 class="text-base font-semibold leading-6 text-prose" id="modal-title">{{ pop.alert.opts.header }}</h3>
          <div class="mt-2">
            <p class="text-sm text-grey">{{ pop.alert.opts.message }}</p>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button class="s8l-button s8l-b-clear inline-flex w-full justify-center shadow-md" type="button" (click)="onAlertDismiss()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="relative z-50" @popupAnimation *ngIf="pop.modal && pop.modal.shown" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-grey bg-opacity-75"></div>
  <div class="fixed inset-0 z-50 overflow-y-auto" [class.flex]="pop.modal.opts.fullscreen">
    <ng-template appModalHost [modal]="pop.modal.opts"></ng-template>
  </div>
</div>

<div class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:px-6 sm:pt-20" aria-live="assertive">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-[30px] bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      *ngFor="let toast of pop.toast"
      @toastAnimation
    >
      <div class="p-4" (click)="onToastClick(toast)" [attr.role]="toast.opts.link ? 'button' : 'none'">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <fa-icon [class]="toastColors[toast.opts.color]" [icon]="['fal',toastIcons[toast.opts.color]]" size="lg"></fa-icon>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-prose">{{ toast.opts.header }}</p>
            <p class="mt-1 text-sm text-grey" *ngIf="toast.opts.message">{{ toast.opts.message }}</p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              class="inline-flex rounded-md bg-white text-grey hover:text-grey focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              type="button"
              (click)="toast.dismiss()"
            >
              <span class="sr-only">Close</span>
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
