import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';

@NgModule({
  imports: [FontAwesomeModule]
})
export class IconModule {
  constructor(library: FaIconLibrary) {
    // all of fal
    library.addIconPacks(fal as any);

    // specific of far
    library.addIcons(faChevronLeft as any);
  }
}
