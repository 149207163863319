import * as Bowser from 'bowser';

// See https://github.com/lancedikson/bowser#filtering-browsers
export const BROWSER_MIN_VERSIONS = {
  safari: '>=12'
};

class BrowserDetection {
  private browser = Bowser.getParser(window.navigator.userAgent);

  public IsRecent(): boolean {
    const satisfies = this.browser.satisfies(BROWSER_MIN_VERSIONS);
    return satisfies === undefined || satisfies;
  }

  public IsInternetExplorer(): boolean {
    return this.browser.isBrowser('Internet Explorer');
  }

  public IsSafari(): boolean {
    return this.browser.isBrowser('Safari');
  }

  public GetPlatformType() {
    return this.browser.getPlatformType();
  }
}

export const Browser = new BrowserDetection();
