<app-header [noElements]="true">
  <button class="s8l-button s8l-b-header" (click)="closeModal()">
    <fa-icon [icon]="['fal', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
  </button>
</app-header>

<swiper
  class="swiper-wrapper"
  [zoom]="true"
  *ngIf="imgSource || svgSource"
  [ngStyle]="{'background-color' : svgSource ? 'white' : '#222428'}"
>
  <ng-template swiperSlide>
    <div class="swiper-zoom-container">
      <img *ngIf="imgSource" [src]="imgSource" />
    </div>
    <div class="swiper-zoom-container svg-container" *ngIf="svgSource" #svgContainer [innerHTML]="svgSource"></div>
  </ng-template>
</swiper>
