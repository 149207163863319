import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidemenuService {
  private _shown = new BehaviorSubject<boolean>(false);
  public get shown(): Observable<boolean> {
    return this._shown;
  }

  public show() {
    if (!this._shown.value) {
      this._shown.next(true);
    }
  }

  public hide() {
    if (this._shown.value) {
      this._shown.next(false);
    }
  }

  public toggle() {
    this._shown.next(!this._shown.value);
  }
}
