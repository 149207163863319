import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { IconModule } from './icon.module';
import { PopupModule } from './modules/popup/popup.module';
import { SidemenuModule } from './modules/sidemenu/sidemenu.module';
import { OverlayPage } from './pages/overlay/overlay.page';
import { AlertErrorHandler } from './services/alerterrorhandler.service';
import { ServiceWorkerService, registerServiceWorker } from './services/service-worker.service';
import { TranslocoRootModule } from './transloco-root.module';
import { fetchConfig } from '../environments/environment';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent, OverlayPage],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    IconModule,
    ComponentsModule,
    TranslocoRootModule,
    SidemenuModule,
    PopupModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: fetchConfig, multi: true, deps: [HttpClient] },
    { provide: APP_INITIALIZER, useFactory: registerServiceWorker, multi: true, deps: [ServiceWorkerService] },
    { provide: ErrorHandler, useClass: AlertErrorHandler },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
