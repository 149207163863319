import { Component, Input } from '@angular/core';

import { EnviromentService } from 'src/app/services/environment.service';
import { TreeService } from 'src/app/services/tree.service';

@Component({
  selector: 'app-resource-icon',
  templateUrl: './resource-icon.component.html',
  styleUrls: ['./resource-icon.component.scss']
})
export class ResourceIconComponent {
  @Input() public resources;
  @Input() public size = 'sm';

  public get res() {
    const res: any = {};
    for (const r of this.resources) {
      res[r] = true;
    }
    return res;
  }

  constructor(private env: EnviromentService) {}

  public hasFeature(feature: string) {
    return this.env.hasFeature(feature);
  }
}
