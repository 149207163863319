import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { AppFeatures, Log, Path, TreeNode } from '@s8l/client-tree-lib';
import { filter } from 'rxjs/operators';

import { EnviromentService } from './environment.service';
import { AchievementServerService, AchievementUserProgress } from './jsonrpc/achievement-server.service';
import { StatsServerService } from './jsonrpc/stats-server.service';
import { UserNodeContentServerService } from './jsonrpc/user-node-content-server.service';
import { NotificationService } from './notification.service';
import { TreeService } from './tree.service';
import { PopupService, ToastColors } from '../modules/popup/popup.service';

export interface Label {
  visited: boolean;
  method: AppFeatures | AppFeatures[];
}

export enum JourneyType {
  Node = 'node',
  Journey = 'journey'
}

export interface JourneyStep {
  path: Path;
  uuid: string;
  parent: string;
  node: TreeNode;
  description: string;
  achieved: number;
  children: JourneyStep[];
}

export interface Journey {
  name: string;
  uuid: string;
  type: JourneyType;
  achieved: number;
  children: JourneyStep[];
}

export interface ActiveJourney extends Journey {
  next?: TreeNode | JourneyStep;
}

export interface JourneySelector {
  type: JourneyType;
  path?: Path;
  uuid?: string;
}

export interface Achievement {
  uuid: string;
  achieved: number;
  value: number;
  unlock_value: number;
  repeatable: boolean;
  title: string;
  description: string;
  config?: { checked?: number };
  event?: { start: string; end: string };
}

@Injectable({
  providedIn: 'root'
})
export class UserJourneyService {
  private _allEvents: AchievementUserProgress[] = [];

  private _journeys: Journey[] = [];
  public get journeys(): Journey[] {
    return [...this._journeys];
  }

  private _achievements: Achievement[] = [];
  public get achievements(): Achievement[] {
    return [...this._achievements];
  }

  // TODO: re-introduce??
  private _journeyOpenNodes: string[] = []; // node uuids
  public get journeyOpenNodes(): string[] {
    return [...this._journeyOpenNodes];
  }

  constructor(
    private pop: PopupService,
    private env: EnviromentService,
    private tree: TreeService,
    private stats: StatsServerService,
    private notification: NotificationService,
    private userNode: UserNodeContentServerService,
    private achievement: AchievementServerService
  ) {}

  public init() {
    this.notification.notifications
      .pipe(
        filter(n => {
          return n.topic == 'notification:achievement';
        })
      )
      .subscribe(notif => {
        void this.handleNotification(notif.params.payload);
      });

    return this.fetchAchievments();
  }

  public async fetchAchievments() {
    const list = await this.achievement.achievementList({ lang: this.env.language });

    if (!list || list.length <= 0) {
      return;
    }

    if (this.env.hasFeature(AppFeatures.Achievement)) {
      await this.updateAchievements(list);
    }

    if (this.env.hasFeature(AppFeatures.Journey)) {
      await this.updateProgress(list);
    }
  }

  public async getJourney(sel: JourneySelector): Promise<ActiveJourney> {
    switch (sel.type) {
      case JourneyType.Journey:
        return this.getJourneyByUuid(sel.uuid);
      case JourneyType.Node:
        return this.getJourneyByPath(sel.path);
    }
  }

  public async checkAchievement(a: Achievement) {
    a.config = { ...a?.config, checked: a?.config?.checked + 1 || 1 };
    await this.setConfig(a);
  }

  public deactivateToast(a: Achievement) {
    const stored = this.getHiddenFromStorage();
    if (!stored.includes(a.uuid)) {
      stored.push(a.uuid);
    }
    localStorage.setItem('achievements', JSON.stringify(stored));
  }

  public getTagsForNodeRecursive(n: TreeNode) {
    return this.userNode
      .nodeGetRecursive({
        parent: true,
        path: n.path,
        recursive: false
      })
      .catch(err => {
        Log.warn('UserJourneyService', 'failed to fetch node tags', err);
        return [];
      });
  }

  public getLabelsForNode(n: TreeNode) {
    return this.userNode.nodeGetLabels(Path.parse(n.path));
  }

  public setLabelsforNode(n: TreeNode, lab: Label) {
    return this.getLabelsForNode(n)
      .then(l => {
        const methods = l?.methods || [];
        if (methods.includes(lab.method)) {
          return this.userNode.nodeSetLabels(Path.parse(n.path), { visited: lab.visited, methods: methods });
        }
        return this.userNode.nodeSetLabels(Path.parse(n.path), { visited: lab.visited, methods: [...methods, lab.method] });
      })
      .catch(err => console.warn('Failed to set node labels', err));
  }

  public async getLastNodes(k = 5) {
    const latest = await this.stats.statsRecentEvents({
      groupBy: ['path'],
      format: 'iso',
      source: 'event',
      exclude: {
        feature: ['menu', 'none', 'other'],
        path_segment_1: ''
      },
      limit: k + 15
    });

    if (!latest) {
      return [];
    }

    const latestMap: { [index: string]: TreeNode } = {};
    const maxDepth = this.tree.config.maxDepth();
    for (const l of latest) {
      if (!l.path.startsWith(this.env.eventPrefix + '/')) {
        // only eat nodes that belong to our app
        continue;
      }

      const path = Path.parse(l.path.replace(this.env.eventPrefix + '/', ''));
      const node = this.tree.get(path);
      if (!node || latestMap[node.uuid]) {
        // skip undefined nodes & nodes we already have
        continue;
      }

      if (path.length - 2 >= maxDepth) {
        // skip nodes != module level
        continue;
      }

      latestMap[node.uuid] = node;
    }

    return Object.values(latestMap).slice(0, k);
  }

  private updateAchivedStep(current: JourneyStep, step: string, achieved: number) {
    if (current.uuid == step) {
      current.achieved = achieved;
      return;
    }
    if (current.children.length) {
      for (const c of current.children) {
        this.updateAchivedStep(c, step, achieved);
      }
      if (current.children.every(s => s.achieved > 0)) {
        current.achieved = 1;
      }
    }
  }

  private async handleNotification(params: any) {
    if (params.type == 'journey') {
      for (const j of this._journeys) {
        for (const c of j.children) {
          this.updateAchivedStep(c, params.uuid, params.achieved);
        }
        if (j.children.every(s => s.achieved > 0)) {
          j.achieved = 1;
        }
      }
      return;
    }

    if (params.effects.length > 0) {
      const notify = params.effects.filter(e => e.type == 'notification')[0];

      if (notify) {
        const toast = await this.pop.createToast({
          header: notify.value[this.env.language || 'de'].title,
          message: notify.value[this.env.language || 'de'].message?.replace(/\n/g, '<br/>'),
          color: ToastColors.Success,
          link: ['user', 'achievement'],
          duration: 20000
        });
        await toast.present();
      }
    }

    return this.updateAchievement(params.uuid, { achieved: params.achieved });
  }

  private async getJourneyByPath(path: Path): Promise<ActiveJourney> {
    const node = this.tree.get(path);
    const journey: ActiveJourney = {
      uuid: node.uuid,
      name: node.name,
      type: JourneyType.Node,
      achieved: 1,
      children: []
    };

    const tags = await this.getTagsForNodeRecursive(node);
    const children = [...node.children].sort((a, b) => a.metric - b.metric);
    for (const c of children) {
      const achieved = tags.findIndex(r => r.uuid == c.uuid) != -1 ? 1 : 0;
      if (achieved == 0) {
        journey.achieved = 0;
      }
      journey.children.push({
        path: Path.parse(c.path).removeFirst(),
        uuid: c.uuid,
        parent: journey.uuid,
        node: c,
        description: c.description,
        achieved: achieved,
        children: []
      });
    }

    journey.next = journey.children.find(s => s.achieved <= 0)?.node;
    if (!journey.next) {
      const parent = this.tree.get(path.removeLast());
      const sibling = parent?.children?.find(c => c.metric > node.metric && c.children.length);
      if (sibling) {
        journey.next = sibling.children[0];
      }
    }

    return journey;
  }

  private async getJourneyByUuid(uuid: string): Promise<ActiveJourney> {
    const journey = this.journeys.find(j => j.uuid == uuid);
    const next = this.findNext(journey.children);
    return Promise.resolve({
      ...journey,
      next
    });
  }

  private findNext(children: JourneyStep[]): JourneyStep {
    for (const c of children) {
      const node = this.findNext(c.children);
      if (node) {
        return node;
      }
      if (c.achieved <= 0) {
        return c;
      }
    }
    return undefined;
  }

  private async buildJourney(j: AchievementUserProgress): Promise<Journey | null> {
    return Promise.all(j.children.map(s => this.buildJourneyStep(s)))
      .then(children => {
        return {
          name: j.name,
          uuid: j.uuid,
          type: JourneyType.Journey,
          achieved: j.achieved,
          children
        };
      })
      .catch(err => {
        // If we cannot access some of the required nodes there is no guarantee the journey works as expected, so it is filtered completely.
        if (err?.message === 'Not found') return null;
        throw err;
      });
  }

  private async buildJourneyStep(step: AchievementUserProgress): Promise<JourneyStep> {
    const node = this.tree.getByUUID(step.meta.node);
    if (node == null) {
      // Most likely missing access permissions.
      throw new Error('Not found');
    }
    return {
      uuid: step.uuid,
      parent: step.parent,
      path: Path.parse(node.path).removeFirst(),
      node,
      description: node.description,
      achieved: step.achieved,
      children: await Promise.all(step.children.map(s => this.buildJourneyStep(s)))
    };
  }

  private async updateAchievements(all: AchievementUserProgress[]) {
    const achievements = all
      .filter(u => u.type == 'achievement')
      .map(j => {
        return {
          uuid: j.uuid,
          achieved: j.achieved,
          value: j.value,
          unlock_value: j.unlock_value,
          repeatable: j.repeatable,
          event: { start: j.event_start || null, end: j.event_end || null },
          title: j.i18n[this.env.language]?.title || j.i18n[Object.keys(j.i18n)[0]]?.title || '',
          description: j.i18n[this.env.language]?.description || j.i18n[Object.keys(j.i18n)[0]]?.description || '',
          config: j.config
        };
      });

    this._achievements = achievements;

    const now = Date.now();
    const hidden = this.getHiddenFromStorage();
    const toasts = this._achievements.filter(
      a =>
        a.value < a.unlock_value &&
        !hidden.includes(a.uuid) &&
        (!a.event?.start || (a.event?.start && Date.parse(a.event?.start) < now)) &&
        (!a.event?.end || (a.event?.end && Date.parse(a.event.end) > now))
    );

    if (toasts.length > 3) {
      const toast = await this.pop.createToast({
        header: translate('achievement.open'),
        message: translate('achievement.openAchievements', { number: toasts.length }),
        color: ToastColors.Success,
        link: ['user', 'achievement'],
        duration: toasts.length * 5000
      });

      await toast.present();
    } else {
      for (const achievement of toasts) {
        const toast = await this.pop.createToast({
          header: achievement.title,
          message: achievement.description,
          color: ToastColors.Success,
          link: ['user', 'achievement'],
          duration: toasts.length * 5000
        });
        await toast.present();
      }
    }

    for (const achievement of toasts) {
      this.deactivateToast(achievement);
    }
  }

  private async updateProgress(all: AchievementUserProgress[]) {
    const journeys: Promise<Journey>[] = all.filter(u => u.type == 'journey').map(async j => this.buildJourney(j));

    this._journeys = (await Promise.all(journeys)).filter(j => j);
    this._allEvents = all;
  }

  private updateAchievement(uuid: string, entry: Partial<Achievement>) {
    const index = this._achievements.findIndex(a => a.uuid == uuid);
    if (index == -1) {
      // not found, re-fetch from server
      return this.achievement.achievementList({ lang: this.env.language }).then(list => this.updateAchievements(list));
    }
    this._achievements[index] = {
      ...this._achievements[index],
      ...entry
    };
  }

  private async setConfig(a: Achievement) {
    await this.achievement.achievementSetConfig({
      uuid: a.uuid,
      config: a.config
    });
    await this.updateAchievement(a.uuid, { config: a.config });
  }

  private getHiddenFromStorage(): string[] {
    try {
      return JSON.parse(localStorage.getItem('achievements') || '[]');
    } catch (err) {
      return [];
    }
  }
}
