import { Injectable, NgZone } from '@angular/core';
import { Deferred, Log, UUID, WebsocketClient, WebsocketService } from '@s8l/client-tree-lib';
import { Subject, Subscription } from 'rxjs';

import { EnviromentService } from './environment.service';
import { NotificationServerService } from './jsonrpc/notification-server.service';
import { Browser } from '../browser';

export interface Notification {
  topic: string;
  title: string;
  message: string;
  payload: any;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private websocketSubscription: Subscription;
  private ws: WebsocketClient;

  private _deferredId: Deferred<string>;
  private _notifications: Subject<{ topic: string; params: Notification }> = new Subject();

  public get notifications() {
    return this._notifications.asObservable();
  }

  constructor(
    private server: NotificationServerService,
    private zone: NgZone,
    private websocket: WebsocketService,
    private env: EnviromentService
  ) {}

  public async deviceId(): Promise<string> {
    if (!this._deferredId) {
      this._deferredId = new Deferred<string>();
      void this.createUUID();
    }
    return this._deferredId.promise;
  }

  public status(): Promise<any> {
    return this.server.notificationStatus({});
  }

  public update(status): Promise<any> {
    return this.server.notificationUpdate(status);
  }

  public async init() {
    const deviceId = await this.deviceId();
    await this.initWebsocket(deviceId);
  }

  public stop(): Promise<void> {
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
      if (this.ws) {
        this.ws.disconnect();
      }
    }
    // Close the notificationstream...
    this._notifications.complete();
    return Promise.resolve();
  }

  private initWebsocket(id) {
    return this.websocket.connect(this.env.getWebsocketUrl('NOTIFICATION_SERVER'), 'NotificationWebsocket').then(client => {
      this.ws = client;
      this.websocketSubscription = this.ws.onRequest().subscribe(msg => {
        switch (msg.method) {
          case 'notification':
            this.onNotification(msg.params);
            void this.ws.respond(msg.id, 'ACK');
            break;

          default:
            Log.log('NotificationService', msg);
            break;
        }
      });
      return this.ws.send('register', {
        type: 'websocket',
        target: id
      });
    });
  }

  private onNotification(notification: Notification) {
    Log.log('NotificationService', 'notification:' + notification.topic, notification);

    return this.zone.run(() => {
      return this._notifications.next({
        topic: 'notification:' + notification.topic,
        params: notification
      });
    });
  }

  private createUUID(canAskForPermission = false) {
    const promiseTimeout = (promise, ms) => {
      const timeout = new Promise((resolve, reject) => {
        const id = setTimeout(() => {
          clearTimeout(id);
          reject('notification permission timeout');
        }, ms);
      });
      return Promise.race([promise, timeout]);
    };

    const fakeToken = () => {
      let uuid = localStorage.getItem('browser-uuid');
      if (!uuid) {
        uuid = UUID.random();
        localStorage.setItem('browser-uuid', uuid);
      }
      this._deferredId.resolve(uuid);
    };

    // if Notification.requestPermission() is called (firebase.requestToken calls this also) without user interaction
    // eg on page reload, the promise never resolves and blocks this code forever, causing the page to hang.
    // no error is thrown and no other indication of anything being wrong is emitted, basically its a bug
    // we try to workaround it by only ever calling it if its "allowed".

    // wrap NotificationApi for iOS, lets us use the ? synatx in the following
    const notificationApi = window.Notification || undefined;
    const hasNotificationApi =
      !Browser.IsSafari() &&
      notificationApi &&
      notificationApi?.requestPermission &&
      typeof notificationApi?.requestPermission == 'function';

    let promise: Promise<any> = Promise.resolve();
    if (hasNotificationApi && notificationApi.permission !== 'granted' && canAskForPermission) {
      // ONLY REQUEST PERMISSION IF WE ARE ALLOWED; OTHERWISE IT WILL BLOCK FOR SURE
      Log.log('NotificationService', 'asking for permission');
      promise = promise.then(() => notificationApi?.requestPermission());
    }
    if (hasNotificationApi && (notificationApi.permission === 'granted' || canAskForPermission)) {
      // EITHER WE HAVE PERMISSION OR WE ARE ALLOWED TO ASK FOR IT; REQUEST TOKEN
      promise = promise.then(() => {
        if (hasNotificationApi && notificationApi.permission != 'granted') {
          Log.log('NotificationService', 'permission not granted');
          return fakeToken();
        }

        /* TODO: BUILD IT BETTER.
        Log.log('NotificationService', 'requesting firebase token');
        this.firebase.requestToken.subscribe(
          token => {
            if (token == null) {
              console.warn('firebase token was null');
              return fakeToken();
            }
            this._deferredId.resolve(token);
          },
          err => {
            console.warn(err);
            return fakeToken();
          }
        );
        */
        return fakeToken();
      });
    } else {
      // NOT ALLOWED TO REQUEST PERMISSION
      promise = promise.then(() => fakeToken());
    }

    // APPLY A TIMEOUT CAUSE FUCK KNOWS
    return promiseTimeout(promise, 5000)
      .catch(err => {
        console.warn(err);
        return fakeToken();
      })
      .finally(() => {
        Log.log('NotificationService', 'createUUID done');
      });
  }
}
