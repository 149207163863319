import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import confetti from 'canvas-confetti';
import { formatDuration, intervalToDuration } from 'date-fns';

import { Achievement, UserJourneyService } from 'src/app/services/user-journey.service';

@Component({
  selector: 'app-achievement-card',
  templateUrl: './achievement-card.component.html',
  styleUrls: ['./achievement-card.component.scss']
})
export class AchievementCardComponent implements OnInit, OnDestroy {
  @Input() public achievement: Achievement;
  @Input() public index: number;
  @Input() public unlocked = false;

  constructor(private userjourney: UserJourneyService) {}

  public get checked() {
    return (
      (this.achievement.config?.checked && !this.achievement.repeatable) ||
      (this.achievement.repeatable && this.achievement.config.checked >= this.achievement.achieved)
    );
  }

  public countdownInterval = null;

  public countdown = {
    hasCountdown: false,
    active: false,
    text: '',
    over: false
  };

  public ngOnInit() {
    if (this.achievement.event.start || this.achievement.event.end) {
      return this.eventCountdowns();
    }
  }

  public ngOnDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  public sparkles(event: PointerEvent) {
    if (this.checked) {
      return;
    }

    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      origin: {
        x: event.clientX / window.innerWidth,
        // since they fall down, start a bit higher than random
        y: event.clientY / window.innerHeight
      }
    });

    return this.checkAchievement();
  }

  private checkAchievement() {
    if (this.checked) {
      return;
    }

    return this.userjourney
      .checkAchievement(this.achievement)
      .then(() => (this.achievement.config = { checked: this.achievement.config?.checked + 1 || 1 }))
      .catch(err => {
        if (err.code == -32010) {
          return this.checkAchievement();
        }
      });
  }

  public parseToPercentage(a: Achievement) {
    return `${Math.floor((a.value / a.unlock_value) * 100)}%`;
  }

  private eventCountdowns() {
    const startTimestamp = new Date(this.achievement.event.start);
    const endTimestamp = new Date(this.achievement.event.end);
    const now = new Date();

    if (now < startTimestamp) {
      this.updateCountdown(startTimestamp);
      this.countdown.hasCountdown = true;
      this.countdownInterval = setInterval(() => {
        this.updateCountdown(startTimestamp);
      }, 1000);

      return;
    }

    if (now < endTimestamp) {
      this.updateCountdown(endTimestamp);
      this.countdown.hasCountdown = true;
      this.countdown.active = true;
      this.countdownInterval = setInterval(() => {
        this.updateCountdown(endTimestamp);
      }, 1000);
    }

    if (now > endTimestamp) {
      this.countdown.hasCountdown = true;
      this.countdown.over = true;
    }
  }

  private updateCountdown(timestamp: Date) {
    const now = new Date();

    if (timestamp < now) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.countdown = {
        hasCountdown: false,
        active: false,
        text: '',
        over: false
      };
      return this.eventCountdowns();
    }

    const format = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'];
    const duration = intervalToDuration({
      start: now,
      end: timestamp
    });
    if (!duration.minutes || duration.minutes < 2) {
      format.push('seconds');
    }

    let text = formatDuration(duration, { format });
    if (!text || !text.length) {
      text = translate('achievement.immediately');
    }
    this.countdown.text = text;
  }
}
