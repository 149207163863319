import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnChanges {
  @ViewChild('video') public videoRef: ElementRef;

  @Output() public onEnded = new EventEmitter<boolean>();
  @Input() public urls: string[] = [];

  public index = 0;

  private playPromise: Promise<void> = undefined;
  private needsReload = true;
  private get video(): HTMLVideoElement {
    return this.videoRef?.nativeElement;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.urls) {
      this.needsReload = true;
    }
  }

  public async play() {
    if (this.playPromise != undefined) {
      await this.playPromise;
      this.playPromise = undefined;
    }
    if (this.needsReload) {
      this.video.pause();
      this.video.load();
    }
    this.playPromise = this.video.play();
    this.needsReload = false;
  }

  public pause() {
    this.video.pause();
  }

  public async next() {
    this.index += 1;
    if (this.index >= this.urls.length) {
      this.index = 0;
    }
    this.needsReload = true;
    await this.play();
  }

  public async prev() {
    this.index -= 1;
    if (this.index < 0) {
      this.index = this.urls.length - 1;
    }
    this.needsReload = true;
    await this.play();
  }

  public emitEnded() {
    return this.onEnded.emit(true);
  }
}
