import { Injectable } from '@angular/core';
import { Path } from '@s8l/client-tree-lib';

import { MediaImageVariantRequest, MediaPresignedUuidResponse, MediaServerService } from './jsonrpc/media-server.service';

export enum CacheState {
  Idle = 0,
  Loading,
  Done
}

export const IMAGE_DEFAULT_WIDTH = 1920;
export const IMAGE_DEFAULT_HEIGHT = 1200;

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  private _urls: { [s: string]: undefined | Promise<string> } = {};
  private _times: { [s: string]: number } = {};
  private _state: { [s: string]: CacheState } = {};
  private _imageBatchPromise: Promise<MediaPresignedUuidResponse[]>;
  private _imageBatch: MediaImageVariantRequest[] = [];

  constructor(private media: MediaServerService) {}

  public loading(path: Path): boolean {
    const uri = path.uri();
    return this._state[uri] == CacheState.Loading;
  }

  public done(path: Path): boolean {
    const uri = path.uri();
    return this._state[uri] == CacheState.Done;
  }

  public parseUrl(path: string): Promise<string> {
    return this.url(Path.parse(path));
  }

  private queueImage(req: MediaImageVariantRequest) {
    if (this._imageBatchPromise == undefined) {
      this._imageBatchPromise = new Promise<void>(resolve => setTimeout(() => resolve(), 10)).then(() => {
        const req = this.media.mediaGetImages(this._imageBatch);
        this._imageBatchPromise = undefined;
        this._imageBatch = [];
        return req;
      });
    }
    const index = this._imageBatch.length;
    this._imageBatch.push(req);
    return this._imageBatchPromise.then(res => res[index]);
  }

  public image(path: Path, width = IMAGE_DEFAULT_WIDTH, height = IMAGE_DEFAULT_HEIGHT): Promise<string> {
    const key = `${path.uri()}-${width}-${height}`;

    const cache = this.getCache(key);
    if (cache != undefined) {
      return cache;
    }

    const handle = this.queueImage({
      ...this.getPathOrUUID(path),
      width,
      height
    }).then(res => res.url);

    return this.updateCache(key, handle);
  }

  public parseImage(path: string, width = IMAGE_DEFAULT_WIDTH, height = IMAGE_DEFAULT_HEIGHT): Promise<string> {
    return this.image(Path.parse(path), width, height);
  }

  public url(path: Path, attachment = false): Promise<string> {
    const key = path.uri();

    const cache = this.getCache(key);
    if (cache != undefined) {
      return cache;
    }

    const identifier = this.getPathOrUUID(path);
    const handle = this.media
      .mediaGet({
        ...identifier,
        attachment
      })
      .catch(err => {
        if (err.code == -32602) {
          // invalid params, use fallback
          return this.media.mediaGetByUuid({ ...identifier });
        }
        throw err;
      })
      .then(res => res.url);

    return this.updateCache(key, handle);
  }

  private getPathOrUUID(path: Path) {
    if (path.get().length === 1 && /(\w+-?)+/.test(path.getFirst())) {
      return {
        uuid: path.getFirst()
      };
    } else {
      return {
        path: path.uri()
      };
    }
  }

  private getCache(key: string): Promise<string> | undefined {
    if (this._urls[key] == undefined) {
      // nothing in cache, yet
      return undefined;
    }

    if (this._times[key] && Math.abs(Date.now() - this._times[key]) / 36e5 >= 4) {
      // cache expired after 4h
      this._urls[key] = undefined;
      return undefined;
    }

    // cache hit
    return this._urls[key];
  }

  private updateCache(key: string, request: Promise<string>): Promise<string> {
    this._state[key] = CacheState.Loading;
    const handler = request
      .catch(err => {
        console.warn('Failed to retrive URL for ' + key, err);
        return undefined;
      })
      .finally(() => {
        this._state[key] = CacheState.Done;
        this._times[key] = Date.now();
      });
    this._urls[key] = handler;
    return handler;
  }
}
