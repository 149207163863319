import { EventEmitter, Injectable } from '@angular/core';
import { Log, WebsocketClient, WebsocketService } from '@s8l/client-tree-lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ProfileService } from 'src/app/services/profile.service';

import { EnviromentService } from './environment.service';

export interface ChatUser {
  uuid: string;
  firstname?: string;
  lastname?: string;
  alias?: string;
  online?: boolean;
}

export enum ChatRoomType {
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
  CHANNEL = 'CHANNEL'
}

export interface ChatRoom {
  uuid?: string;
  type: ChatRoomType;
  name: string;
  unread?: number;
  recv?: number;
  read?: number;
  writer?: boolean;
  admin?: boolean;
  members: ChatUser[];
}

export interface ChatTyping {
  uuid: string;
  user: string;
  typing: boolean;
}

export interface ChatKicked {
  uuid: string;
  name: string;
  type: ChatRoomType;
}

export class ChatMessage {
  public get ts(): Date {
    return new Date(Math.floor(this.seq));
  }

  constructor(public seq: number, public from: string, public room: string, public text: string) {}

  public static fromJson(obj: any): ChatMessage {
    return new ChatMessage(obj.seq, obj.from, obj.room, obj.text);
  }
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  public background = false;

  private _rooms = new BehaviorSubject<ChatRoom[]>([]);
  public get rooms() {
    return this._rooms.asObservable();
  }

  private _newMessage = new EventEmitter<ChatMessage>();
  public get newMessage() {
    return this._newMessage.asObservable();
  }

  private _newMessages = new EventEmitter<ChatMessage>();
  public get newMessages() {
    return this._rooms.getValue().filter(chat => chat.unread > 0).length;
  }

  private _typingTimeout: { [room: string]: { [user: string]: number } } = {};

  private _typing = new EventEmitter<{ [room: string]: string[] }>();
  public get typing(): Observable<{ [room: string]: string[] }> {
    return this._typing.asObservable();
  }

  private client: WebsocketClient;
  private subscription: Subscription;

  constructor(private websocket: WebsocketService, private env: EnviromentService, private profile: ProfileService) {}

  public async init() {
    this.client = await this.websocket.connect(this.env.getWebsocketUrl('CHAT_SERVER'), 'ChatWebsocket');
    this.subscription = this.client.onRequest().subscribe(msg => void this.handleRequest(msg.method, msg.params));
    await this.client.send('list-rooms', {}).then((rooms: ChatRoom[]) => this.notifyUpdate(rooms));
  }

  public deinit() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.client) {
      this.client.disconnect();
    }
    return Promise.resolve();
  }

  public async sendMessage(room: string, text: string) {
    return this.client.send('send-message', { room, text });
  }

  public async invite(room: string, user: string) {
    return this.client.send('invite-user', { room, user });
  }

  public async leave(room: string) {
    await this.client.send('leave-room', { room });
    this._rooms.next(this._rooms.value.filter(r => r.uuid != room));
  }

  public async noteRecv(seq: number) {
    return this.client.send('note-recv', { seq });
  }

  public async noteRead(seq: number) {
    return this.client.send('note-read', { seq });
  }

  public async noteTyping(room: string, typing = true) {
    return this.client.send('note-typing', { room, typing });
  }

  public async listMessages(room: string): Promise<ChatMessage[]> {
    return this.client
      .send('list-messages', { room, limit: 1000, offset: 0 })
      .then(messages => {
        return messages.map(m => ChatMessage.fromJson(m)) as ChatMessage[];
      })
      .then(messages => {
        return messages.sort((a, b) => a.seq - b.seq);
      })
      .then(messages => {
        if (messages.length == 0) {
          return messages;
        }
        return this.noteRecv(messages[messages.length - 1].seq).then(() => messages);
      });
  }

  public searchContacts(query: string) {
    return this.client.send('search-users', { query });
  }

  public createRoom(type: ChatRoomType, name: string, members: string[] = []) {
    return this.client.send('create-room', { name, type, members });
  }

  private notifyUpdate(rooms: ChatRoom[]): void {
    rooms = rooms.map(room => {
      // Setup name to other participants alias on private rooms
      if (room.type === ChatRoomType.PRIVATE) {
        const contact = room.members.find(m => m.uuid != this.profile.uuid);
        if (contact?.alias) room.name = contact.alias;
      }
      return room;
    });
    this._rooms.next(rooms);
  }

  private async handleRequest(method: string, params?: any) {
    switch (method) {
      case 'message': {
        const msg = ChatMessage.fromJson(params);
        await this.noteRecv(msg.seq);
        this.clearTyping(msg.room, msg.from, null);
        this._newMessage.emit(msg);
        break;
      }

      case 'room': {
        const update = params as ChatRoom;

        const rooms = [...this._rooms.value];
        const index = rooms.findIndex(r => r.uuid === update.uuid);
        if (index != -1) {
          rooms[index] = {
            ...rooms[index],
            ...update
          };
        } else {
          rooms.push(update);
        }

        this.notifyUpdate(rooms);
        break;
      }

      case 'typing': {
        const typing = params as ChatTyping;
        if (typing.typing) {
          this.startTyping(typing.uuid, typing.user, 3000);
        } else {
          this.clearTyping(typing.uuid, typing.user, null);
        }
        break;
      }

      case 'kicked': {
        const kicked = params as ChatKicked;
        const rooms = [...this._rooms.value].filter(r => r.uuid != kicked.uuid);
        this.notifyUpdate(rooms);
        break;
      }

      default:
        Log.warn('ChatService', 'unhandled method', method, params);
        break;
    }
  }

  private startTyping(room: string, user: string, duration: number) {
    const timeout = Date.now() + duration - 100;
    const users = this._typingTimeout[room] ?? {};
    const wasTyping = users[user] > 0;
    users[user] = timeout;
    this._typingTimeout[room] = users;
    setTimeout(() => this.clearTyping(room, user, timeout), duration);
    if (!wasTyping) {
      this.notifyTyping();
    }
  }

  private clearTyping(room: string, user: string, timeout: number) {
    const users = this._typingTimeout[room];
    if (users && users[user] && (timeout == null || users[user] <= timeout)) {
      delete users[user];
      this.notifyTyping();
    }
  }

  private notifyTyping() {
    const typing: { [room: string]: string[] } = {};
    for (const room in this._typingTimeout) {
      const users = Object.keys(this._typingTimeout[room]);
      if (users && users.length > 0) {
        typing[room] = users;
      }
    }
    this._typing.emit(typing);
  }
}
