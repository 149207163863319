import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

const EmbedUrl = 'https://vimeo.com/api/oembed.json?url=';
const EmbedParams = '&byline=false&title=false&responsive=true&dnt=true&portrait=false';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss']
})
export class VimeoPlayerComponent implements OnInit {
  @Input() public set uri(u: string) {
    this.http
      .get(EmbedUrl + u + EmbedParams)
      .toPromise()
      .then(v => {
        this.iframe = this.sanitizer.bypassSecurityTrustHtml((v as any).html);
      });
  }

  public iframe;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  public ngOnInit() {}
}
