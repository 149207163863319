import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { EnviromentService } from '../environment.service';

export interface RegisterRequest {
  schedule?: number;
  token?: string;
}

export interface StatusRequest {
  token?: string;
}

export interface UpdateRequest {
  schedule?: number;
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReminderServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'ReminderServerService';
  }

  public async register(params: RegisterRequest) {
    return this.message(this.env.getServerUrl('REMINDER_SERVER', `/reminder`), 'register', params);
  }

  public async status(params: StatusRequest) {
    return this.message(this.env.getServerUrl('REMINDER_SERVER', `/reminder`), 'status', params);
  }

  public async update(params: UpdateRequest) {
    return this.message(this.env.getServerUrl('REMINDER_SERVER', `/reminder`), 'update', params);
  }
}
