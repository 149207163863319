import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { PopupService } from 'src/app/modules/popup/popup.service';
import { PasswordService } from 'src/app/services/password.service';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {
  public ready = false;

  public form = new UntypedFormGroup(
    {
      currentPassword: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl(''),
      passwordRepeat: new UntypedFormControl('')
    },
    [this.checkPasswordRepeat, this.checkPasswordChanged]
  );

  constructor(public pop: PopupService, private pass: PasswordService) {}

  public ngOnInit() {
    return this.pass.passwordValidators().then(validators => {
      this.form.controls.password.setValidators(validators);
      this.ready = true;
    });
  }

  public checkPasswordRepeat(group: UntypedFormGroup): ValidationErrors {
    const password = group.controls.password.value;
    const repeat = group.controls.passwordRepeat.value;
    return password === repeat ? null : { notsame: true };
  }

  public checkPasswordChanged(group: UntypedFormGroup): ValidationErrors {
    const old = group.controls.currentPassword.value;
    const latest = group.controls.password.value;
    return old === latest ? { same: true } : null;
  }

  public submit() {
    return this.pop.dismissModal({
      current: this.form.controls.currentPassword.value,
      password: this.form.controls.password.value
    });
  }

  public dismiss() {
    return this.pop.dismissModal();
  }
}
