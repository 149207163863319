import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { Path } from '@s8l/client-tree-lib';
import { Subscription, combineLatest, filter } from 'rxjs';

import { MenuMethod, Method, MethodNames } from 'src/app/models/methods';
import { AppRouterService } from 'src/app/services/app-router.service';
import { MenuService } from 'src/app/services/menu.service';
import { StorageService } from 'src/app/services/storage.service';
import { JourneyType } from 'src/app/services/user-journey.service';

import { UserJourneyWalkerService } from './../../services/user-journey-walker.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.page.html',
  styleUrls: ['./overlay.page.scss']
})
export class OverlayPage implements OnInit, OnDestroy {
  @ViewChild('outletContainter') public outletContainter: ElementRef<HTMLDivElement>;

  public method: Method;
  public methods: MenuMethod[];

  public sidemenu = true;

  private routeSubscription: Subscription;
  private storageSubscription: Subscription;
  private scrollSubscription: Subscription;

  public get isOverview() {
    return this.method?.name == MethodNames.Journey;
  }

  constructor(
    public walk: UserJourneyWalkerService,
    private router: Router,
    private route: ActivatedRoute,
    private menu: MenuService,
    private appRouter: AppRouterService,
    private storage: StorageService
  ) {}

  public ngOnInit() {
    this.routeSubscription = combineLatest([this.route.params, this.route.url]).subscribe(([params]) => {
      void this.updateJourney(params);
    });
    this.storageSubscription = this.storage.stored('overlay-sidemenu').subscribe(s => {
      this.sidemenu = s[s.length - 1];
    });

    this.scrollSubscription = this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(() => {
      this.outletContainter.nativeElement.scrollTop = 0;
    });
  }

  public ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.storageSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }

  private async updateJourney(params: any) {
    this.method = this.appRouter.getMethodFromURL();

    if (params.journey) {
      await this.walk.updateJourney({ type: JourneyType.Journey, uuid: params.journey }, this.isOverview, params.journeyStep);
    } else if (params.path) {
      const path = Path.parse(params.path);
      await this.walk.updateJourney(
        { type: JourneyType.Node, path: this.isOverview ? path : path.removeLast() },
        this.isOverview,
        path.uri()
      );
    }

    if (this.isOverview) {
      this.methods = [];
    } else {
      this.methods = this.menu.getMethods(this.walk.activeStep.node);
    }

    await Promise.resolve();
  }

  public next() {
    return this.walk.navigate(this.walk.nextNode);
  }

  public previous() {
    return this.walk.navigate(this.walk.prevNode);
  }

  public toggleSidemenu() {
    this.storage.save('overlay-sidemenu', !this.sidemenu);
    return;
  }
}
