import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageBackgroundDirective } from './image-background/image-background.directive';
import { ImageSrcDirective } from './image-src/image-src.directive';
import { PortalOutletDirective } from './portal/portal-outlet.directive';
import { PortalTemplateDirective } from './portal/portal-template.directive';
import { SetHtmlDirective } from './set-html/set-html.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ImageBackgroundDirective, ImageSrcDirective, PortalOutletDirective, PortalTemplateDirective, SetHtmlDirective],
  exports: [ImageBackgroundDirective, ImageSrcDirective, PortalOutletDirective, PortalTemplateDirective, SetHtmlDirective]
})
export class DirectivesModule {}
