import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface StorageObject {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageMapObject = [];
  private _storage = new BehaviorSubject<StorageObject[]>([]);

  public stored(key: string): Observable<any[]> {
    if (!this.storageMapObject || this.storageMapObject.filter(o => o.key == key).length == 0) {
      const value = JSON.parse(localStorage.getItem(key));
      if (value) this.save(key, value);
    }
    return this._storage.asObservable().pipe(map(s => s.filter(o => o.key == key).map(o => o.value)));
  }

  public save(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
    this.storageMapObject.push({ key, value });
    this.updateStore();
  }

  private retrieve(key: string) {
    localStorage.getItem(key);
  }

  private updateStore() {
    this._storage.next(this.storageMapObject);
  }
}
