<div class="flex h-full flex-col" *transloco="let t;">
  <app-profile-icon class="m-auto w-1/2" [seed]="user.uuid"></app-profile-icon>
  <div class="my-4">
    <h2 class="text-center">{{userFullname}}</h2>
    <button class="s8l-button s8l-b-clear mx-auto max-w-full shadow" (click)="navigateToProfile()">
      <fa-icon [icon]="['fal', 'cog']" style="margin-right: 6px"></fa-icon>
      {{t('app.profileSetting')}}
    </button>
  </div>
</div>
