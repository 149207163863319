<div class="relative py-2">
  <input type="hidden" name="date" x-ref="date" />
  <input
    class="focus:shadow-outline w-full rounded-lg py-3 pl-4 pr-10 font-medium leading-none text-gray-600 shadow-sm focus:outline-none"
    type="text"
    role="button"
    readonly
    [value]="date.toDateString()"
    (click)="showDatepicker = !showDatepicker"
    (keydown.escape)="showDatepicker = false"
    placeholder="Select date"
  />

  <div class="absolute right-0 top-2 px-3 py-2">
    <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </div>

  <div
    class="absolute left-0 top-0 z-50 mt-12 rounded-lg bg-white p-4 shadow"
    style="width: 17rem"
    [hidden]="!showDatepicker"
    (keydown.away)="showDatepicker = false"
  >
    <div class="mb-2 flex items-center justify-between">
      <div>
        <span class="text-lg font-bold text-gray-800">{{ monthNames[month] }}</span>
        <span class="ml-1 text-lg font-normal text-gray-600">{{ year }}</span>
      </div>
      <div>
        <button
          class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200"
          type="button"
          [ngClass]="{ 'cursor-not-allowed opacity-25': month === 0 }"
          [disabled]="month === 0 ? true : false"
          (click)="updateMonth(-1)"
        >
          <svg class="inline-flex h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button
          class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200"
          type="button"
          [ngClass]="{ 'cursor-not-allowed opacity-25': month === 11 }"
          [disabled]="month === 11 ? true : false"
          (click)="updateMonth(+1)"
        >
          <svg class="inline-flex h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>

    <div class="-mx-1 mb-3 flex flex-wrap">
      <ng-container *ngFor="let day of dayNames; let index = index">
        <div class="px-1" style="width: 14.26%">
          <div class="text-center text-xs font-medium text-gray-800">{{ day }}</div>
        </div>
      </ng-container>
    </div>

    <div class="-mx-1 flex flex-wrap">
      <ng-container *ngFor="let date of days; let dateIndex = index; trackBy: trackByIdentity">
        <div class="border-none border-transparent p-1 text-center text-sm" *ngIf="date === 0" style="width: 14.28%"></div>
        <div class="mb-1 px-1" *ngIf="date > 0" style="width: 14.28%">
          <div
            class="cursor-pointer rounded-full text-center text-sm leading-loose transition duration-100 ease-in-out"
            (click)="updateDate(date)"
            [ngClass]="{
                        'bg-blue-500 text-white': isToday(date) === true,
                        'text-gray-700 hover:bg-blue-200': isToday(date) === false
                      }"
          >
            {{ date }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
