import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import SwiperCore, { Zoom, Navigation, Pagination } from 'swiper';

import { PopupService } from 'src/app/modules/popup/popup.service';

SwiperCore.use([Zoom, Navigation, Pagination]);

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements AfterViewInit {
  @ViewChild('svgContainer') public container: ElementRef<HTMLElement>;

  @Input() public imgSource = '';
  @Input() public svgSource: SafeHtml;
  @Input() public imgTitle = '';
  @Input() public imgDescription = '';

  public slideOpts = {
    centeredSlides: 'true'
  };

  constructor(private pop: PopupService) {}

  public ngAfterViewInit(): void {
    if (this.container && this.container.nativeElement) {
      const animateElement = this.container.nativeElement.querySelector('#startAnimation');

      if (animateElement) {
        animateElement.classList.add('start');
      }
    }
  }

  public closeModal() {
    return this.pop.dismissModal();
  }
}
