import { Component } from '@angular/core';

import { AppRouterService } from 'src/app/services/app-router.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  constructor(private appRouter: AppRouterService) {}

  public goBack() {
    this.appRouter.navigateBack();
  }
}
