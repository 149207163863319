<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" *transloco="let t;">
  <form
    class="relative transform overflow-hidden rounded-[30px] bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
    *ngIf="ready"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="flex justify-between">
      <div class="text-center">
        <h3 class="text-xl font-semibold leading-6 text-prose" id="modal-title">{{ t('password.title') }}</h3>
      </div>

      <button class="flex items-baseline" type="button" (click)="dismiss()">
        <fa-icon [icon]="['fal', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <!-- Current Password -->
    <div class="mb-1 mt-3">
      <label class="block font-medium leading-6 text-prose" for="currentPassword">{{ t('password.current') }}</label>
      <div class="mt-2">
        <input
          class="block w-full rounded-full border-0 p-3 text-prose shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          id="currentPassword"
          name="currentPassword"
          type="password"
          formControlName="currentPassword"
          required
        />
      </div>
    </div>

    <!-- New Password -->
    <div class="mb-1 mt-3">
      <label class="block font-medium leading-6 text-prose" for="password">{{ t('password.new') }}</label>
      <div class="mt-2">
        <input
          class="block w-full rounded-full border-0 p-3 text-prose shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          id="password"
          name="password"
          type="password"
          formControlName="password"
          required
        />
      </div>
    </div>
    <ng-container *ngIf="form.controls.password.dirty">
      <ng-container *ngFor="let err of form.controls.password.errors | keyvalue">
        <div class="text-sm text-red-600">{{ t('register.invalid.password.' + err.key) }}</div>
      </ng-container>
    </ng-container>

    <!-- New Password Repeat -->
    <div class="mb-1 mt-3">
      <label class="block font-medium leading-6 text-prose" for="passwordRepeat">{{ t('password.repeat') }}</label>
      <div class="mt-2">
        <input
          class="block w-full rounded-full border-0 p-3 text-prose shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          id="passwordRepeat"
          name="passwordRepeat"
          type="password"
          formControlName="passwordRepeat"
          required
        />
      </div>
    </div>
    <ng-container *ngIf="form.controls.passwordRepeat.dirty && form.hasError('notsame')">
      <div class="text-sm text-red-600">{{ t('register.invalid.password.notsame') }}</div>
    </ng-container>
    <ng-container *ngIf="form.controls.passwordRepeat.dirty && form.hasError('same')">
      <div class="text-sm text-red-600">{{ t('register.invalid.password.same') }}</div>
    </ng-container>

    <div class="mt-5 sm:mt-6">
      <button class="s8l-button s8l-b-clear inline-flex w-full justify-center shadow-md" type="submit" [disabled]="!form.valid">
        {{ t('password.change') }}
      </button>
    </div>
  </form>
</div>
