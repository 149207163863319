import { Component, OnInit } from '@angular/core';

import { BookingEvent, BookingServerService } from 'src/app/services/jsonrpc/booking-server.service';

@Component({
  selector: 'dashboard-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  public booked: BookingEvent[];

  constructor(private booking: BookingServerService) {}

  public ngOnInit() {
    return this.fetchBookedEvents();
  }

  public cancelEvent(e: BookingEvent) {
    return this.booking.bookingUnbook({ id: e.id }).then(_ => {
      return this.fetchBookedEvents();
    });
  }

  private fetchBookedEvents() {
    return this.booking.bookingBooked().then(events => (this.booked = events.sort((a, b) => (a.startTime < b.startTime ? -1 : 1))));
  }
}
