import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-chat',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss']
})
export class ChatMessagesComponent implements OnInit {
  public messages = [
    'Wilkommen im neuen Semester!',
    'Liebe Studierenden, in Brand Identity findet ihr zusätzliches Material für die Prüfung.',
    'Bitte meldet euch alle zu den Prüfungen an!'
  ];

  constructor() {}

  public ngOnInit() {}
}
