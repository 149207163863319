<ng-container *ngIf="chartOptions">
  <overview-duration-chips [duration]="duration" (durationChanged)="durationChanges($event)"></overview-duration-chips>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [dataLabels]="chartOptions.dataLabels"
    [grid]="chartOptions.grid"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
  ></apx-chart>
</ng-container>
