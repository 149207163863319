<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" *transloco="let t;">
  <div
    class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
  >
    <div class="flex justify-between">
      <div class="text-center">
        <h3 class="text-xl font-semibold leading-6 text-prose" id="modal-title">{{ t('multi.invited.header', {alias: alias}) }}</h3>
      </div>

      <button class="flex items-baseline" type="button" (click)="decline()">
        <fa-icon [icon]="['fal', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <div class="text-center">
      <div class="mt-2">
        <p class="text-sm text-grey">{{ t('multi.invited.message') }}</p>
      </div>
    </div>

    <div class="mt-5 sm:mt-6">
      <button class="s8l-button s8l-b-clear inline-flex w-full justify-center shadow-md" type="button" (click)="decline()">
        {{ t('multi.invited.notnow') }}
      </button>
    </div>
    <div class="mt-3 sm:mt-4">
      <button class="s8l-button s8l-b-primary s8l-b-clear inline-flex w-full justify-center shadow-md" type="button" (click)="accept()">
        {{ t('multi.invited.letsgo') }}
      </button>
    </div>
  </div>
</div>
