<figure
  class="flex min-h-[33vh] w-full flex-col items-center justify-end rounded-b-[1.5rem] bg-zinc-700 bg-cover bg-clip-border bg-center bg-no-repeat px-5 py-4 bg-blend-overlay shadow-md md:h-[50vh] md:justify-center"
  [class.!bg-top]="hero.imageAnchor === 'top'"
  [class.!bg-bottom]="hero.imageAnchor === 'bottom'"
  appImageBackground
  [image]="hero?.image"
  [imageWidth]="1920"
  [imageHeight]="1080"
>
  <div class="mt-8 px-3 text-left text-white">
    <h1 class="hyphen-breaks m-1 text-4xl font-semibold" [innerHTML]="title"></h1>
    <h3 class="hyphen-breaks m-1 text-xl text-white" [innerHTML]="subtitle"></h3>
  </div>
</figure>
