<button
  class="relative inline-flex h-7 w-14 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-900 focus:ring-offset-2"
  [class.bg-primary]="enabled"
  [class.bg-light-grey]="!enabled"
  type="button"
  role="switch"
  [attr.aria-checked]="enabled"
  (click)="enabledChange.emit(!enabled)"
>
  <span class="sr-only">Use setting</span>
  <span
    class="pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    [class.translate-x-8]="enabled"
    [class.translate-x-0]="!enabled"
    aria-hidden="true"
  ></span>
</button>
