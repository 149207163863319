import { Component, OnInit } from '@angular/core';
import { Path } from '@s8l/client-tree-lib';

import { Methods } from 'src/app/models/methods';
import { AppRouterService } from 'src/app/services/app-router.service';
import { SettingsNodeSettings, UserNodeContentServerService } from 'src/app/services/jsonrpc/user-node-content-server.service';

import { SidemenuService } from '../../sidemenu/sidemenu.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dashboard-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {
  public bookmarks: SettingsNodeSettings[] = [];

  constructor(private user: UserNodeContentServerService, private appRouter: AppRouterService, private svc: SidemenuService) {}

  public ngOnInit() {
    return this.fetch();
  }

  public navigate(b) {
    this.svc.hide();
    return this.appRouter.navigate({ ...Methods.WikiArticle, path: Path.parse(b.path).removeFirst() });
  }

  public navigateToBookmarks() {
    this.svc.hide();
    return this.appRouter.navigateRaw(['user', 'bookmarks']);
  }

  public removeBookmark(b) {
    return this.user.nodeSetLabels(Path.parse(b.path), { bookmark: {} }).then(() => this.fetch());
  }

  private async fetch() {
    return this.user
      .nodeGetByLabel('bookmark')
      .then(data => data?.sort((a, b) => a.path?.localeCompare(b.path)))
      .then(data => {
        this.bookmarks = data;
      })
      .catch(e => console.warn(e));
  }
}
