import { Injectable } from '@angular/core';
import { AppFeatures, Path, QuizModes, TreeMediaFiles, TreeNode, TreeResource } from '@s8l/client-tree-lib';

import { EnviromentService } from './environment.service';
import { TreeService } from './tree.service';
import { MenuMethod, Methods } from '../models/methods';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private tree: TreeService, private env: EnviromentService) {}

  public getMethods(node: TreeNode): MenuMethod[] {
    const methods = [];

    const addMethod = (m: MenuMethod) => {
      methods.push(this.transformMethod(m));
    };

    if (this.env.hasFeature(AppFeatures.Wiki)) {
      if (node.hasResource(TreeResource.Wiki) || node.anyChildHasResource(TreeResource.Wiki)) {
        // some child has article
        addMethod(Methods.WikiArticle);
      }
    }

    if (this.env.hasFeature(AppFeatures.Impuls)) {
      if (node.hasResource(TreeResource.Impuls)) {
        addMethod(Methods.Impuls);
      }
    }

    if (this.env.hasFeature(AppFeatures.Glossary)) {
      const parent = this.parentHas(node, TreeResource.Glossary, true);
      if (parent) {
        addMethod({
          ...Methods.Glossary,
          path: Path.parse(parent.path).removeFirst()
        });
      }
      const glossary = this.tree.root.children.find(el => this.tree.config.matchesModifiers(el, ['glossary']));
      if (glossary) {
        addMethod({
          ...Methods.Glossary,
          path: Path.parse(glossary.path).removeFirst()
        });
      }
    }

    if (this.env.hasFeature(AppFeatures.Casestudy) && node.anyChildHasResource(TreeResource.Casestudy)) {
      addMethod(Methods.CasestudyIndex);
    }

    if (this.env.hasFeature(AppFeatures.Legal) && node.anyChildHasResource(TreeResource.Legal)) {
      addMethod(Methods.Legal);
    }

    if (this.anyHasResource(node, TreeResource.Media) && node.meta?.mediafiles) {
      if (this.env.hasFeature(AppFeatures.Document) && node.meta?.mediafiles.find(m => m == TreeMediaFiles.Document)) {
        addMethod(Methods.Document);
      }
    }

    if (
      (this.anyHasResource(node, TreeResource.Media) &&
        this.env.hasFeature(AppFeatures.Video) &&
        (node.meta?.mediafiles?.find(m => m == TreeMediaFiles.Video) || this.anyChildHasMedia(node, TreeMediaFiles.Video))) ||
      (this.env.hasFeature(AppFeatures.Vimeo) && this.anyHasResource(node, TreeResource.Video))
    ) {
      addMethod(Methods.Video);
    }

    if (this.anyHasResource(node, TreeResource.Media)) {
      if (
        this.env.hasFeature(AppFeatures.Podcast) &&
        (node.meta?.mediafiles?.find(m => m == TreeMediaFiles.Podcast) || this.anyChildHasMedia(node, TreeMediaFiles.Podcast))
      ) {
        addMethod(Methods.Podcast);
      }
    }

    if (this.env.hasFeature(AppFeatures.Flashcard) && node.hasResource(TreeResource.Flashcard)) {
      addMethod(Methods.Flashcard);
    }

    const quizNodes = this.hasNodesWithResource(Path.parse(node.path), TreeResource.Quiz);

    if (quizNodes && quizNodes.length > 0) {
      const path = Path.parse(node.path).removeFirst();

      const hasQuiz = quizNodes.findIndex(x => x.meta?.quizModes?.includes(QuizModes.Default) ?? true) != -1;
      if (this.env.hasFeature(AppFeatures.QuizSp) && hasQuiz) {
        addMethod({
          ...Methods.QuizSp,
          path
        });
      }

      const hasMp = quizNodes.findIndex(x => x.meta?.quizModes?.includes(QuizModes.Multi) ?? true) != -1;
      if (this.env.hasFeature(AppFeatures.QuizMp) && hasMp) {
        addMethod({
          ...Methods.QuizMp,
          path
        });
      }
    }

    return methods;
  }

  public getQuizMethods(node: TreeNode): MenuMethod[] {
    const methods = [];

    const addMethod = (m: MenuMethod) => {
      methods.push(this.transformMethod(m));
    };

    const quizNodes = this.hasNodesWithResource(Path.parse(node.path), TreeResource.Quiz);
    if (!quizNodes || quizNodes.length == 0) {
      return methods;
    }

    const path = Path.parse(node.path).removeFirst();

    const hasQuiz = quizNodes.findIndex(x => x.meta?.quizModes?.includes(QuizModes.Default) ?? true) != -1;
    if (this.env.hasFeature(AppFeatures.QuizSp) && hasQuiz) {
      addMethod({
        ...Methods.QuizSp,
        path
      });
    }

    const hasExam = quizNodes.findIndex(x => x.meta?.quizModes?.includes(QuizModes.Exam) ?? true) != -1;
    if (this.env.hasFeature(AppFeatures.QuizExam) && hasExam) {
      addMethod({
        ...Methods.Exam,
        path: path.removeLast().removeLast()
      });
    }

    const hasReview = quizNodes.findIndex(x => x.meta?.quizModes?.includes(QuizModes.Review)) != -1;
    if (this.env.hasFeature(AppFeatures.QuizReview) && hasReview) {
      addMethod({
        ...Methods.Review,
        path
      });
    }

    return methods;
  }

  private parentHas(node: TreeNode, resource: string, recursive = false): TreeNode {
    const parentPath = Path.parse(node.path).removeFirst().removeLast();
    if (parentPath.length === 0) {
      return null;
    }
    const parent = this.tree.get(parentPath);
    if (!parent) {
      return null;
    }
    if (parent.hasResource(resource as TreeResource)) {
      return parent;
    }
    if (!recursive) {
      return null;
    }
    return this.parentHas(parent, resource, recursive);
  }

  private findDepth(resource: TreeResource): number {
    for (const r of this.tree.config.rules) {
      if (r.modifier) {
        continue;
      }

      if (r.resource.includes(resource)) {
        return r.depth + 1;
      }
    }
    return -1;
  }

  private hasNodeWithResource(path: Path, resource: TreeResource): TreeNode {
    const depth = this.findDepth(resource);
    if (depth > path.length) {
      const n = this.tree.get(path);
      if (n.anyChildHasResource(resource)) {
        return n.children[0];
      }
      return null;
    }

    const p = new Path(path.get().slice(0, depth));
    const n = this.tree.get(p);
    if (n.hasResource(resource)) {
      return n;
    }
    return null;
  }

  private hasNodesWithResource(path: Path, resource: TreeResource): TreeNode[] {
    let nodes: TreeNode[] = [];
    const depth = this.findDepth(resource);

    if (depth > path.length) {
      const n = this.tree.get(path);
      if (n.anyChildHasResource(resource)) {
        nodes = nodes.concat(n.children.filter(x => x.hasResource(resource)));
      }
      return nodes;
    }

    const p = new Path(path.get().slice(0, depth));
    const n = this.tree.get(p);
    if (n.hasResource(resource)) {
      nodes.push(n);
    }
    return nodes;
  }

  private anyHasResource(node: TreeNode, res: TreeResource) {
    return node.hasResource(res) || node.anyChildHasResource(res);
  }

  private anyChildHasMedia(node: TreeNode, media: TreeMediaFiles) {
    for (const child of node.children) {
      if (child.children && this.anyChildHasMedia(child, media)) {
        return true;
      }

      if (child.meta?.mediafiles && child.meta?.mediafiles.find(m => m == media)) {
        return true;
      }
    }
    return false;
  }

  private transformMethod(method): MenuMethod {
    return {
      ...method,
      text: this.env.getMethodName(method.name)
    };
  }
}
