import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { AuthService, AppFeatures, JSONRPCError, JSONRPCErrorCode, WebsocketError } from '@s8l/client-tree-lib';

import { environment } from 'src/environments/environment';

import { ChatService } from './chat.service';
import { EnviromentService } from './environment.service';
import { NotificationService } from './notification.service';
import { ProfileService } from './profile.service';
import { TreeService } from './tree.service';
import { UserJourneyService } from './user-journey.service';
import { PopupService } from '../modules/popup/popup.service';
import { MultiplayerService } from '../modules/quiz/multiplayer.service';

const ReturnURLBlacklist = ['/login', '/login/startup'];

@Injectable({
  providedIn: 'root'
})
export class AppLifecycleService {
  private _websocketError = false;
  private _isInit = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    private profile: ProfileService,
    private notification: NotificationService,
    private userJourney: UserJourneyService,
    private multiplayer: MultiplayerService,
    private ngZone: NgZone,
    private chat: ChatService,
    private pop: PopupService,
    private env: EnviromentService,
    private tree: TreeService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private canActivateFeature(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (next.routeConfig.path === 'chat') {
      this.chat.background = true;

      if (!this.env.hasFeature(AppFeatures.Chat)) {
        return this.router.createUrlTree(['/menu']);
      }
    } else {
      this.chat.background = true;
    }
    return true;
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.auth.isAuth) {
      return this.router.createUrlTree([this.env.loginEndpoint], { queryParams: { returnUrl: state.url } });
    }

    if (!this._isInit) {
      return this.init()
        .then(() => this.canActivateFeature(next, state))
        .catch(() => this.router.createUrlTree([this.env.loginEndpoint]));
    }

    return this.canActivateFeature(next, state);
  }

  public login(username: string, password: string): Promise<void> {
    return this.profile.login(username, password);
  }

  public async init(): Promise<any> {
    try {
      await this.tree.init();

      const promises: any[] = [
        this.profile.init(this.env.hasFeature(AppFeatures.Reminder), this.tree.rootNode),
        this.profile.startHeartbeat()
      ];

      if (this.env.hasFeature(AppFeatures.Notification)) {
        promises.push(this.notification.init().catch(err => this.onWebsocketError(err, AppFeatures.Notification, AppFeatures.Achievement)));
      }

      if (this.env.hasFeature(AppFeatures.QuizMp)) {
        promises.push(this.multiplayer.init().catch(err => this.onWebsocketError(err, AppFeatures.QuizMp)));
      }

      if (this.env.hasFeature(AppFeatures.Chat)) {
        promises.push(this.chat.init().catch(err => this.onWebsocketError(err, AppFeatures.Chat)));
      }

      if (this.env.hasFeature(AppFeatures.Journey) || this.env.hasFeature(AppFeatures.Achievement)) {
        promises.push(this.userJourney.init());
      }

      await Promise.all(promises);

      if (this.multiplayer.GameResume != null) {
        await this.multiplayer.resume();
      }

      this._isInit = true;
    } catch (err) {
      this._isInit = false;

      if (err instanceof JSONRPCError && err.code === JSONRPCErrorCode.UNAUTHORIZED) {
        return this.ngZone.run(() => this.router.navigate([this.env.loginEndpoint]));
      }
      throw err;
    }
  }

  private onWebsocketError(err: any, ...disableFeatures: string[]) {
    if (err instanceof WebsocketError) {
      if (disableFeatures?.length > 0) {
        const rem = disableFeatures.map(f => f.toLowerCase());
        environment.features = environment.features.filter(f => !rem.includes(f.toLowerCase()));
      }
      if (!this._websocketError) {
        this._websocketError = true; // Show error message only once
        return this.pop
          .createAlert({
            header: translate('errors.websocket'),
            message: translate('errors.websocketInfo')
          })
          .then(alert => alert.present());
      }
    } else {
      throw err;
    }
  }

  public async deinit(unknownError = false, logout = true) {
    this.tree.deinit();
    this._isInit = false;
    this._websocketError = false;

    await this.profile.stopHeartbeat().catch(console.warn);

    if (this.env.hasFeature(AppFeatures.Notification)) {
      await this.notification.stop().catch(console.warn);
    }
    if (this.env.hasFeature(AppFeatures.QuizMp)) {
      await this.multiplayer.deinit().catch(console.warn);
    }
    if (this.env.hasFeature(AppFeatures.Chat)) {
      await this.chat.deinit().catch(console.warn);
    }

    await this.profile.logout(logout).catch(console.warn);

    const options: any = {};

    if (!unknownError) {
      let url = this.router.routerState.snapshot.url;

      // preserve old returnUrl if present
      const query = this.parseUrlQuery(url);
      if (query && query.returnUrl) {
        url = decodeURIComponent(query.returnUrl);
      }

      if (!ReturnURLBlacklist.includes(url)) {
        options.queryParams = {
          returnUrl: url
        };
      }
    }

    await this.router.navigate([this.env.loginEndpoint], options);
  }

  private parseUrlQuery(path: string): any {
    return path
      .slice(path.indexOf('?') + 1)
      .split('&')
      .reduce((map, hash) => {
        const [key, val] = hash.split('=');
        map[key] = val;
        return map;
      }, {});
  }
}
