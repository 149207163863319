import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface AchievementsUuidRequest {
  uuid?: string;
}

export interface AchievementsAdminAchievement {
  i18n_locked?: any;
  i18n_achieved?: any;
  effects?: AchievementsAdminAchievementEffect[];
  filters?: AchievementsAdminFilter[];
  uuid?: string;
  name?: string;
  type?: string;
  parent?: string;
  metric?: number;
  display?: AchievementDisplay;
  stat?: AchievementsStat;
  unlock_value?: number;
  repeatable?: boolean;
  event_start?: AchievementInstant;
  event_end?: AchievementInstant;
}

export interface AchievementsAdminAchievementEffect {
  type?: AchievementsType;
  value?: any;
}

export type AchievementsType = any;

export interface AchievementsAdminFilter {
  key?: string;
  value?: string;
  positive?: boolean;
}

export interface AchievementsStat {
  uuid?: string;
  event?: string;
  selector?: string;
  min_value?: number;
  max_value?: number;
  meta?: any;
}

export type AchievementInstant = string;

export interface AchievementsDeleteRequest {
  uuid?: string;
  recursive?: boolean;
  stats?: boolean;
}

export interface AchievementSetConfigRequest {
  uuid?: string;
  config?: any;
}

export enum AchievementDisplay {
  /**
   * Always show the achievement to the user.
   */
  Show = 'show',

  /**
   * Hide the achievement execpt if completed.
   */
  Secret = 'secret',

  /**
   * Show the achievement only during the event time frame or if completed.
   */
  Event = 'event',

  /**
   * Hide the achievement. It is only used internally.
   */
  Hidden = 'hidden'
}

export interface AchievementUserProgress {
  uuid?: string;
  name?: string;
  type?: string;
  meta?: any;
  parent?: string;
  metric?: number;

  config?: any;

  i18n?: any;

  display?: AchievementDisplay;

  unlock_value?: number;
  value?: number;
  achieved?: number;
  achieved_at?: AchievementInstant;

  repeatable?: boolean;

  event_start?: AchievementInstant;
  event_end?: AchievementInstant;

  children?: AchievementUserProgress[];
}

export interface StatsUuidRequest {
  uuid?: string;
}

export interface StatsStat {
  uuid?: string;
  event?: string;
  selector?: string;
  min_value?: number;
  max_value?: number;
  meta?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AchievementServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'AchievementServerService';
  }

  public async achievementSetConfig(params: AchievementSetConfigRequest): Promise<AchievementUserProgress> {
    return this.message(this.env.getServerUrl('ACHIEVEMENT_SERVER', `/achievement`), 'set-config', params);
  }

  public async achievementList(params: any): Promise<AchievementUserProgress[]> {
    return this.message(this.env.getServerUrl('ACHIEVEMENT_SERVER', `/achievement`), 'list', params);
  }
}
