<div class="mb-4" *transloco="let t;">
  <h2 class="mb-1">{{t('app.ranking')}}</h2>
  <h5 class="mb-4">{{t('ranking.last-month')}}</h5>

  <div class="ml-3" *ngIf="!ranked">
    <div [innerHTML]="t('ranking.deactivated')"></div>
  </div>

  <div class="ml-3">
    <div *ngIf="me?.rank > range">
      <h4 class="text-center">...</h4>
    </div>
    <div
      class="border-b-1 mb-4 flex items-center justify-between p-2"
      *ngFor="let i of indices"
      [class.active]="me?.rank === entries[i]?.rank"
    >
      <h4 class="first-letter:text-2xl">{{entries[i]?.rank}}. {{entries[i]?.alias}}</h4>
      <p class="!mb-0">{{entries[i]?.score}}</p>
    </div>
    <div *ngIf="me?.rank < entries.length - range">
      <h4 class="text-center">...</h4>
    </div>
  </div>
  <button class="s8l-button s8l-b-clear mx-auto max-w-full shadow" (click)="navigateToLeaderboard()">
    <fa-icon [icon]="['fal', 'trophy']" style="margin-right: 6px"></fa-icon>
    {{t('app.ranking')}}
  </button>
</div>
