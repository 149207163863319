<footer class="fixed bottom-0 left-0 right-0 z-30" *transloco="let t;">
  <nav class="mx-auto flex w-full items-center justify-between border-t border-grey bg-page p-2 shadow">
    <div class="flex flex-1 justify-start"></div>

    <div class="flex snap-x snap-mandatory divide-x divide-primary overflow-x-auto" *ngIf="enabledButtons.length">
      <div class="my-2 snap-center px-4" *ngFor="let button of footerButtons">
        <button class="text-primary" (click)="navigate(button)">
          <fa-icon class="text-2xl" [icon]="['fal', button.icon]" [fixedWidth]="true"></fa-icon>
          <div class="text-center text-base">{{ t(button.text) }}</div>
        </button>
      </div>
      <div class="my-2 snap-center px-4" *ngIf="menu">
        <button class="text-center text-primary" (click)="openMenu()">
          <fa-icon class="text-2xl" [icon]="['fal', 'ellipsis-h']" [fixedWidth]="true"></fa-icon>
          <div class="text-center text-base">{{ t('app.more') }}</div>
        </button>
      </div>
    </div>

    <div class="flex flex-1 justify-end"></div>
  </nav>

  <!-- ... more menu on mobile screens-->
  <div [class.hidden]="!open">
    <div class="h-screen-fix flex flex-col bg-black" [class.bg-opacity-0]="closing" [class.bg-opacity-50]="open">
      <div class="grow" (click)="closeMenu()"></div>
      <div class="rounded-t-lg border-t border-grey bg-page px-4 shadow transition-transform" [class.close]="closing" [class.open]="open">
        <div class="my-2 snap-center divide-y divide-primary" *ngFor="let button of enabledButtons; let i = index">
          <button class="flex w-full items-center justify-between py-3 text-primary" (click)="navigate(button)">
            <span class="flex items-center">
              <fa-icon class="text-2xl" [icon]="['fal', button.icon]" [fixedWidth]="true"></fa-icon>
              <span class="px-2 text-center text-base">{{ t(button.text) }}</span>
            </span>
            <fa-icon class="stretch text-2xl" [icon]="['fal', 'chevron-right']" [fixedWidth]="true"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</footer>
