import { Injectable } from '@angular/core';

export interface Popup {
  id: string;
  shown: boolean;

  present: () => Promise<void>;
  dismiss: () => Promise<void>;
}

export interface LoadingOptions {
  message?: string;
  cancel?: boolean;

  handler?: () => Promise<void> | void;
}

export interface Loading extends Popup {
  opts: LoadingOptions;
}

export interface AlertOptions {
  header: string;
  message: string;

  present?: boolean;

  handler?: () => Promise<void> | void;
}

export interface Alert extends Popup {
  opts: AlertOptions;
}

export interface ModalOptions {
  component: any;
  componentProps?: { [index: string]: any };
  fullscreen?: boolean;

  handler?: (data: any) => Promise<void> | void;
}

export interface Modal extends Popup {
  opts: ModalOptions;
}

export enum ToastColors {
  Success = 'success',
  Danger = 'danger'
}

export interface ToastOptions {
  header: string;
  message?: string;
  color: ToastColors;
  duration?: number;
  link?: string[];
}

export interface Toast extends Popup {
  opts: ToastOptions;
}

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public alert?: Alert;
  public loading?: Loading;
  public modal?: Modal;
  public toast: Toast[] = [];

  public createAlert(opts: AlertOptions): Promise<Alert> {
    const id = this.uuid('alert');
    this.alert = {
      id,
      opts,
      shown: opts.present || false,

      present: () => {
        if (this.alert?.id == id) {
          this.alert.shown = true;
        }
        return Promise.resolve();
      },
      dismiss: () => {
        if (this.alert?.id == id) {
          this.alert = undefined;
        }
        return Promise.resolve();
      }
    };
    return Promise.resolve(this.alert);
  }

  public createLoading(opts?: LoadingOptions): Promise<Loading> {
    const id = this.uuid('loading');
    this.loading = {
      id,
      opts: opts || {},
      shown: false,

      present: () => {
        if (this.loading?.id == id) {
          this.loading.shown = true;
        }
        return Promise.resolve();
      },
      dismiss: () => {
        if (this.loading?.id == id) {
          this.loading = undefined;
        }
        return Promise.resolve();
      }
    };
    return Promise.resolve(this.loading);
  }

  public createToast(opts: ToastOptions): Promise<Toast> {
    const id = this.uuid('toast');
    const toast = {
      id,
      opts: {
        ...opts,
        duration: opts.duration || 5000
      },
      shown: false,

      present: () => {
        setTimeout(() => void toast.dismiss(), toast.opts.duration);
        toast.shown = true;
        return Promise.resolve();
      },
      dismiss: () => {
        this.toast = this.toast.filter(t => t.id != id);
        return Promise.resolve();
      }
    };

    this.toast.push(toast);
    return Promise.resolve(toast);
  }

  public createModal(opts: ModalOptions): Promise<Modal> {
    const id = this.uuid('modal');
    this.modal = {
      id,
      opts,
      shown: false,

      present: () => {
        if (this.modal?.id == id) {
          this.modal.shown = true;
        }
        return Promise.resolve();
      },
      dismiss: () => {
        if (this.modal?.id == id) {
          this.modal = undefined;
        }
        return Promise.resolve();
      }
    };
    return Promise.resolve(this.modal);
  }

  public async dismissModal(data?: any) {
    if (!this.modal) {
      return;
    }
    try {
      if (this.modal.opts.handler) {
        await this.modal.opts.handler(data);
      }
    } finally {
      await this.modal.dismiss();
    }
  }

  public async dismissAllToasts() {
    this.toast = [];
    return Promise.resolve();
  }

  private uuid(typ: string) {
    return `${typ}-${Math.random().toString(36).substring(7)}`;
  }
}
