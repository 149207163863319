<ng-container *transloco="let t;">
  <div *ngIf="user">
    <div *ngFor="let l of latest">
      <div class="my-4 flex items-center" *ngIf="l.methods?.length > 0">
        <button
          class="s8l-button s8l-b-clear w-full overflow-hidden !rounded-lg text-left shadow"
          (click)="navigateToMethod(l.node, l.methods[0])"
        >
          <fa-icon
            class="mx-4 aspect-square min-w-[1.75rem] shrink-0 text-center text-2xl"
            [icon]="['fal', l.node.meta?.icon ? l.node.meta?.icon : 'circle']"
            fixedWidth="true"
          ></fa-icon>
          <span class="grow overflow-hidden text-ellipsis whitespace-nowrap"> {{l.node.name}} </span>

          <button class="s8l-button s8l-b-primary s8l-b-icon-only" *ngFor="let m of l.methods" (click)="navigateToMethod(l.node, m)">
            <fa-icon [icon]="['fal', m.icon]" [fixedWidth]="true"></fa-icon>
          </button>
        </button>
      </div>
    </div>
  </div>
</ng-container>
