import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { SwiperModule } from 'swiper/angular';

import { AchievementCardComponent } from './achievement-card/achievement-card.component';
import { AliasModalComponent } from './alias-modal/alias-modal.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeroComponent } from './hero/hero.component';
import { HeroSliderComponent } from './hero-slider/hero-slider.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { JourneyOverviewComponent } from './journey-overview/journey-overview.component';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { MediaListComponent } from './media-list/media-list.component';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { ResourceIconComponent } from './resource-icon/resource-icon.component';
import { ToggleComponent } from './toggle/toggle.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    BackButtonComponent,
    FooterComponent,
    HeaderComponent,
    HeroComponent,
    HeroSliderComponent,
    ImageViewerComponent,
    LanguageSwitchComponent,
    MediaListComponent,
    JourneyOverviewComponent,
    PasswordModalComponent,
    ResourceIconComponent,
    VideoPlayerComponent,
    VimeoPlayerComponent,
    ToggleComponent,
    AliasModalComponent,
    AchievementCardComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    SwiperModule,
    DirectivesModule
  ],
  exports: [
    FontAwesomeModule,
    DirectivesModule,
    BackButtonComponent,
    FooterComponent,
    HeaderComponent,
    HeroComponent,
    HeroSliderComponent,
    ImageViewerComponent,
    LanguageSwitchComponent,
    MediaListComponent,
    JourneyOverviewComponent,
    PasswordModalComponent,
    ResourceIconComponent,
    VideoPlayerComponent,
    VimeoPlayerComponent,
    ToggleComponent,
    AliasModalComponent,
    AchievementCardComponent,
    DatePickerComponent
  ]
})
export class ComponentsModule {}
