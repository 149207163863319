import { Component, Input } from '@angular/core';

import { SidemenuService } from 'src/app/modules/sidemenu/sidemenu.service';
import { AppRouterService } from 'src/app/services/app-router.service';
import { ChatService } from 'src/app/services/chat.service';
import { EnviromentService } from 'src/app/services/environment.service';
import { TreeService } from 'src/app/services/tree.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public title: string;
  @Input() public back = true;
  @Input() public home = true;
  @Input() public lang = true;
  @Input() public chat = true;
  @Input() public menu = true;

  @Input() public noElements = false;

  public get hasChat() {
    return this.env.hasFeature('chat');
  }

  public get newMessages() {
    return this.chatService.newMessages;
  }

  constructor(
    private appRouter: AppRouterService,
    public sidemenu: SidemenuService,
    private env: EnviromentService,
    private chatService: ChatService
  ) {}

  public goBackToHomePage() {
    return this.appRouter.navigateHome();
  }

  public goToChat() {
    return this.appRouter.navigateRaw(['chat']);
  }
}
