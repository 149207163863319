import { EventEmitter, Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Log, WebsocketClient, WebsocketService } from '@s8l/client-tree-lib';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { QuizInviteeModalComponent } from './quiz-invitee-modal/quiz-invitee-modal.component';
import { Methods } from '../../models/methods';
import { AppRouterService } from '../../services/app-router.service';
import { EnviromentService } from '../../services/environment.service';
import { Modal, PopupService, ToastColors } from '../popup/popup.service';

@Injectable({ providedIn: 'root' })
export class MultiplayerService {
  public game = null;
  private gameToResume = null;

  private ws: WebsocketClient;
  private _gameStartEvents = new EventEmitter<any>();
  private _gameUpdateEvents = new EventEmitter<any>();
  private _gameDeclineEvents = new EventEmitter<any>();
  private _gameInviteEvents = new EventEmitter<any>();
  private _gameFinishEvents = new EventEmitter<any>();

  private _updateSubscription: Subscription = null;
  private _inviteSubscription: Subscription = null;
  private _declineSubscription: Subscription = null;
  private _resumeSubscription: Subscription = null;

  // The server makes sure there will always be only one invite at a time.
  private _pendingInvite: { msg: any; modal: Modal } = null;

  public get hasInvitePending() {
    return this._pendingInvite != null;
  }

  public get GameResume() {
    return this.gameToResume;
  }

  public get gameStartEvents() {
    return this._gameStartEvents.asObservable();
  }
  public get gameUpdateEvents() {
    return this._gameUpdateEvents.asObservable();
  }
  public get gameInviteEvents() {
    return this._gameInviteEvents.asObservable();
  }
  public get gameFinishEvents() {
    return this._gameFinishEvents.asObservable();
  }
  public get gameDeclineEvents() {
    return this._gameDeclineEvents.asObservable();
  }

  constructor(
    private pop: PopupService,
    private env: EnviromentService,
    private websocket: WebsocketService,
    private appRouter: AppRouterService
  ) {}

  public init() {
    return this.websocket
      .connect(this.env.getWebsocketUrl('QUIZ_MP_SERVER'), 'MultiplayerWebsocket')
      .then(client => (this.ws = client))
      .then(() => {
        this._updateSubscription = this.ws
          .onRequest()
          .pipe(filter(res => res.method == 'update'))
          .subscribe(res => void this.onUpdate(res));
        this._inviteSubscription = this.ws
          .onRequest()
          .pipe(filter(res => res.method == 'invite'))
          .subscribe(res => void this.onInvite(res.params));
        this._declineSubscription = this.ws
          .onRequest()
          .pipe(filter(res => res.method == 'decline'))
          .subscribe(res => void this.onDecline(res.params));
        this._resumeSubscription = this.ws
          .onRequest()
          .pipe(filter(res => res.method == 'resume'))
          .subscribe(res => void this.onResume(res.params));
      });
  }

  public deinit(): Promise<void> {
    Log.log('MultiplayerService', 'DEINIT');
    if (this._updateSubscription) {
      this._updateSubscription.unsubscribe();
    }
    if (this._inviteSubscription) {
      this._inviteSubscription.unsubscribe();
    }
    if (this._declineSubscription) {
      this._declineSubscription.unsubscribe();
    }
    if (this._resumeSubscription) {
      this._resumeSubscription.unsubscribe();
    }

    if (this.ws) {
      this.ws.disconnect();
    }

    // null the games...
    this.game = null;
    this.gameToResume = null;

    // for api parity
    return Promise.resolve();
  }

  public quickPlay(params) {
    return this.ws.send('quick_play', params);
  }

  public invite(params) {
    return this.ws.send('invite', params);
  }

  public join(params) {
    return this.ws.send('join', params);
  }

  public play(params) {
    return this.ws.send('play', params);
  }

  public cancel(params) {
    return this.ws.send('cancel', params);
  }

  public decline(params) {
    this._pendingInvite = null;
    return this.ws.send('decline', params);
  }

  public forfeit(params) {
    return this.ws.send('forfeit', params);
  }

  public answer(question: string, answer: number[]) {
    return this.ws.send('answer', {
      uuid: this.game.uuid,
      answer,
      question
    });
  }

  public resume() {
    if (!this.GameResume) {
      return;
    }

    Log.log('MultiplayerService', 'RESUMING GAME', this.GameResume);
    this.game = this.GameResume;
    this.gameToResume = null;

    if (this.appRouter.router.url !== '/mp/' + (this.game.uuid as string)) {
      return this.appRouter.navigate({ ...Methods.QuizMp }, { replaceUrl: true, game: this.game.uuid });
    }
  }

  private onUpdate(msg) {
    const update = msg.params;

    Log.log('MultiplayerService', 'UPDATE ', update, 'CURRENT ', this.game);

    if (this.game == null) {
      // game was null == we just started a new game

      this.game = update;
      this._gameStartEvents.emit(update);
    } else if (update.finished && update.opponent.finished) {
      // we had a game != null and recv finished, we are done!

      this.game = null;
      this._gameFinishEvents.emit(update);
    } else {
      // game is currently in progress and we recv an update

      this.game = update;
      this._gameUpdateEvents.emit(update);
    }

    return this.ws.respond(msg.id, null, null);
  }

  private async onInvite(msg) {
    if (this.hasInvitePending) {
      return this.decline({ invite: msg.alias, path: msg.path, language: this.env.language });
    }

    const modal = await this.pop.createModal({
      component: QuizInviteeModalComponent,
      componentProps: {
        alias: msg.alias
      },
      handler: data => {
        this._pendingInvite = null;
        if (data) {
          return this.appRouter.navigate({ ...Methods.QuizMp }, { skipLocationChange: true, invited: true, opponent: msg.alias });
        } else {
          return this.decline({
            invite: msg.alias,
            path: msg.path,
            language: this.env.language
          });
        }
      }
    });

    this._pendingInvite = { msg, modal };
    return await modal.present();
  }

  // Since this is called before pretty much every service is initialized,
  // We have to set a resume state and react to it later.
  private async onResume(state) {
    Log.log('MultiplayerServer', 'ON RESUME', state);
    this.gameToResume = state;
    return Promise.resolve();
  }

  private async onDecline(msg) {
    if (this.hasInvitePending) {
      if (msg.alias === this._pendingInvite.msg.alias) {
        if (this._pendingInvite.modal) {
          await this._pendingInvite.modal.dismiss();
        }

        await this.showDangerToast(translate('multi.canceled', { alias: msg.alias }));
        this._pendingInvite = null;
      }
    }

    Log.log('MultiplayerService', 'DECLINE ', msg);
    this._gameDeclineEvents.emit(msg);
  }

  private async showDangerToast(header: string) {
    const toast = await this.pop.createToast({
      header,
      duration: 5000,
      color: ToastColors.Danger
    });
    return toast.present();
  }
}
