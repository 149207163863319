<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 512 512">
  <g mask='url("#circle-mask")' fill="none">
    <rect width="512" height="512" x="0" y="0" [attr.fill]="'url(#' + seed + '-gradient-0)'"></rect>
    <path *ngFor="let p of paths; let i = index" [attr.d]="p" [attr.fill]="'url(#' + seed + '-gradient-' + (i + 1) + ')'"></path>
  </g>
  <defs>
    <mask id="circle-mask">
      <circle cx="256" cy="256" r="256" fill="#ffffff"></circle>
    </mask>

    <linearGradient
      [id]="seed + '-gradient-' + i"
      *ngFor="let g of gradients; let i = index"
      x1="0%"
      y1="0%"
      x2="100%"
      y2="100%"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="g.start" offset="0"></stop>
      <stop [attr.stop-color]="g.stop" offset="1"></stop>
    </linearGradient>
  </defs>
</svg>
