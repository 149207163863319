import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { JourneyNavigationContainer, JourneyNavigationType } from 'src/app/services/user-journey-walker.service';
import { ActiveJourney, JourneyStep } from 'src/app/services/user-journey.service';

@Component({
  selector: 'app-journey-overview',
  templateUrl: './journey-overview.component.html',
  styleUrls: ['./journey-overview.component.scss']
})
export class JourneyOverviewComponent implements OnChanges {
  @Input() public journey: ActiveJourney;
  @Input() public activeStep: JourneyStep;
  @Input() public activeSteps: JourneyStep[];
  @Input() public overview = false;
  @Input() public showOverview = true;

  @Output() public openJourney: EventEmitter<JourneyNavigationContainer> = new EventEmitter();

  public seen = 0;

  public ngOnChanges() {
    if (this.activeSteps?.length) {
      let seen = 0;
      for (const s of this.activeSteps) {
        if (s.achieved) {
          seen++;
        }
      }
      this.seen = seen;
    }
  }

  public openStep(step: JourneyStep) {
    return this.openJourney.emit({
      type: JourneyNavigationType.Step,
      step
    });
  }

  public openOverview() {
    return this.openJourney.emit({
      type: JourneyNavigationType.Overview,
      step: this.activeStep
    });
  }
}
