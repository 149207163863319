<div *ngIf="showOverview && journey && activeSteps?.length">
  <div class="mx-5 mt-20">
    <h2 class="mb-2">{{journey.name}}</h2>
  </div>
  <div class="mb-6 h-2 bg-primary" [style.width]="(seen) * 100 / activeSteps.length + '%'"></div>
  <div
    class="mx-5 my-2 flex items-center justify-between border-b-2 border-b-slate-400 py-2"
    role="button"
    *ngFor="let step of activeSteps"
    [class.!border-b-primary]="activeStep?.node?.uuid === step?.node?.uuid"
    (click)="openStep(step)"
  >
    <div class="flex-grow-0 hyphens-auto">
      {{step.node.name}} <br />
      <app-resource-icon [resources]="step.node.resources"></app-resource-icon>
    </div>

    <div class="h-min" *ngIf="step.achieved && activeStep?.node?.uuid !== step.node.uuid">
      <fa-icon class="text-primary" [icon]="['fal', 'check-circle']" [size]="'2x'"> </fa-icon>
    </div>
    <div class="spin h-min" *ngIf="activeStep?.node?.uuid === step.node.uuid">
      <fa-icon class="text-primary" [icon]="['fal', 'spinner-third']" [size]="'2x'"> </fa-icon>
    </div>
    <div class="h-min" *ngIf="!step.achieved && activeStep?.node?.uuid !== step.node.uuid">
      <fa-icon class="text-primary" [icon]="['fal', 'circle']" [size]="'2x'"> </fa-icon>
    </div>
  </div>

  <div class="mx-5 my-2 flex items-center justify-between border-b-2 border-b-slate-400 py-2" role="button" (click)="openOverview()">
    <div>{{'Overview'}}</div>

    <div class="h-min" *ngIf="!overview">
      <fa-icon class="text-primary" [icon]="['fal', 'tasks']" [size]="'2x'"> </fa-icon>
    </div>
    <div class="spin h-min" *ngIf="overview">
      <fa-icon class="text-primary" [icon]="['fal', 'spinner-third']" [size]="'2x'"> </fa-icon>
    </div>
  </div>
</div>
