import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export enum RankingOrigin {
  all = 'all',
  best = 'best',
  first = 'first'
}

export enum RankingUserFilter {
  existing = 'existing',
  named = 'named'
}

export enum RankingOp {
  equal = '=',
  not_equal = '!=',
  gte = '>=',
  gt = '>',
  lte = '<=',
  lt = '<',
  in = 'in',
  contains = 'contains',
  starts_with = 'starts_with',
  ends_with = 'ends_with'
}

export interface RankingRequest {
  from?: number;
  limit?: number;
  center?: boolean;
  selector?: RankingDatasetSelector | RankingDatasetSelector[];
  filter?: RankingFilter | RankingFilter[];
  users?: RankingUserFilter | RankingUserFilter[];
  timespan?: RankingTimeSpan;
}

export interface RankingDatasetSelector {
  filter?: RankingFilter | RankingFilter[];
  origin?: RankingOrigin;
}

export interface RankingFilter {
  key?: string;
  value?: string | string[];
  op?: RankingOp;
}

export interface RankingTimeSpan {
  from?: Date;
  to?: Date;
}

export interface RankingResponse {
  entries?: RankingEntry[];
  total?: number;
  self?: RankingEntry;
}

export interface RankingEntry {
  rank?: number;
  uuid?: string;
  alias?: string;
  games?: number;
  perfect?: number;
  score?: number;
  time?: number;
}

@Injectable({
  providedIn: 'root'
})
export class RankingServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'RankingServerService';
  }

  public async rankingGet(params: RankingRequest): Promise<RankingResponse> {
    return this.message(this.env.getServerUrl('RANKING_SERVER', `/ranking`), 'get', params, true, CacheKey);
  }
}
