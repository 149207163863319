<div *transloco="let t;">
  <div *ngIf="bookmarks && bookmarks.length > 0">
    <h2>{{t('app.bookmarks')}}</h2>
    <div class="mb-2 flex w-full gap-2" *ngFor="let n of bookmarks; let i = index">
      <button class="s8l-button s8l-b-clear grow shadow" (click)="navigate(n)">
        {{ n.labels.bookmark.title.split('/').slice(-1)[0].trim() }}
      </button>
      <button class="s8l-button s8l-b-clear s8l-b-icon-only" (click)="removeBookmark(n)">
        <fa-icon [icon]="['fal', 'trash']" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
  </div>
</div>
