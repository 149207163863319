import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

import { ComponentsModule } from 'src/app/components/components.module';

import { DateRangeComponent } from './date-range/date-range.component';
import { EventComponent } from './event/event.component';

@NgModule({
  declarations: [DateRangeComponent, EventComponent],
  exports: [DateRangeComponent, EventComponent],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule, TranslocoModule, TranslocoLocaleModule, ComponentsModule, FormsModule]
})
export class BookingModule {}
