<div class="h-[33vh] w-full overflow-hidden md:h-[50vh]">
  <div class="scrollbar-hide flex h-full w-full snap-x snap-mandatory overflow-x-auto scroll-smooth pb-4" #scrollContainer>
    <figure
      class="flex h-full w-full shrink-0 snap-start items-end rounded-b-[1.5rem] bg-zinc-700 bg-cover bg-clip-border bg-center bg-no-repeat px-5 py-4 bg-blend-overlay shadow-md"
      [class.!bg-top]="slide.imageAnchor === 'top'"
      [class.!bg-bottom]="slide.imageAnchor === 'bottom'"
      role="button"
      *ngFor="let slide of slides"
      appImageBackground
      [image]="slide.image"
      [imageWidth]="1920"
      [imageHeight]="1080"
      (click)="navigate(slide.link, slide.externalLink)"
    >
      <div class="grow pl-3 text-left">
        <h1 class="m-1 text-2xl font-semibold text-white md:text-4xl">{{slide.headline.trim()}}</h1>
        <span class="m-1 text-base text-white md:text-xl">{{slide.text.trim()}}</span>

        <div class="mt-1 flex">
          <div class="grow"></div>
          <div
            class="m-1 h-3 w-3 rounded-full border-2 border-white"
            role="button"
            *ngFor="let slide of slides; let index = index"
            [class.bg-black]="slideIndex === index"
            [class.bg-page]="slideIndex !== index"
            (click)="$event.stopPropagation(); clickSelector(index)"
          ></div>
          <div class="grow"></div>
        </div>
      </div>
    </figure>
  </div>
</div>
