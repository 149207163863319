import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexGrid, ApexYAxis, ApexTooltip } from 'ng-apexcharts';

import { ChartData, fmtMinutes } from '../dashboard.utils';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'overview-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges {
  @Input() public values: Array<ChartData>;
  @Input() public label: string;
  @Input() public duration = 14;

  @Output() public durationChange = new EventEmitter<number>();

  public chartOptions: Partial<ChartOptions>;

  public ngOnChanges() {
    if (this.label && this.values) {
      this.chartOptions = this.createLineChart(this.label, this.values, fmtMinutes);
    }
  }

  // as stats server gets called once over, just pass through
  public durationChanges(d: number) {
    this.duration = d;
    this.durationChange.emit(d);
  }

  private createLineChart(label: string, values: Array<ChartData>, formatter: any): Partial<ChartOptions> {
    return {
      series: [
        {
          name: label,
          data: values.map(v => {
            return {
              x: typeof v.name === 'string' ? v.name : v.name.getTime(),
              y: v.value
            };
          })
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['transparent'],
          opacity: 0.5
        }
      },
      yaxis: {
        title: {
          text: label
        }
      },
      xaxis: {
        type: 'datetime'
      },
      tooltip: {
        y: {
          formatter: formatter
        }
      }
    };
  }
}
