import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface PortalTemplate {
  portal: string;
  tmpl: TemplateRef<unknown>;
}

@Injectable({
  providedIn: 'root'
})
export class PortalService {
  private templateMap: { [uuid: string]: PortalTemplate } = {};

  private _templates = new BehaviorSubject<PortalTemplate[]>([]);
  public templates(portal: string): Observable<TemplateRef<unknown>[]> {
    return this._templates.asObservable().pipe(map(t => t.filter(e => e.portal == portal).map(e => e.tmpl)));
  }

  public push(portal: string, uuid: string, tmpl: TemplateRef<unknown>) {
    this.templateMap[uuid] = {
      portal,
      tmpl
    };
    this.updateTemplates();
  }

  public pop(portal: string, uuid: string) {
    delete this.templateMap[uuid];
    this.updateTemplates();
  }

  private updateTemplates() {
    this._templates.next(Object.values(this.templateMap));
  }
}
