import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@s8l/client-tree-lib';
import { firstValueFrom } from 'rxjs';

declare const VERSION: string;
declare const LANGUAGE_HASHES: { [index: string]: string };

export interface Environment extends AppConfig {
  bundle: string;
  version: string;

  logging: boolean;
  production: boolean;

  language_hashes: { [index: string]: string };

  user_ping_interval: number;

  sentry_url: string;
}

/* Common defaults for all environments */
const defaults: Partial<Environment> = {
  version: VERSION,
  bundle: 'com.straightlabs.standard.quiz',
  language_hashes: LANGUAGE_HASHES,

  logging: true,
  production: false,

  user_ping_interval: 60 * 1000,
  sentry_url: 'https://3624cb70faf44b5d9200eaa81cc13c1c@crash.str8labs.net/8'
};

function makeURLs(ENV_URL: string) {
  const HTTP_API_URL = `https://api.${ENV_URL}`;
  const WS_API_URL = `wss://api.${ENV_URL}`;
  return {
    QUIZ_MP_SERVER_WS: WS_API_URL + '/quiz-mp/game',
    NOTIFICATION_SERVER_WS: WS_API_URL + '/notification/notification',
    CHAT_SERVER_WS: WS_API_URL + '/chat/chat',
    USER_SERVER_WS: WS_API_URL + '/user/event',

    QUIZ_SP_SERVER_URL: HTTP_API_URL + '/quiz-sp',
    QUIZ_MP_SERVER_URL: HTTP_API_URL + '/quiz-mp',
    QUIZ_EXAM_SERVER_URL: HTTP_API_URL + '/quiz-exam',
    QUIZ_CASESTUDY_SERVER_URL: HTTP_API_URL + '/quiz-casestudy',
    QUIZ_REVIEW_SERVER_URL: HTTP_API_URL + '/quiz-review',

    RANKING_SERVER_URL: HTTP_API_URL + '/ranking',
    RANKING_SERVER_VR_URL: HTTP_API_URL + '/ranking-vr',
    STATS_SERVER_URL: HTTP_API_URL + '/stats',
    USER_SERVER_URL: HTTP_API_URL + '/user',
    CONTENT_TREE_SERVER_URL: HTTP_API_URL + '/content-tree',
    REMINDER_SERVER_URL: HTTP_API_URL + '/reminder',
    RECOMMENDER_SERVER_URL: HTTP_API_URL + '/recommender',
    NOTIFICATION_SERVER_URL: HTTP_API_URL + '/notification',
    FEEDBACK_SERVER_URL: HTTP_API_URL + '/feedback',
    WIKI_SERVER_URL: HTTP_API_URL + '/wiki',
    GLOSSARY_SERVER_URL: HTTP_API_URL + '/glossary',
    DESCRIPTION_SERVER_URL: HTTP_API_URL + '/description',
    IMPULS_SERVER_URL: HTTP_API_URL + '/impuls',
    CASESTUDY_SERVER_URL: HTTP_API_URL + '/casestudy',
    LEGAL_SERVER_URL: HTTP_API_URL + '/legal',
    FLASHCARD_SERVER_URL: HTTP_API_URL + '/flashcard',
    FLASHCARD_SELECTION_SERVER_URL: HTTP_API_URL + '/flashcard-selection',
    MEDIA_SERVER_URL: HTTP_API_URL + '/media',
    ACHIEVEMENT_SERVER_URL: HTTP_API_URL + '/achievement',
    VIDEO_SERVER_URL: HTTP_API_URL + '/video',
    USER_NODE_CONTENT_SERVER_URL: HTTP_API_URL + '/user-node-content',
    BOOKING_SERVER_URL: HTTP_API_URL + '/booking',
    SEARCH_SERVER_URL: HTTP_API_URL + '/search',
    SAML_LOGIN_SERVER_URL: HTTP_API_URL + '/saml-login'
  };
}

export async function applyConfig(http: HttpClient, initials: any): Promise<Environment> {
  const config = await firstValueFrom(http.get<any>('/config.json')).then(config => ({
    ...defaults,
    ...initials,
    ...makeURLs(config.env_url),
    ...config
  }));

  if (config?.wiki?.colors) {
    for (const key of Object.keys(config.wiki.colors)) {
      document.documentElement.style.setProperty(`--custom-${key}`, config.wiki.colors[key]);
    }
  }

  if (config?.app?.colors) {
    for (const key of Object.keys(config.app.colors)) {
      document.documentElement.style.setProperty(`--s8l-${key}`, config.app.colors[key]);
    }
  }

  return config;
}
