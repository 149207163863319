import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from 'ng-apexcharts';

import { ChartData, fmtMinutes } from '../dashboard.utils';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'overview-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.html']
})
export class BarChartComponent implements OnChanges {
  @Input() public values: Array<ChartData>;
  @Input() public label: string;
  @Input() public duration = 14;

  @Output() public durationChange = new EventEmitter<number>();

  public chartOptions: Partial<ChartOptions>;

  public ngOnChanges(): void {
    if (this.label && this.values) {
      this.chartOptions = this.createBarChart(this.label, this.values, fmtMinutes);
    }
  }

  // as stats server gets called once over, just pass through
  public durationChanges(d: number) {
    this.duration = d;
    this.durationChange.emit(d);
  }

  private createBarChart(label: string, values: Array<ChartData>, formatter: any): Partial<ChartOptions> {
    return {
      series: [
        {
          name: label,
          data: values.map(v => v.value)
        }
      ],
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: values.map(v => v.name),
        labels: {
          rotate: -90,
          rotateAlways: true,
          trim: true,
          maxHeight: 160
        }
      },
      yaxis: {
        title: {
          text: label
        }
      },
      tooltip: {
        y: {
          formatter: formatter
        }
      },
      fill: {
        opacity: 1
      }
    };
  }
}
