import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService, TreeConfig } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface InfoInfo {
  rootNode?: string;
  features?: string[];
  config: TreeConfig;
  colors?: { [name: string]: string };
}

export interface NodePath {}

export interface NodeNode {
  path?: NodePath;
  metric?: number;
  generation?: number;
  meta?: NodeMetadata;
  resources?: NodeEnum[];
  uuid?: string;
}

export interface NodeMetadata {
  sidemenu?: boolean;
  icon?: string;
  wikiLink?: string;
  color?: string;
  images?: string[];
  mobileImages?: string[];
  imageAnchors?: string[];
  mobileAnchors?: string[];
  mediafiles?: string[];
}

export interface NodeEnum {}

export interface NodeNestedRawNode {
  path?: NodePath;
  metric?: number;
  generation?: number;
  meta?: NodeMetadata;
  resources?: NodeEnum[];
  uuid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContentTreeServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'ContentTreeServerService';
  }

  public async infoGet(params: void): Promise<InfoInfo> {
    return this.message(this.env.getServerUrl('CONTENT_TREE_SERVER', `/info`), 'get', params, true, CacheKey);
  }

  public async nodeGet(params: NodePath): Promise<NodeNode> {
    return this.message(this.env.getServerUrl('CONTENT_TREE_SERVER', `/node`), 'get', params, true, CacheKey);
  }

  public async nodeRecursive(params: NodePath): Promise<NodeNestedRawNode> {
    return this.message(this.env.getServerUrl('CONTENT_TREE_SERVER', `/node`), 'recursive', params, true, CacheKey);
  }
}
