import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';

import { PopupComponent, ModalHostDirective } from './popup.component';

@NgModule({
  declarations: [PopupComponent, ModalHostDirective],
  imports: [CommonModule, FontAwesomeModule, TranslocoModule],
  exports: [PopupComponent]
})
export class PopupModule {}
