import { Component, Input, OnChanges } from '@angular/core';
import chroma from 'chroma-js';

import { TreeService } from 'src/app/services/tree.service';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent implements OnChanges {
  @Input() public seed: string;

  public gradients: {
    start: string;
    stop: string;
  }[] = [];
  public paths: string[] = [];

  private hash: number;

  constructor(private tree: TreeService) {}

  public ngOnChanges() {
    if (!this.seed) {
      return;
    }

    this.hash = this.funhash(this.seed);

    let startColor = '#fff';
    let endColor = '#000';

    if (this.tree.colorPalette) {
      startColor = this.tree.colorPalette[4];
      endColor = this.tree.colorPalette[5];
    }

    const palette = chroma.bezier([startColor, endColor]).scale().mode('lch').correctLightness().colors(4);

    const gradients: any[] = [];
    for (let i = 0; i < 4; i++) {
      const saturate = 5 + ((this.hash >> (i * 2)) & 0x3) * 2;
      const brighten = 3 + ((this.hash >> ((i + 1) * 2)) & 0x3) * 2;

      const start = chroma(palette[i]).brighten(1).hex();
      const stop = chroma(start).brighten(brighten).saturate(saturate).hex();
      gradients.push({
        start: start,
        stop: stop
      });
    }
    this.gradients = gradients;

    const verticalTicks = 128;
    const verticalHash = this.rotate(this.hash, 4);
    const curveHash = this.rotate(this.hash, 24);

    const paths: string[] = [];
    let sign = -1;
    for (let i = 0; i < 3; i++) {
      //M 0,88 C 34.2,82.6 102.6,54.4 171,61 C 239.4,67.6 273.8,117.4 342,121 C 410.2,124.6 478,87.4 512,79L512 512L0 512z
      const curves = 2 + ((curveHash >> (i * 2)) & 0x3);
      const vertialMod = (verticalHash >> (i * 8)) & 0xff;
      const verticalStart = (i + 1) * verticalTicks - vertialMod * 0.5;
      const curveMod = this.rotate(this.hash, i * 4);

      let lastPos = 0;
      let path = `M 0,${verticalStart}`;
      for (let j = 0; j < curves; j++) {
        const tick = 512 / curves;

        path += ' C';
        path += ` ${lastPos.toFixed(2)},${verticalStart}`;
        path += ` ${(lastPos + tick * 0.5).toFixed(2)},${verticalStart + sign * (curveMod & 0x7f)}`;
        path += ` ${(lastPos + tick * 1.0).toFixed(2)},${verticalStart}`;

        sign = sign > 0 ? -1 : 1;
        lastPos += tick;
      }
      path += ' L512 512 L0 512 Z';

      paths.push(path);
    }
    this.paths = paths;
  }

  private funhash(s: string) {
    let h = 0xdeadbeef;
    for (let i = 0; i < s.length; i++) {
      h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
    }
    return (h ^ (h >>> 16)) >>> 0;
  }

  private rotate(val: number, n: number) {
    return (val << (32 - n)) | (val >> n);
  }
}
