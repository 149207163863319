import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AppRouterService } from 'src/app/services/app-router.service';
import { Achievement, UserJourneyService } from 'src/app/services/user-journey.service';

@Component({
  selector: 'dashboard-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit, AfterViewChecked {
  public achivements: Achievement[];
  public canScroll = true;

  @ViewChild('container') private container: ElementRef<HTMLDivElement>;

  constructor(private jourey: UserJourneyService, private app: AppRouterService) {}

  public ngOnInit() {
    this.achivements = this.jourey.achievements.sort((a, b) => this.score(b) - this.score(a)).slice(0, 6);
  }

  public ngAfterViewChecked() {
    setTimeout(() => {
      this.canScroll = this.container.nativeElement.scrollWidth != this.container.nativeElement.clientWidth;
    });
  }

  private score(a: Achievement) {
    const now = Date.now();
    if (a.achieved > 0 && this.checkable(a)) {
      return 3;
    }
    if (a.achieved <= 0 && (!a.event?.end || Date.parse(a.event?.end) > now)) {
      return 2;
    }
    if (a.achieved > 0 && !this.checkable(a)) {
      return 1;
    }
    return 0;
  }

  private checkable(a: Achievement) {
    return !((a.config?.checked && !a.repeatable) || (a.repeatable && a.config.checked >= a.achieved));
  }

  public navigateToAchievements() {
    return this.app.navigateRaw(['user', 'achievement']);
  }

  public scroll(container: HTMLDivElement, direction: number) {
    const childWidth = container.firstElementChild?.clientWidth || 0;
    container.scrollLeft += direction * childWidth;
  }
}
