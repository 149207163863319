import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { JSONRPCError, JSONRPCErrorCode, NetworkError } from '@s8l/client-tree-lib';
import * as Sentry from '@sentry/browser';

import { AppLifecycleService } from './app-lifecycle.service';
import { environment } from '../../environments/environment';
import { PopupService } from '../modules/popup/popup.service';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry_url,
    release: environment.version
  });
}

@Injectable({ providedIn: 'root' })
export class AlertErrorHandler implements ErrorHandler {
  private activeErrors: { [index: string]: boolean } = {};

  constructor(private pop: PopupService, private injector: Injector, private ngZone: NgZone) {}

  public async handleError(error: any): Promise<void> {
    const err = error.rejection || error.originalError || error;

    console.error(err);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
      return;
    }

    if (err instanceof JSONRPCError) {
      if (err.code === JSONRPCErrorCode.UNAUTHORIZED) {
        await this.createAlert(translate('alert.logout'), translate('alert.logoutinfo'), true);
      } else if (err.code === JSONRPCErrorCode.TOO_MANY_REQUESTS || err.code == JSONRPCErrorCode.TOO_MANY_REQUESTS) {
        await this.createAlert(translate('alert.login'), translate('alert.logininfo'), true);
      } else if (err.code === JSONRPCErrorCode.INVALID_NAME && err.data === 'already_in_use') {
        await this.createAlert(translate('alert.aliasused'), translate('alert.aliasusedinfo'), false);
      } else if (err.code === JSONRPCErrorCode.INVALID_NAME) {
        await this.createAlert(translate('alert.aliasinvalid'), translate('alert.aliasinvalidinfo'), false);
      } else if (err.code === JSONRPCErrorCode.USER_NOT_WHITELISTED) {
        await this.createAlert(translate('alert.whitelist'), translate('alert.whitelistinfo'), true);
      } else {
        await this.createAlert(translate('alert.unknown'), translate('alert.unknowninfo'), true, true);

        if (environment.production) {
          Sentry.captureException(err);
        }
      }
    } else if (err instanceof NetworkError) {
      await this.createAlert(translate('alert.network'), translate('alert.networkinfo'), true);
    } else if (err.message == 'authentication failed (401)') {
      await this.createAlert(translate('alert.logout'), translate('alert.logoutinfo'), true);
    } else {
      await this.createAlert(translate('alert.unknown'), translate('alert.unknowninfo'), true, true);

      if (environment.production) {
        Sentry.captureException(err);
      }
    }
  }

  private async createAlert(header: string, message: string, shouldLogout: boolean, unknownError = false) {
    // do not create an alert if there is already one.
    const key = header + message + (shouldLogout ? 'T' : 'F') + (unknownError ? 'T' : 'F');
    if (this.activeErrors[key]) {
      return;
    }
    this.activeErrors[key] = true;

    const lifecycle = this.injector.get(AppLifecycleService);
    const timeout = new Promise<void>(resolve => setTimeout(() => resolve(), 0));
    return timeout.then(() =>
      this.ngZone.run(() =>
        this.pop
          .createAlert({
            header,
            message,
            handler: () => {
              delete this.activeErrors[key];
              if (shouldLogout) {
                void lifecycle.deinit(unknownError);
              }
            }
          })
          .then(alert => alert.present())
      )
    );
  }
}
