import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Booking, BookingEvent, BookingServerService } from 'src/app/services/jsonrpc/booking-server.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'booking-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  @Input() public event: BookingEvent;
  @Input() public isBooked = false;
  @Input() public isFull = false;

  public booking = false;
  public canceling = false;

  public user: Booking;

  @Output() public booked: EventEmitter<{ user: Booking; ev: BookingEvent }> = new EventEmitter<{ user: Booking; ev: BookingEvent }>();
  @Output() public cancel: EventEmitter<BookingEvent> = new EventEmitter<BookingEvent>();

  constructor(private profile: ProfileService, private bookingServer: BookingServerService) {}

  public ngOnInit(): void {
    this.user = { firstname: this.profile.meta.firstname, lastname: this.profile.meta.lastname, email: this.profile.meta.mail };
  }

  public cancelingEvent() {
    return (this.canceling = true);
  }

  public cancelCancelingEvent() {
    return (this.canceling = false);
  }

  public unbookEvent() {
    return this.cancel.emit(this.event);
  }

  public startBook(element: HTMLElement) {
    this.booking = true;
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 50);
  }

  public cancelBooking() {
    return (this.booking = false);
  }

  public confirmBook() {
    return this.booked.emit({ user: this.user, ev: this.event });
  }
}
