import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfWeek,
  endOfYear,
  format,
  getDaysInMonth,
  getISODay,
  getMonth,
  getYear,
  isToday,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input()
  public date = new Date();
  @Output()
  public dateChange = new EventEmitter<Date>();

  public today = new Date();
  public dayNames = eachDayOfInterval({ start: startOfWeek(this.today), end: endOfWeek(this.today) }).map(d => format(d, 'EEE'));
  public monthNames = eachMonthOfInterval({ start: startOfYear(this.today), end: endOfYear(this.today) }).map(d => format(d, 'MMMM'));

  public showDatepicker = false;

  public month = 0;
  public year = 0;

  public days: number[] = [];

  constructor(private elRef: ElementRef) {}

  public ngOnInit(): void {
    this.year = getYear(this.date);
    this.month = getMonth(this.date);
    this.updateDays();
  }

  public updateDate(date: number) {
    this.date = new Date(this.year, this.month, date);
    this.dateChange.emit(this.date);
    this.showDatepicker = false;
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: PointerEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.showDatepicker = false;
    }
  }

  public updateMonth(delta: number) {
    this.month = this.month + delta;
    this.updateDays();
  }

  private updateDays() {
    const date = startOfMonth(new Date(this.year, this.month));

    const daysArray = [];
    const start = getISODay(date);
    for (let i = 1; i < start; i++) {
      daysArray.push(0);
    }

    const daysInMonth = getDaysInMonth(date);
    for (let i = 0; i < daysInMonth; i++) {
      daysArray.push(i + 1);
    }

    this.days = daysArray;
  }

  public isToday(date: number) {
    return isToday(new Date(this.year, this.month, date));
  }
  public trackByIdentity = (index: number, item: any) => item;
}
