import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface MediaDownloadRequest {
  uuid?: string;
  path?: string;
  attachment?: boolean;
  bypassCache?: boolean;
}

export interface MediaPresignedUuidResponse {
  uuid?: string;
  url?: string;
}

export interface MediaPurgeRequest {
  language?: string;
  dryRun?: boolean;
}

export interface MediaCopyRequest {
  newPath?: string;
  path?: string;
}

export interface MediaListRequest {
  recursive?: boolean;
  types?: string[];
  path?: string;
}

export interface MediaFileItem {
  uuid?: string;
  path?: string;
  type?: string;
}

export interface MediaPathRequest {
  path?: string;
}

export interface MediaImageVariantRequest {
  width?: number;
  height?: number;
  gravity?: string;
  uuid?: string;
  path?: string;
  attachment?: boolean;
  bypassCache?: boolean;
}

export interface MediaPutRequest {
  type?: string;
  uuid?: string;
  path?: string;
}

export interface MediaPresignedUrlResponse {
  url?: string;
}

export interface MediaObjectInfoResponse {
  etag?: string;
  uuid?: string;
  path?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'MediaServerService';
  }

  public async mediaGet(params: MediaDownloadRequest): Promise<MediaPresignedUuidResponse> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'get', params, true, CacheKey);
  }

  public async mediaGetByUuid(params: MediaDownloadRequest): Promise<MediaPresignedUuidResponse> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'getByUuid', params, true, CacheKey);
  }

  public async mediaList(params: MediaListRequest): Promise<MediaFileItem[]> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'list', params, true, CacheKey);
  }

  public async mediaGetImage(params: MediaImageVariantRequest): Promise<MediaPresignedUuidResponse> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'getImage', params, true, CacheKey);
  }

  public async mediaGetImages(params: MediaImageVariantRequest[]): Promise<MediaPresignedUuidResponse[]> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'getImages', params, true, CacheKey);
  }

  public async mediaInfo(params: MediaPathRequest): Promise<MediaObjectInfoResponse> {
    return this.message(this.env.getServerUrl('MEDIA_SERVER', `/media`), 'info', params, true, CacheKey);
  }
}
