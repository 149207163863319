import { Component, Input, OnInit } from '@angular/core';
import { JSONRPCErrorCode, Path } from '@s8l/client-tree-lib';
import { addMonths } from 'date-fns';

import { AppRouterService } from 'src/app/services/app-router.service';
import {
  RankingEntry,
  RankingFilter,
  RankingOp,
  RankingOrigin,
  RankingResponse,
  RankingServerService,
  RankingTimeSpan
} from 'src/app/services/jsonrpc/ranking-server.service';
import { TreeService } from 'src/app/services/tree.service';

@Component({
  selector: 'dashboard-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  @Input() public range = 5;

  public ranked = true;
  public me: RankingEntry = {};
  public entries: RankingEntry[] = [];
  public indices: number[] = [];

  constructor(private tree: TreeService, private ranking: RankingServerService, private appRouter: AppRouterService) {}

  public ngOnInit() {
    return this.fetch();
  }

  public fetch() {
    return this.getQuizRanking(new Path([this.tree.root.name]))
      .then(result => {
        this.me = result.self;
        this.entries = result.entries;
        this.ranked = true;

        if (!this.me) {
          return;
        }

        const indices = new Set<number>();

        indices.add(this.me.rank - 1);
        for (let i = 0; i < this.range + 1; i++) {
          if (this.me.rank - i >= 0) {
            indices.add(this.me.rank - i - 1);
          }
          if (this.me.rank + i < this.entries.length) {
            indices.add(this.me.rank + i - 1);
          }
        }

        this.indices = [...indices].sort((a, b) => a - b).filter(i => i >= 0);
      })
      .catch(err => {
        if (err.code === JSONRPCErrorCode.NOT_FOUND) {
          this.ranked = false;
        } else if (err.code !== JSONRPCErrorCode.UNAUTHORIZED) {
          throw err;
        }
      });
  }

  public navigateToLeaderboard() {
    return this.appRouter.navigateRaw(['user', 'ranking']);
  }

  private getQuizRanking(path: Path): Promise<RankingResponse> {
    const pathPrefix = path
      .get()
      .map(s => encodeURIComponent(s))
      .join('/');
    const bonusFilter: RankingFilter[] = [{ key: 'type', value: ['achievement'] }];
    const quizFilters: RankingFilter[] = [
      { key: 'type', value: ['quiz-default', 'quiz-exam', 'quiz-casestudy', 'quiz-multiplayer'] },
      { key: 'path', value: pathPrefix, op: RankingOp.starts_with }
    ];
    const timeFilter: RankingTimeSpan = {
      from: addMonths(new Date(), -1)
    };
    return this.ranking.rankingGet({
      selector: [
        { origin: RankingOrigin.all, filter: quizFilters },
        { origin: RankingOrigin.all, filter: bonusFilter }
      ],
      timespan: timeFilter
    });
  }
}
