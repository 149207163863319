<fa-icon
  *ngIf="resources && (hasFeature('quiz-mp') || hasFeature('quiz-sp'))"
  placement="top"
  [class]="res.QUIZ ? 'present':'none'"
  [class]=""
  [icon]="['fal', 'question-circle']"
  [size]="size"
>
</fa-icon>
<fa-icon *ngIf="resources" placement="top" [class]="res.WIKI ? 'present':'none'" [icon]="['fal', 'book-alt']" [size]="size"> </fa-icon>
<fa-icon
  *ngIf="resources && hasFeature('glossary')"
  placement="top"
  [class]="res.GLOSSARY ? 'present':'none'"
  [icon]="['fal', 'search-plus']"
  [size]="size"
>
</fa-icon>
<fa-icon
  *ngIf="resources && hasFeature('impuls')"
  placement="top"
  [class]="res.IMPULS ? 'present':'none'"
  [icon]="['fal', 'list']"
  [size]="size"
>
</fa-icon>
<fa-icon
  *ngIf="resources && hasFeature('casestudy')"
  placement="top"
  [class]="res.CASESTUDY ? 'present':'none'"
  [icon]="['fal', 'file-edit']"
  [size]="size"
>
</fa-icon>
<!--
  <fa-icon *ngIf="resources" placement="top" [class]="res.MEDIA ? 'present':'none'" [icon]="['fal', 'image']" [size]="size"> </fa-icon>
-->
<fa-icon
  *ngIf="resources && hasFeature('flashcard')"
  placement="top"
  [class]="res.FLASHCARD ? 'present':'none'"
  [icon]="['fal', 'th-large']"
  [size]="size"
>
</fa-icon>
<!--
  <fa-icon
  *ngIf="resources && hasFeature('description')"
  placement="top"
  ngbTooltip="Description"
  [class]="res.DESCRIPTION ? 'present':'none'"
  icon="info-square"
  [size]="size"
  >
</fa-icon>
-->
