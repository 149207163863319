<ng-container *transloco="let t;">
  <div class="flex justify-between">
    <button class="mx-2 hidden text-primary md:block" (click)="scroll(container, -1)" [class.invisible]="!canScroll">
      <fa-icon class="text-4xl" [icon]="['fal', 'chevron-left']"></fa-icon>
    </button>
    <div class="scrollbar-hide flex snap-x snap-mandatory items-stretch gap-6 overflow-x-auto scroll-smooth px-32 py-6 md:px-3" #container>
      <app-achievement-card
        class="flex max-w-[16rem] shrink-0 grow snap-center snap-always scroll-mx-3 md:w-[calc(33%-0.75rem)]"
        *ngFor="let a of achivements; let i = index"
        [achievement]="a"
        [index]="i"
      ></app-achievement-card>
    </div>
    <button class="mx-2 hidden text-primary md:block" (click)="scroll(container, 1)" [class.invisible]="!canScroll">
      <fa-icon class="text-4xl" [icon]="['fal', 'chevron-right']"></fa-icon>
    </button>
  </div>

  <button class="s8l-button s8l-b-clear mx-auto mt-4 max-w-full bg-white shadow" (click)="navigateToAchievements()">
    <fa-icon [icon]="['fal', 'shield-check']" style="margin-right: 6px"></fa-icon>
    {{t('dashboard.achievements')}}
  </button>
</ng-container>
