import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { JSONRPCError } from '@s8l/client-tree-lib';

import { AccountPasswordRule, UserServerService } from './jsonrpc/user-server.service';

@Injectable({ providedIn: 'root' })
export class PasswordService {
  constructor(private user: UserServerService) {}

  private fallback: AccountPasswordRule[] = [
    {
      pattern: '^.{8,}$',
      match: true,
      error: new JSONRPCError(3121, 'too_short', { info: 'Password is too short' })
    },
    {
      pattern: '^.{0,64}$',
      match: true,
      error: new JSONRPCError(3121, 'too_long', { info: 'Password is too long' })
    },
    {
      pattern: '.*[a-z].*',
      match: true,
      error: new JSONRPCError(3121, 'require_lower_case', { info: 'Password requires at least one lower case letter' })
    },
    {
      pattern: '.*[A-Z].*',
      match: true,
      error: new JSONRPCError(3121, 'require_upper_case', { info: 'Password requires at least one upper case letter' })
    },
    {
      pattern: '.*[0-9].*',
      match: true,
      error: new JSONRPCError(3121, 'require_number', { info: 'Password requires at least one number' })
    },
    {
      pattern: '^[\\p{L}0-9]*$',
      match: false,
      error: new JSONRPCError(3121, 'require_special', { info: 'Password requires at least one special character' })
    }
  ];

  public passwordValidators(): Promise<ValidatorFn[]> {
    return this.user
      .accountPasswordRules()
      .catch(() => this.fallback)
      .then(response => response.map(rule => this.createValidator(rule)));
  }

  private createValidator(rule: AccountPasswordRule): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      const match = new RegExp(rule.pattern, 'u').test(control.value);
      return match == rule.match ? null : { [rule.error.message]: true };
    };
  }
}
