import { Component, Input } from '@angular/core';

import { HyphenationService } from 'src/app/services/hyphenation.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
  @Input() public hero;
  @Input() public resourceHeroImage;

  public heroImageUrl;
  public heroMobileImageUrl;

  public get title() {
    if (!this.hero?.title || this.hero?.title.length == 0) {
      return '';
    }
    if (this.hero?.hyphenate) {
      return this.hyphen.hyphenate(this.hero?.title);
    }
    return this.hero?.title.trim();
  }
  public get subtitle() {
    if (!this.hero?.subtitle || this.hero?.subtitle.length == 0) {
      return '';
    }
    if (this.hero?.hyphenate) {
      return this.hyphen.hyphenate(this.hero?.subtitle);
    }
    return this.hero?.subtitle.trim();
  }

  constructor(private hyphen: HyphenationService) {}
}
