import { trigger, style, animate, transition } from '@angular/animations';
import { Component, Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';

import { AppRouterService } from 'src/app/services/app-router.service';

import { ModalOptions, PopupService, Toast, ToastColors } from './popup.service';

@Directive({
  selector: '[appModalHost]'
})
export class ModalHostDirective implements OnChanges {
  @Input() public modal: ModalOptions;

  constructor(public viewRef: ViewContainerRef) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.modal) {
      this.createComponent(this.modal.component);
    }
  }

  private createComponent(comp: any) {
    this.viewRef.clear();

    const ref = this.viewRef.createComponent(comp, {
      injector: this.viewRef.injector
    });
    for (const [key, val] of Object.entries(this.modal.componentProps || {})) {
      ref.setInput(key, val);
    }
  }
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))])
    ]),
    trigger('toastAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(0.5rem)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))])
    ])
  ]
})
export class PopupComponent {
  constructor(public pop: PopupService, private appRouter: AppRouterService) {}

  public toastColors = {
    [ToastColors.Success]: 'text-successs',
    [ToastColors.Danger]: 'text-red-400'
  };
  public toastIcons = {
    [ToastColors.Success]: 'check-circle',
    [ToastColors.Danger]: 'times-circle'
  };

  public async onLoadingDismiss() {
    if (this.pop.loading.opts.handler) {
      await this.pop.loading.opts.handler();
    }
    await this.pop.loading.dismiss();
  }

  public async onAlertDismiss() {
    if (!this.pop.alert || !this.pop.alert.shown) {
      return;
    }

    if (this.pop.alert.opts.handler) {
      await this.pop.alert.opts.handler();
    }
    await this.pop.alert.dismiss();
  }

  public async onToastClick(toast: Toast) {
    if (toast.opts.link) {
      await this.pop.dismissAllToasts();
      return this.appRouter.navigateRaw(toast.opts.link);
    } else {
      await toast.dismiss();
    }
  }
}
