<ng-template appPortalTemplate portal="header-left">
  <button class="s8l-button s8l-b-header" (click)="toggleSidemenu()">
    <fa-icon *ngIf="sidemenu" [icon]="['fal','chevron-square-left']" [fixedWidth]="true" size="lg"></fa-icon>
    <fa-icon *ngIf="!sidemenu" [icon]="['fal','chevron-square-right']" [fixedWidth]="true" size="lg"></fa-icon>
  </button>
</ng-template>

<div class="h-screen-fix flex flex-col justify-items-stretch">
  <div class="scrollbar-hide flex grow flex-row items-stretch overflow-y-auto">
    <app-journey-overview
      class="node-overview scrollbar-hide shrink-0 overflow-y-auto bg-page"
      [class.collapsed]="!sidemenu"
      [journey]="walk.activeJourney"
      [activeSteps]="walk.activeSteps"
      [activeStep]="walk.activeStep"
      [showOverview]="sidemenu"
      [overview]="isOverview"
      (openJourney)="walk.navigate($event)"
    ></app-journey-overview>
    <div class="scrollbar-hide grow overflow-y-auto" #outletContainter>
      <router-outlet #outlet></router-outlet>
    </div>
  </div>
  <div class="flex w-full shrink-0 flex-row items-center justify-between border-t border-grey bg-page py-2 shadow">
    <div class="flex flex-1 justify-start md:border-primary">
      <button class="my-2 px-2 text-primary disabled:text-grey" [disabled]="!walk.prevNode" (click)="previous()">
        <fa-icon [icon]="['fal','chevron-left']" [fixedWidth]="true" size="2x"></fa-icon>
      </button>
    </div>

    <div class="flex snap-x snap-mandatory divide-x divide-primary overflow-x-auto" *transloco="let t;">
      <div class="my-2 snap-center px-4" *ngFor="let method of methods">
        <button class="text-primary" (click)="walk.openMenu(method)">
          <fa-icon [icon]="['fal', method.icon]" [fixedWidth]="true" size="2x"></fa-icon>
          <div class="text-center text-base">{{ t(method.text) }}</div>
        </button>
      </div>
    </div>

    <div class="flex flex-1 justify-end md:border-primary">
      <button class="my-2 px-2 text-primary disabled:text-grey" [disabled]="!walk.nextNode" (click)="next()">
        <fa-icon [icon]="['fal','chevron-right']" [fixedWidth]="true" size="2x"></fa-icon>
      </button>
    </div>
  </div>
</div>
