import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { translate } from '@ngneat/transloco';
import { AuthService, Log } from '@s8l/client-tree-lib';

import { Browser } from './browser';
import { PopupService } from './modules/popup/popup.service';
import { SidemenuService } from './modules/sidemenu/sidemenu.service';
import { AppLifecycleService } from './services/app-lifecycle.service';
import { AppRouterService } from './services/app-router.service';
import { ChatService } from './services/chat.service';
import { EnviromentService } from './services/environment.service';
import { ServiceWorkerService } from './services/service-worker.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private defaultPageTitle: string;

  public get isInternetExplorer() {
    return Browser.IsInternetExplorer();
  }

  public get appSidemenu() {
    return this.chat.background && this.auth.isAuth;
  }

  constructor(
    private sw: ServiceWorkerService,
    private pop: PopupService,
    private auth: AuthService,
    private chat: ChatService,
    private title: Title,
    private sidemenu: SidemenuService,
    private appRouter: AppRouterService,
    private lifecycle: AppLifecycleService,
    private env: EnviromentService
  ) {}

  public async ngOnInit() {
    this.defaultPageTitle = this.env.appName;

    this.appRouter.pageTitle.subscribe(title => {
      title ? this.title.setTitle(this.defaultPageTitle + ' - ' + title) : this.title.setTitle(this.defaultPageTitle);
    });

    await this.showBrowserNotification();
    await this.sw.markAppReady();
    await this.hideLoading();
  }

  private showBrowserNotification() {
    // Skip if the user does not want to be reminded anymore...
    const notify = localStorage.getItem('browserNotify');
    if (notify != null && notify === 'false') {
      return;
    }
    if (Browser.IsRecent()) {
      return;
    }
    return this.pop
      .createAlert({
        header: translate('app.refresh'),
        message: translate('app.refreshinfo'),
        handler: () => {
          localStorage.setItem('browserNotify', 'false');
        }
      })
      .then(a => a.present());
  }

  private hideLoading() {
    const loading = document.getElementById('app-loading');
    if (!loading) {
      return Promise.resolve();
    }
    if (!loading.classList.contains('hidden')) {
      loading.classList.add('hidden');
    }
    return Promise.resolve();
  }

  @HostListener('window:beforeunload')
  public async beforeunloadHandler() {
    Log.log('AppComponent', 'beforeunload');
    await this.lifecycle.deinit(false, false);
  }

  @HostListener('document:visibilitychange')
  public async visibilitychangeHandler() {
    if (document.hidden) {
      await this.pauseApp();
    } else {
      await this.resumeApp();
    }
  }

  public async pauseApp() {
    Log.log('AppComponent', 'App paused');
    this.sidemenu.hide();
    return Promise.resolve();
  }

  public async resumeApp() {
    Log.log('AppComponent', 'App resumed');
    return Promise.resolve();
  }
}
