import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ImageCacheService } from 'src/app/services/image-cache.service';

@Directive({
  selector: '[appImageBackground]'
})
export class ImageBackgroundDirective implements OnChanges, AfterViewInit {
  @Input() public image: string;
  @Input() public imageWidth: number | undefined;
  @Input() public imageHeight: number | undefined;

  private prevImage: string;

  constructor(private el: ElementRef<HTMLElement>, private img: ImageCacheService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async ngOnChanges(changes: SimpleChanges) {
    if (this.image) {
      await this.setImage(this.image, this.imageWidth, this.imageHeight);
    } else {
      this.resetImage();
    }
  }

  public ngAfterViewInit() {
    const prevImage = getComputedStyle(this.el.nativeElement).getPropertyValue('background-image');
    if (prevImage == 'none') {
      return;
    }
    this.prevImage = prevImage;
  }

  private async setImage(p: string, width?: number, height?: number) {
    if (!this.el.nativeElement) {
      return;
    }

    const url = await (width && height ? this.img.parseImage(p, width, height) : this.img.parseUrl(p));
    if (url) {
      const images = [];
      if (this.prevImage) {
        images.push(this.prevImage);
      }
      images.push(`url("${url}")`);
      this.el.nativeElement.style.backgroundImage = images.join(',');
    } else {
      this.resetImage();
    }
  }

  private resetImage() {
    this.el.nativeElement.style.backgroundImage = this.prevImage;
  }
}
