import { Injectable } from '@angular/core';
import createHyphenator from 'hyphen';
import hyphenationPatternsDe from 'hyphen/patterns/de-1996';

@Injectable({
  providedIn: 'root'
})
export class HyphenationService {
  private _hyphenate = createHyphenator(hyphenationPatternsDe);

  constructor() {}

  public hyphenate(s: string): string {
    if (!s || s.length == 0) {
      return s;
    }
    return this._hyphenate(s);
  }
}
