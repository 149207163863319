import { Directive, ViewContainerRef, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { PortalService } from './portal.service';

@Directive({
  selector: '[appPortalOutlet]'
})
export class PortalOutletDirective implements OnInit, OnDestroy {
  @Input() public portal: string;

  private portalSubscription: Subscription;

  constructor(private vref: ViewContainerRef, private svc: PortalService) {}

  public ngOnInit() {
    this.portalSubscription = this.svc.templates(this.portal).subscribe(tmpls => {
      this.renderTemplates(tmpls);
    });
  }

  public ngOnDestroy() {
    if (this.portalSubscription) {
      this.portalSubscription.unsubscribe();
    }
  }

  private renderTemplates(tmpls: TemplateRef<unknown>[]) {
    this.vref.clear();
    for (const tmpl of tmpls) {
      this.vref.createEmbeddedView(tmpl);
    }
  }
}
