export class ChartData {
  public name: string | Date;
  public value: number;
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}

export function daysAgo(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
}

export function round(value: number, precision = 2): number {
  if (!value) {
    return 0;
  }
  const m = Math.pow(10, precision);
  return Math.round(value * m) / m;
}

export function fmtMinutes(value: number) {
  return value.toString() + ' min';
}
