import { Component, Input, OnInit } from '@angular/core';

import { AppRouterService } from 'src/app/services/app-router.service';

@Component({
  selector: 'dashboard-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class DashboardProfileComponent implements OnInit {
  @Input() public user = null;

  public userFullname: string;

  constructor(private appRouter: AppRouterService) {}

  public ngOnInit(): void {
    if (this.user.firstname && this.user.lastname) {
      this.userFullname = <string>this.user.firstname + ' ' + <string>this.user.lastname;
    } else {
      this.userFullname = this.user.alias;
    }
  }

  public navigateToProfile() {
    return this.appRouter.navigateRaw(['user', 'profile']);
  }
}
