import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { OverlayPage } from './pages/overlay/overlay.page';
import { AppLifecycleService } from './services/app-lifecycle.service';
import { EnviromentService } from './services/environment.service';

const methodRoutes: Routes = [
  {
    path: 'user',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule)
  },
  {
    path: 'media',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/media/media.module').then(m => m.MediaModule)
  },
  {
    path: 'flashcard/:path',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/flashcard/flashcard.module').then(m => m.FlashcardPageModule)
  },
  {
    path: 'quiz',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/singleplayer/quiz.module').then(m => m.QuizPageModule)
  },
  {
    path: 'mp',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/multiplayer/multiplayer.module').then(m => m.MultiplayerModule)
  },
  {
    path: 'wiki',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/wiki/wiki.module').then(m => m.WikiPageModule)
  },
  {
    path: 'casestudy',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/casestudy/casestudy.module').then(m => m.CasestudyPageModule)
  },
  {
    path: 'chat',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'booking',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  }
];

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenancePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('./pages/imprint/imprint.module').then(m => m.ImprintPageModule)
  },
  {
    path: 'menu',
    canActivate: [(next, state) => inject(AppLifecycleService).canActivate(next, state)],
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'overlay/node/:path',
    component: OverlayPage,
    children: methodRoutes
  },
  {
    path: 'overlay/journey/:journey/:journeyStep',
    component: OverlayPage,
    children: methodRoutes
  },
  ...methodRoutes
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [(next, state) => inject(EnviromentService).canActivate(next, state)],
    children: appRoutes
  },
  {
    path: ':lang',
    canActivate: [(next, state) => inject(EnviromentService).canActivate(next, state)],
    children: appRoutes
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
