import { AfterViewChecked, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ImageCacheService } from 'src/app/services/image-cache.service';

@Directive({
  selector: '[appImageSrc]'
})
export class ImageSrcDirective implements OnChanges, AfterViewChecked {
  @Input() public image: string;
  @Input() public imageWidth: number | undefined;
  @Input() public imageHeight: number | undefined;

  private prevSrc: string;

  constructor(private el: ElementRef<HTMLImageElement>, private img: ImageCacheService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async ngOnChanges(changes: SimpleChanges) {
    if (this.image) {
      await this.setImage(this.image, this.imageWidth, this.imageHeight);
    } else {
      this.resetImage();
    }
  }

  public ngAfterViewChecked() {
    this.prevSrc = this.el.nativeElement.src;
  }

  private async setImage(p: string, width?: number, height?: number) {
    if (!this.el.nativeElement) {
      return;
    }

    const url = await (width && height ? this.img.parseImage(p, width, height) : this.img.parseUrl(p));
    if (url) {
      this.el.nativeElement.src = url;
    } else {
      this.resetImage();
    }
  }

  private resetImage() {
    this.el.nativeElement.src = this.prevSrc;
  }
}
