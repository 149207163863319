import { Component, EventEmitter, Input, OnInit, Output, NgZone, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Path } from '@s8l/client-tree-lib';

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent implements OnInit, OnDestroy {
  @Input() public slideDuration = 5;
  @Input() public slides: any[] = [];

  @Output() public navigateToContent: EventEmitter<Path> = new EventEmitter();

  @ViewChild('scrollContainer') public scrollContainer: ElementRef<HTMLDivElement>;

  public slideIndex = 0;

  private intervalId: number;

  constructor(private zone: NgZone) {}

  public ngOnInit() {
    this.resetInterval();
  }

  public ngOnDestroy() {
    if (this.intervalId != undefined) {
      window.clearInterval(this.intervalId);
    }
  }

  public clickSelector(index: number) {
    this.selectSlide(index);
    this.resetInterval();
  }

  public navigate(link: string, external: boolean) {
    if (!link || link.length == 0) {
      return;
    }

    if (external) {
      (window.location as any) = link;
    } else {
      this.navigateToContent.emit(Path.parse(link));
    }
  }

  private selectSlide(index: number) {
    if (index >= this.slides.length) {
      index = 0;
    }
    if (index < 0) {
      index = this.slides.length - 1;
    }
    const childWidth = this.scrollContainer.nativeElement.firstElementChild?.clientWidth || 0;
    this.scrollContainer.nativeElement.scrollLeft = index * childWidth;
    this.slideIndex = index;
  }

  private moveSlide(delta: number) {
    this.selectSlide(this.slideIndex + delta);
  }

  private resetInterval() {
    if (this.intervalId != undefined) {
      window.clearInterval(this.intervalId);
    }
    this.intervalId = window.setInterval(() => {
      this.zone.run(() => this.moveSlide(+1));
    }, this.slideDuration * 1000);
  }
}
