import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface SessionGame {
  session?: string;
  eventId?: number;
  type?: string;
  page?: string;
  path?: string;
  data?: any;
  timestamp?: SessionInstant;
}

export type SessionInstant = any;

export interface SessionAny {
  session?: string;
  eventId?: number;
  type?: string;
  page?: string;
  path?: string;
  data?: any;
  timestamp?: SessionInstant;
}

export interface SessionStart {
  session?: string;
  eventId?: number;
  type?: string;
  page?: string;
  path?: string;
  data?: any;
  timestamp?: SessionInstant;
}

export interface LabelUserLabel {
  key?: string;
  values?: string[];
}

export interface MetricMetricRequest {
  groupBy?: MetricColumn[];
  relative?: boolean;
  from?: MetricInstant;
  to?: MetricInstant;
}

export type MetricColumn = any;

export type MetricInstant = any;

export interface MetricObject {
  _children?: any;
  _nodeFactory?: MetricJsonNodeFactory;
}

export interface MetricJsonNodeFactory {
  _cfgBigDecimalExact?: boolean;
}

export interface MetricActivity {
  active?: MetricTimeSpan[];
  inactive?: MetricTimeSpan[];
  groupBy?: MetricColumn[];
  relative?: boolean;
  from?: MetricInstant;
  to?: MetricInstant;
}

export interface MetricTimeSpan {
  from?: MetricInstant;
  to?: MetricInstant;
}

export interface MetricGeneral {
  source?: MetricDatasource;
  groupBy?: MetricColumn[];
  relative?: boolean;
  from?: MetricInstant;
  to?: MetricInstant;
}

export type MetricDatasource = any;

export interface MetricLegacyTransition {
  source?: string;
  destination?: string;
  total?: number;
  relativeSource?: number;
  relativeDestination?: number;
}

export interface MetricTransitionVertex {
  feature?: string;
  in?: MetricSortedMap;
  out?: MetricSortedMap;
  totalIn?: number;
  totalOut?: number;
  relIn?: MetricSortedMap;
  relOut?: MetricSortedMap;
}

export type MetricSortedMap = any;

export interface FilterAccessFilterModel {
  uuid?: string;
  name?: string;
  labels?: any;
  meta?: FilterMetadata;
}

export interface FilterMetadata {
  roles?: string[];
  users?: string[];
}

export interface StatsMetricRequest {
  groupBy?: StatsColumn[];
  relative?: boolean;
  from?: StatsInstant;
  to?: StatsInstant;
}

export type StatsColumn = any;

export type StatsInstant = any;

export interface StatsObject {
  _children?: any;
  _nodeFactory?: StatsJsonNodeFactory;
}

export interface StatsJsonNodeFactory {
  _cfgBigDecimalExact?: boolean;
}

export interface AnswersResponse {
  answers?: number;
  correct?: number;
  wrong?: number;
  avg_time?: number;
}

export interface StatsEntry {
  feature?: string;
  result?: string;
  date?: string;
  time?: number;
  count?: number;
  plays?: number;
  path_segments?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class StatsServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'StatsServerService';
  }

  public async statsTimes(params: any): Promise<StatsEntry[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'times', params, true, CacheKey);
  }

  public async statsAnswers(params: any): Promise<AnswersResponse> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'answers', params, true, CacheKey);
  }

  public async statsEventCount(params: any): Promise<StatsEntry[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'count_events', params, true, CacheKey);
  }

  public async statsGamesPlayed(params: any): Promise<StatsEntry[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'games_played', params, true, CacheKey);
  }
  public async sessionGame(params: SessionGame): Promise<void> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/session`), 'game', params, true, CacheKey);
  }

  public async sessionAlive(params: SessionAny): Promise<void> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/session`), 'alive', params, true, CacheKey);
  }

  public async sessionStart(params: SessionStart): Promise<void> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/session`), 'start', params, true, CacheKey);
  }

  public async sessionEvent(params: SessionAny): Promise<void> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/session`), 'event', params, true, CacheKey);
  }

  public async metricMediaPlays(params: MetricMetricRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'media_plays', params, true, CacheKey);
  }

  public async metricTimes(params: MetricMetricRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'times', params, true, CacheKey);
  }

  public async metricActiveUsers(params: MetricActivity): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'active_users', params, true, CacheKey);
  }

  public async metricCount(params: MetricGeneral): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'count', params, true, CacheKey);
  }

  public async metricActiveThreshold(params: MetricActivity): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'active_threshold', params, true, CacheKey);
  }

  public async metricGamesPlayed(params: MetricMetricRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'games_played', params, true, CacheKey);
  }

  public async metricTransitions(params: MetricMetricRequest): Promise<MetricLegacyTransition[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'transitions', params, true, CacheKey);
  }

  public async metricUsers(params: MetricMetricRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'users', params, true, CacheKey);
  }

  public async metricTransition(params: MetricMetricRequest): Promise<MetricTransitionVertex[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/metric`), 'transition', params, true, CacheKey);
  }

  public async statsRecentGames(params: any): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'recent_games', params, true, CacheKey);
  }

  public async statsCountEvents(params: any): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'count_events', params, true, CacheKey);
  }
  public async statsRecentEvents(params: any): Promise<any[]> {
    return this.message(this.env.getServerUrl('STATS_SERVER', `/stats`), 'recent', params, true, CacheKey);
  }
}
