import { Directive, TemplateRef, OnInit, OnDestroy, Input } from '@angular/core';
import { UUID } from '@s8l/client-tree-lib';

import { PortalService } from './portal.service';

@Directive({
  selector: '[appPortalTemplate]'
})
export class PortalTemplateDirective implements OnInit, OnDestroy {
  @Input() public portal: string;

  private uuid: string;

  constructor(private el: TemplateRef<unknown>, private svc: PortalService) {}

  public ngOnInit() {
    if (!this.uuid) {
      this.uuid = UUID.random();
    }

    this.svc.push(this.portal, this.uuid, this.el);
  }
  public ngOnDestroy() {
    if (!this.uuid) {
      return;
    }

    this.svc.pop(this.portal, this.uuid);
  }
}
