<header class="pointer-events-none fixed left-0 right-0 top-0 z-40 bg-transparent">
  <nav class="mx-auto flex w-full items-center justify-between py-2 text-black">
    <div class="header-button-container flex flex-1 justify-start">
      <app-back-button *ngIf="!noElements && back"></app-back-button>
      <ng-container *ngIf="!noElements" appPortalOutlet portal="header-left"></ng-container>
    </div>

    <div class="header-button-container flex flex-1 justify-end">
      <ng-container *ngIf="!noElements" appPortalOutlet portal="header-right"></ng-container>
      <ng-content></ng-content>

      <button
        class="s8l-button s8l-b-header relative"
        *ngIf="!noElements && chat && hasChat"
        [class.!bg-primary]="newMessages"
        [class.!text-white]="newMessages"
        aria-label="chat"
        (click)="goToChat()"
      >
        <fa-icon [icon]="['fal','envelope']" size="lg" [fixedWidth]="true"></fa-icon>
        <div
          class="absolute -right-1 -top-1 inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
          *ngIf="newMessages"
        >
          {{newMessages}}
        </div>
      </button>
      <button class="s8l-button s8l-b-header" *ngIf="!noElements && home" aria-label="home" (click)="goBackToHomePage()">
        <fa-icon [icon]="['fal','home']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
      <button class="s8l-button s8l-b-header" *ngIf="!noElements && menu" (click)="sidemenu.toggle()">
        <fa-icon [icon]="['fal', 'bars']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
  </nav>
</header>
