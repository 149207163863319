import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { EnviromentService } from '../environment.service';

export interface NotificationUpdateRequest {
  filters?: any;
}

export interface NotificationSendRequest {
  uuid?: string;
  topic?: string;
  title?: string;
  message?: string;
  targets?: NotificationEnum[];
  payload?: any;
}

export interface NotificationEnum {}

export interface NotificationRegisterRequest {
  target?: string;
  type?: NotificationEnum;
}

export interface NotificationStatusRequest {}

@Injectable({
  providedIn: 'root'
})
export class NotificationServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'NotificationServerService';
  }
  public async notificationUpdate(params: NotificationUpdateRequest): Promise<any> {
    return this.message(this.env.getServerUrl('NOTIFICATION_SERVER', `/notification`), 'update', params);
  }

  public async notificationSend(params: NotificationSendRequest): Promise<number> {
    return this.message(this.env.getServerUrl('NOTIFICATION_SERVER', `/notification`), 'send', params);
  }

  public async notificationRegister(params: NotificationRegisterRequest): Promise<string> {
    return this.message(this.env.getServerUrl('NOTIFICATION_SERVER', `/notification`), 'register', params);
  }

  public async notificationStatus(params: NotificationStatusRequest): Promise<any> {
    return this.message(this.env.getServerUrl('NOTIFICATION_SERVER', `/notification`), 'status', params);
  }
}
