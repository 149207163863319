import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { ComponentsModule } from 'src/app/components/components.module';

import { DashboardComponentModule } from './../dashboard/dashboard.module';
import { SidemenuComponent } from './sidemenu.component';

@NgModule({
  declarations: [SidemenuComponent],
  imports: [CommonModule, DashboardComponentModule, ComponentsModule, TranslocoModule, RouterModule],
  exports: [SidemenuComponent]
})
export class SidemenuModule {}
