<app-popup></app-popup>
<app-sidemenu *ngIf="appSidemenu"></app-sidemenu>

<div class="h-screen-fix scrollbar-hide overflow-y-auto bg-page" id="main-container">
  <router-outlet id="main" *ngIf="!isInternetExplorer"></router-outlet>
</div>

<ng-container *ngIf="isInternetExplorer">
  <div *transloco="let t;">
    <div class="container mx-auto">
      <h2>{{t('app.notSupported')}}</h2>
      {{t('app.notSupportedMessage')}}
    </div>
  </div>
</ng-container>
