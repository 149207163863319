import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { EnviromentService } from '../environment.service';

export interface BookingEvent {
  id: number;
  name: string;
  description?: string;
  startTime?: Date;
  endTime?: Date;
  location?: string;
  capacity?: number;
  booked?: number;
}

export interface IdContainer {
  id: number;
}

export interface Booking {
  userUuid?: string;
  firstname: string;
  lastname: string;
  email: string;
}

export interface BookingRequest {
  booking: Booking;
  event: number;
}

@Injectable({
  providedIn: 'root'
})
export class BookingServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'BookingServerService';
  }

  public async eventsList(): Promise<BookingEvent[]> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/events'), 'list', {});
  }

  public async eventsCreate(params: BookingEvent): Promise<BookingEvent> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/events'), 'create', params);
  }

  public async eventsDelete(params: IdContainer): Promise<BookingEvent> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/events'), 'delete', params);
  }

  public async bookingCreate(params: BookingRequest): Promise<BookingEvent> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/booking'), 'create', params);
  }

  public async bookingList(params: IdContainer): Promise<Booking> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/booking'), 'list', params);
  }

  public async bookingBooked(): Promise<BookingEvent[]> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/booking'), 'booked', {});
  }

  public async bookingUnbook(params: IdContainer): Promise<BookingEvent> {
    return this.message(this.env.getServerUrl('BOOKING_SERVER', '/booking'), 'unbook', params);
  }
}
