<ng-container *transloco="let t;">
  <!-- t(media.video.none, media.podcast.none, media.document.none) -->
  <div *ngIf="!loading && !hasEntries"><{{t('media.' + fileType + '.none')}}</div>

  <div class="flex flex-wrap p-1 pb-2" *ngIf="!loading && hasCategories">
    <button
      class="s8l-button md:s8l-b-primary m-1 w-fit grow whitespace-nowrap !px-3 !py-1 text-sm text-white md:grow-0"
      *ngFor="let c of categories"
      (click)="category = c"
      [class.!bg-grey]="category !== c"
      [class.!bg-primary]="category === c"
    >
      {{c}}
    </button>
  </div>

  <div class="overflow-y-auto">
    <div class="my-4 flex cursor-pointer p-1" *ngFor="let item of entries[category]" (click)="onMediaClicked(item)">
      <div class="aspect-video w-1/3 min-w-[33%] md:w-1/5 md:min-w-[20%]" *ngIf="hasPreview">
        <img *ngIf="item.preview" [src]="item.preview" />
      </div>
      <div class="pl-4">
        <h4 class="text-lg">{{item.basename}}</h4>
        <div class="text-grey">{{category === 'All' ? (item.path.get()[1]): category}}</div>
      </div>
    </div>
  </div>
</ng-container>
