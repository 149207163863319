import { Component } from '@angular/core';

import { EnviromentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent {
  constructor(public env: EnviromentService) {}
}
