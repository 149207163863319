import { Injectable } from '@angular/core';
import { TreeNode } from '@s8l/client-tree-lib';

import { Method, MenuMethod, Methods } from './../models/methods';
import { AppNavigateOpts, AppRouterService } from './app-router.service';
import { MenuService } from './menu.service';
import { ActiveJourney, JourneySelector, JourneyStep, JourneyType, UserJourneyService } from './user-journey.service';
import { LayoutType } from '../models/menu';

export enum JourneyNavigationType {
  Step = 'step',
  Overview = 'overview'
}

export interface JourneyNavigationContainer {
  type: JourneyNavigationType;
  step: JourneyStep;
}

@Injectable({
  providedIn: 'root'
})
export class UserJourneyWalkerService {
  public activeJourney: ActiveJourney;
  public activeSteps: JourneyStep[];
  public activeStep: JourneyStep;

  public prevNode: JourneyNavigationContainer;
  public nextNode: JourneyNavigationContainer;

  private method: Method;
  private sel: JourneySelector;
  private onOverview = false;

  constructor(private menu: MenuService, private appRouter: AppRouterService, private userJourney: UserJourneyService) {}

  // stepId maybe path _or_ uuid
  public async updateJourney(sel: JourneySelector, onOverview: boolean, stepId: string) {
    this.method = this.appRouter.getMethodFromURL();
    this.onOverview = onOverview;

    if (!this.sel || this.sel.type != sel.type || this.sel.path != sel.path || this.sel.uuid != sel.uuid) {
      this.activeJourney = await this.userJourney.getJourney(sel);
      this.sel = sel;
    }

    const active = this.findJourney(stepId);
    this.activeStep = active.step;
    this.activeSteps = active.children;

    this.prevNode = this.findPrevNode();
    this.nextNode = this.findNextNode();
  }

  public async openMenu(method: MenuMethod) {
    await this.userJourney.setLabelsforNode(this.activeStep.node, {
      visited: true,
      method: method.feature
    });

    await this.appRouter.navigate({ ...method, path: this.activeStep.path }, { layout: LayoutType.Overlay });
  }

  public async navigate(nav: JourneyNavigationContainer) {
    if (!nav || !nav.step) {
      return;
    }

    if (this.activeStep) {
      await this.userJourney.setLabelsforNode(this.activeStep.node, {
        visited: true,
        method: this.method.feature
      });
    }

    const opts: AppNavigateOpts = { replaceUrl: true, layout: LayoutType.Overlay };

    if (nav.type == JourneyNavigationType.Overview) {
      const method = { ...Methods.Journey, path: nav.step.path };
      if (this.activeJourney.type == JourneyType.Journey) {
        // navigate to a journey vs a node
        opts.journey = this.activeJourney.uuid;
        opts.journeyStep = nav.step.parent;
      } else {
        // navigate to a node one up.
        opts.overlayPath = this.activeStep.path.removeLast();
        method.path = method.path.removeLast();
      }
      return this.appRouter.navigate(method, opts);
    }

    // fetch our next step, possibly some levels down
    const step = this.findNextStep(nav.step);
    // find method for next step
    const method = this.findMethod(step.node);
    if (this.activeJourney.type == JourneyType.Journey) {
      // navigate to a journey vs a node
      opts.journey = this.activeJourney.uuid;
      opts.journeyStep = step.uuid;
    }
    return this.appRouter.navigate({ ...method, path: step.path }, opts);
  }

  private findNextStep(step: JourneyStep): JourneyStep {
    if (step.children.length == 0) {
      return step;
    }
    return this.findNextStep(step.children[0]);
  }

  private findJourney(stepId: string): { step: JourneyStep; children: JourneyStep[] } {
    if (this.activeJourney.type == JourneyType.Node && this.onOverview) {
      return { children: this.activeJourney.children, step: undefined };
    }

    if (this.activeJourney.uuid == stepId) {
      return { children: this.activeJourney.children, step: undefined };
    }

    const journey = this.findJourneyInChildren(this.activeJourney.children, stepId);
    if (!this.onOverview) {
      return journey;
    }
    return { children: journey.step.children, step: undefined };
  }

  private findJourneyInChildren(children: JourneyStep[], stepId: string): { step: JourneyStep; children: JourneyStep[] } {
    for (const c of children) {
      const hit = this.findJourneyInChildren(c.children, stepId);
      if (hit) {
        return hit;
      }
      if (c.uuid == stepId || c.path.uri() == stepId) {
        return {
          step: c,
          children
        };
      }
    }
    return undefined;
  }

  private findNextNode(): JourneyNavigationContainer {
    if (this.onOverview) {
      return undefined;
    }

    const currentIndex = this.activeSteps.findIndex(s => s.uuid == this.activeStep.uuid);
    if (currentIndex == -1) {
      return undefined;
    }

    const nextIndex = currentIndex + 1;
    if (nextIndex > this.activeSteps.length) {
      return undefined;
    }
    if (nextIndex == this.activeSteps.length) {
      return {
        type: JourneyNavigationType.Overview,
        step: this.activeStep
      };
    }
    return {
      type: JourneyNavigationType.Step,
      step: this.activeSteps[nextIndex]
    };
  }

  private findPrevNode(): JourneyNavigationContainer {
    if (this.onOverview) {
      return {
        type: JourneyNavigationType.Step,
        step: this.activeSteps[this.activeSteps.length - 1]
      };
    }

    const currentIndex = this.activeSteps.findIndex(s => s.uuid == this.activeStep.uuid);
    if (currentIndex == -1) {
      return undefined;
    }

    const nextIndex = currentIndex - 1;
    if (nextIndex < 0) {
      return undefined;
    }
    return {
      type: JourneyNavigationType.Step,
      step: this.activeSteps[nextIndex]
    };
  }

  private findMethod(node: TreeNode) {
    const methods = this.menu.getMethods(node);
    return methods.find(m => m.page === this.method?.page) || methods[0];
  }
}
