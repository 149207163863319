import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Log, Path, JSONRPCRequest } from '@s8l/client-tree-lib';
import { setDefaultOptions } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

import { environment } from 'src/environments/environment';

import { FooterIcons } from '../models/menu';
import { MethodNames, Methods } from '../models/methods';

export function CacheKey(auth: any, req: JSONRPCRequest) {
  return JSON.stringify({
    uuid: auth.uuid,
    method: req.method,
    params: req.params
  });
}

@Injectable({
  providedIn: 'root'
})
export class EnviromentService {
  public get language() {
    return this.transloco.getActiveLang();
  }

  public get availableLangs() {
    if (environment.languages && environment.languages.length && environment.languages[0].length > 0) {
      return environment.languages;
    }
    return this.transloco.getAvailableLangs() as string[];
  }

  public get production() {
    return environment.production;
  }

  public get imprint() {
    return environment.app?.imprint;
  }

  public get dataprotection() {
    return environment.app?.dataprotection;
  }

  public get appName() {
    return environment.name;
  }

  public get anonymousUser() {
    // TODO: trigger differently
    return false;
  }

  public get anonymousLogin() {
    // TODO: trigger differently
    return false;
  }

  public get loginEndpoint() {
    return this.anonymousLogin ? '/startup' : '/login';
  }

  public get footerIcons() {
    return (
      environment.footer_icons || [
        FooterIcons.Videos,
        FooterIcons.Glossary,
        FooterIcons.Podcasts,
        FooterIcons.Dashboard,
        FooterIcons.Journey
      ]
    );
  }

  public get eventPrefix() {
    return 's8l://' + environment.bundle;
  }

  // tslint:disable-next-line: variable-name
  private _languageUpdate = new EventEmitter<string>();
  public get languageUpdate() {
    return this._languageUpdate.asObservable();
  }

  constructor(private router: Router, private transloco: TranslocoService) {
    let savedLang = localStorage.getItem('language');
    if (!savedLang || !this.availableLangs.includes(savedLang)) {
      const autoDetect = (navigator.language || (navigator as any).userLanguage || 'de').replace(/\-(\w+)$/i, '');
      if (!this.availableLangs.includes(autoDetect)) {
        savedLang = 'de';
      } else {
        savedLang = autoDetect;
      }
    }

    this.transloco.langChanges$.subscribe(lang => {
      Log.log('EnviromentService', 'language update', lang);

      const dateFnsLocale = {
        de: de,
        fr: fr,
        en: enUS
      };
      setDefaultOptions({ locale: dateFnsLocale[lang] });
      Path.defaultLanguage = lang;
      localStorage.setItem('language', lang);

      this._languageUpdate.next(lang);
    });

    this.transloco.setActiveLang(savedLang);
  }

  public hasFeature(name: string): boolean {
    if (!environment.features) {
      return false;
    }
    return !!environment.features.find(f => f.toLowerCase() == name.toLowerCase());
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (environment.maintenance && state.url != '/maintenance') {
      return this.router.createUrlTree(['/maintenance']);
    }

    if (!environment.maintenance && state.url == '/maintenance') {
      return this.router.createUrlTree([this.loginEndpoint]);
    }

    if (!this.anonymousLogin && route.routeConfig.path === '/login/startup') {
      return this.router.createUrlTree(['/login']);
    }

    let activeLang = 'de';

    const routeLang = route.paramMap.get('lang');
    if (routeLang && this.availableLangs.includes(routeLang)) {
      activeLang = routeLang;
    } else {
      const savedLang = localStorage.getItem('language');
      if (savedLang && this.availableLangs.includes(savedLang)) {
        activeLang = savedLang;
      }
    }

    Log.log('EnviromentService', 'route guard language update', activeLang);

    if (activeLang != this.language) {
      this.transloco.setActiveLang(activeLang);
    }

    if (routeLang) {
      const url = state.url.replace('/' + routeLang, '');
      Log.log('EnviromentService', 'route guard redirecting', url);
      return this.router.parseUrl(url);
    }
    return true;
  }

  public switchLanguage() {
    let currentIndex = this.availableLangs.indexOf(this.transloco.getActiveLang());
    if (currentIndex === -1) {
      console.warn("Can't find current lang");
      return;
    }
    if (++currentIndex >= this.availableLangs.length) {
      currentIndex = 0;
    }
    this.transloco.setActiveLang(this.availableLangs[currentIndex]);
  }

  public getMethodName(n: MethodNames | string) {
    let name = Methods[n]?.text || null;

    if (environment.app?.method_names) {
      name = environment.app?.method_names[n];
    }
    if (!name) {
      console.warn(`method ${n} not found!`);
      return n;
    }
    return this.transloco.translate(name);
  }

  public getServerUrl(server: string, path: string): string {
    return `${environment[server + '_URL'] as string}${path}`;
  }

  public getWebsocketUrl(server: string) {
    return environment[server + '_WS'];
  }

  public isValidUsername(username: string) {
    // TODO: fetch from server???
    return /^.+@.+\..+$/.test(username);
  }
}
