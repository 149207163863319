import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'overview-duration-chips',
  templateUrl: './duration-chips.component.html',
  styleUrls: ['./duration-chips.component.scss']
})
export class DurationChips {
  // duration in days
  @Input() public duration = 14;
  @Output() public durationChanged = new EventEmitter<number>();

  public durations = [
    { dur: 7, desc: translate('durations.one-week') },
    { dur: 14, desc: translate('durations.two-weeks') },
    { dur: 31, desc: translate('durations.one-month') },
    { dur: 93, desc: translate('durations.three-months') },
    { dur: 186, desc: translate('durations.six-months') },
    { dur: 366, desc: translate('durations.twelve-months') }
  ];

  constructor() {}

  public changeDuration(d: number) {
    return this.durationChanged.emit(d);
  }
}
