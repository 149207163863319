import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { AuthService, JSONRPCError, Log, Path, WebsocketClient, WebsocketService } from '@s8l/client-tree-lib';
import { interval, Subscription } from 'rxjs';

import { AppRouterService } from './app-router.service';
import { EnviromentService } from './environment.service';
import { ReminderServerService } from './jsonrpc/reminder-server.service';
import { UserServerService } from './jsonrpc/user-server.service';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { Browser } from '../browser';
import { AliasModalComponent } from '../components/alias-modal/alias-modal.component';
import { PopupService } from '../modules/popup/popup.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private ws: WebsocketClient;
  private rootNode = '';

  private intervalSubscription: Subscription = null;
  private interactEvents = [];
  private interactLastTime = Date.now();

  private previousAlias: string;

  public scheduleId = -1;
  public schedules: { string: number } = null;

  public uuid: string;
  public meta: any;
  public settings: any;
  public labels: any;

  constructor(
    private pop: PopupService,
    private env: EnviromentService,
    private auth: AuthService,
    private user: UserServerService,
    private reminder: ReminderServerService,
    private notification: NotificationService,
    private appRouter: AppRouterService,
    private websocket: WebsocketService
  ) {
    this.appRouter.navigationEnds.subscribe(event => {
      if (!event.component) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/dot-notation
      let name = event.component['name'];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const analyticsName = event.component['analyticsName'];
      if (analyticsName && analyticsName instanceof Function) {
        name = analyticsName();
      }
      void this.pageInteract('open', name, event.snapshot.params.path);
    });
  }

  public init(hasReminder: boolean, rootNode: string) {
    // Remove trailing slash if any
    if (rootNode.endsWith('/')) {
      rootNode.slice(0, -1);
    }

    Log.log('ProfileService', 'INIT', rootNode);
    this.rootNode = rootNode;

    const promises: any[] = [
      this.websocket
        .connect(this.env.getWebsocketUrl('USER_SERVER'), 'ProfileWebsocket')
        .then(client => {
          this.ws = client;
        })
        .catch(e => {
          Log.warn('ProfileSerice', 'websocket connection failed', e);
          this.ws = undefined;
        })
    ];
    if (hasReminder) {
      promises.push(this.registerReminder());
    }
    if (!this.meta || !this.settings) {
      promises.push(this.fetchProfile());
    }

    return Promise.all(promises);
  }

  public save(): Promise<any> {
    return this.user.settingsSet(this.settings).then(data => (this.settings = data.settings));
  }

  public startHeartbeat() {
    this.intervalSubscription = interval(environment.user_ping_interval).subscribe(() => void this.sendEvents());
    return this.sendEvents();
  }

  public async stopHeartbeat(): Promise<void> {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.intervalSubscription = null;

    await this.sendEvents(true);

    if (this.ws) {
      this.ws.disconnect();
      this.ws = undefined;
    }
  }

  public async login(username: string, password: string): Promise<void> {
    const deviceId = await this.notification.deviceId();
    const platform = Browser.GetPlatformType();
    const data = await this.user.accountLogin({
      username,
      password,
      app: 'quiz',
      device: { id: deviceId, platform }
    });

    this.auth.auth(data.token);

    return this.fetchProfile();
  }

  public async logout(deleteToken = true): Promise<any> {
    await this.stopHeartbeat();

    if (this.auth.isAuth && deleteToken) {
      await this.user
        .accountLogout()
        .then(() => this.auth.unauth())
        .catch(err => console.warn(err));
    }
  }

  private fetchProfile() {
    return this.user.profileGet().then(data => {
      this.uuid = this.auth.payload.uuid;
      this.settings = data.settings;
      this.meta = data.meta;
      this.labels = data.labels;
    });
  }

  public pageInteract(type: string, page: string, path?: string) {
    let p = path ? Path.parse(path) : new Path([this.rootNode]);
    if (p.getFirst() != this.rootNode) {
      p = p.addFirst(this.rootNode);
    }
    this.interactEvents.push({
      timestamp: Date.now(),
      path: this.env.eventPrefix + '/' + p.uri(),
      type,
      page
    });
    return this.sendEvents();
  }

  private async sendEvents(force = false) {
    if (!this.auth.isAuth) {
      return;
    }
    if ((Date.now() - this.interactLastTime) / 1000 <= 1 || force) {
      return;
    }

    if (this.ws) {
      await this.ws.send('page', { events: this.interactEvents });
    } else {
      await this.user.eventPage({ events: this.interactEvents });
    }

    this.interactEvents = [];
    this.interactLastTime = Date.now();
  }

  public password(current, password): Promise<any> {
    const params = {
      currentPassword: current,
      newPassword: password
    };

    return this.user.accountPassword(params);
  }

  public updateReminder(schedule): Promise<any> {
    return this.reminder.update({ schedule }).then(res => (this.scheduleId = res.schedule));
  }

  private registerReminder() {
    return this.reminder
      .status({})
      .then(data => {
        this.schedules = data.schedules;
        this.scheduleId = data.schedule;

        if (this.scheduleId !== -1) {
          return;
        }

        return this.reminder.register({ schedule: 1 }).then(() => (this.scheduleId = 1));
      })
      .catch(err => {
        Log.error('REMINDER', 'Failed to query reminder status', err);
      });
  }

  public changeAlias(extraMessage: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const modal = this.pop.createModal({
        component: AliasModalComponent,
        componentProps: {
          extraMessage
        },
        handler: data => {
          if (!data?.alias) {
            return reject(new Error('alias_canceled'));
          }
          this.previousAlias = this.settings.alias;
          this.settings.alias = data.alias;
          resolve(this.settings.alias);
        }
      });

      void modal.then(p => p.present());
    });
  }

  public resetAlias() {
    this.settings.alias = this.previousAlias;
  }

  public translateError(err: JSONRPCError) {
    const key = 'password.' + err.message.replace('password_', '');
    const messages = {
      require_lower_case: translate('register.invalid.password.require_lower_case'),
      require_number: translate('register.invalid.password.require_number'),
      require_special: translate('register.invalid.password.require_special'),
      require_upper_case: translate('register.invalid.password.require_upper_case'),
      too_long: translate('register.invalid.password.too_long'),
      too_short: translate('register.invalid.password.too_short')
    };

    let msg = messages[key];
    if (msg === '' || msg === key) {
      // no fitting translation found
      msg = err.data?.info ?? err.message;
    }
    return msg;
  }
}
