import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Log } from '@s8l/client-tree-lib';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Log.active = environment.logging;

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.warn(err));
