import { HttpClient } from '@angular/common/http';

import { applyConfig, Environment } from 'src/environments/common';

const initials: any = {
  production: false,
  logging: true
};

export let environment: Environment = initials;
export function fetchConfig(http: HttpClient) {
  return () => {
    return applyConfig(http, initials).then(e => {
      environment = e;
    });
  };
}
