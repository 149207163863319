import { Injectable, NgZone } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { Workbox } from 'workbox-window';

import { PopupService } from '../modules/popup/popup.service';

export function registerServiceWorker(sw: ServiceWorkerService) {
  return () => sw.register();
}

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  private wb: Workbox;

  private pendingUpdate = false;
  private appReady = false;

  constructor(private pop: PopupService, private trans: TranslocoService, private zone: NgZone) {}

  public async register() {
    if ('serviceWorker' in navigator) {
      this.wb = new Workbox('/service-worker.js');
      this.wb.addEventListener('waiting', () => {
        void this.zone.run(() => this.markUpdate());
      });
      return this.wb.register();
    }
  }

  public async markAppReady() {
    this.appReady = true;
    return this.createUpdateAlert();
  }

  private markUpdate() {
    this.pendingUpdate = true;
    return this.createUpdateAlert();
  }

  private async createUpdateAlert() {
    if (!this.pendingUpdate || !this.appReady) {
      return;
    }

    this.wb.addEventListener('controlling', () => {
      window.location.reload();
    });

    return this.pop
      .createAlert({
        header: await firstValueFrom(this.trans.selectTranslate('update.available')),
        message: await firstValueFrom(this.trans.selectTranslate('update.availableinfo')),
        handler: () => this.wb.messageSkipWaiting()
      })
      .then(a => a.present());
  }
}
