export enum MenuTypes {
  Tree = 'tree',
  Slider = 'slider',
  List = 'list'
}

export enum LayoutType {
  App = 'app',
  Overlay = 'overlay'
}

export enum FooterIcons {
  Videos = 'videos',
  Podcasts = 'podcasts',
  Dashboard = 'dashboard',
  Glossary = 'glossary',
  Journey = 'journey'
}
