<ng-container *transloco="let t;">
  <div
    class="flex w-full shrink-0 cursor-default snap-center flex-col justify-between rounded-[1.5rem] bg-white px-3 py-4 shadow-uni-md hover:shadow-xl"
    [class.!bg-grey]="countdown?.over"
    *ngIf="achievement.achieved <= 0; else achieved"
  >
    <div class="pb-4">
      <h2 class="mb-3 hyphens-auto">{{achievement.title}}</h2>
      <div class="text-ellipsis hyphens-auto whitespace-normal">{{achievement.description}}</div>
    </div>
    <div class="mt-5" *ngIf="countdown.hasCountdown">
      <h4 *ngIf="!countdown.over">{{countdown.active ? t('achievement.endsIn') : t('achievement.startsIn') }}</h4>
      <h4 *ngIf="countdown.over">{{t('achievement.over') }}</h4>
      <span class="text-xl" *ngIf="!countdown.over">{{countdown.text}}</span>
    </div>
    <div
      class="my-3 h-[32px] w-full shrink-0 overflow-hidden rounded-full bg-light-grey"
      *ngIf="!countdown.hasCountdown || countdown.active"
    >
      <div
        class="h-[32px] self-end overflow-hidden rounded-full bg-gradient-to-r from-primary to-secondary p-1 text-center text-primary-contrast"
        [style.width]="parseToPercentage(achievement)"
      >
        <span class="text-white"> {{parseToPercentage(achievement)}} </span>
      </div>
    </div>
  </div>

  <ng-template #achieved
    ><div
      class="flex w-full shrink-0 cursor-default snap-center flex-col justify-between rounded-[1.5rem] bg-gradient-to-t from-primary to-secondary px-3 py-4 text-white shadow-uni-md hover:shadow-xl"
      (click)="sparkles($event)"
    >
      <div class="pb-4">
        <h2>{{achievement.title}}</h2>
        <span class="grow text-ellipsis">{{achievement.description}}</span>
      </div>
      <div class="py-3" [style.visibility]="checked ? 'hidden' : 'initial'">
        <button class="s8l-button mx-auto w-full bg-white text-primary">
          <fa-icon [icon]="['fal', 'sparkles']" style="margin-right: 6px"></fa-icon>
          {{t('achievement.finishAchievement')}}
        </button>
      </div>
    </div></ng-template
  >
</ng-container>
