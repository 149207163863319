import { Directive, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appSetHTML]'
})
export class SetHtmlDirective {
  @Input() public set appSetHTML(html) {
    const element = this.element.nativeElement;

    if (element.setHTML) {
      element.setHTML(html);
    } else {
      element.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, html);
    }
  }

  constructor(private element: ElementRef, private sanitizer: DomSanitizer) {}
}
