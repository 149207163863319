import { AppFeatures, Path, TreeResource } from '@s8l/client-tree-lib';
import { marker } from '@s8l/transloco-keys-manager/marker';

export enum MethodNames {
  QuizSp = 'QuizSp',
  QuizMp = 'QuizMp',
  Exam = 'Exam',
  Review = 'Review',
  Flashcard = 'Flashcard',
  WikiArticle = 'WikiArticle',
  Video = 'Video',
  Podcast = 'Podcast',
  Document = 'Document',
  Legal = 'Legal',
  Glossary = 'Glossary',
  Impuls = 'Impuls',
  Casestudy = 'Casestudy',
  CasestudyIndex = 'CasestudyIndex',
  Achievements = 'Achievements',
  Journey = 'Journey',
  Ranking = 'Ranking'
}

export interface Method {
  name: MethodNames;
  feature: AppFeatures;
  resource: TreeResource;
  page: string;
  text: string;
  icon: string;
}

export const Methods: { [index in MethodNames]: Method } = {
  QuizSp: {
    name: MethodNames.QuizSp,
    feature: AppFeatures.QuizSp,
    resource: TreeResource.Quiz,
    page: 'quiz',
    text: marker('method.quizSp'),
    icon: 'question-circle'
  },
  QuizMp: {
    name: MethodNames.QuizMp,
    feature: AppFeatures.QuizMp,
    resource: TreeResource.Quiz,
    page: 'mp',
    text: marker('method.quizMp'),
    icon: 'user-friends'
  },
  Exam: {
    name: MethodNames.Exam,
    feature: AppFeatures.QuizExam,
    resource: TreeResource.Quiz,
    page: 'exam',
    text: marker('method.exam'),
    icon: 'graduation-cap'
  },
  Review: {
    name: MethodNames.Review,
    feature: AppFeatures.QuizReview,
    resource: TreeResource.Quiz,
    page: 'review',
    text: marker('method.review'),
    icon: 'trophy'
  },

  Flashcard: {
    name: MethodNames.Flashcard,
    feature: AppFeatures.Flashcard,
    resource: TreeResource.Flashcard,
    page: 'flashcard',
    text: marker('method.flashcards'),
    icon: 'clone'
  },
  WikiArticle: {
    name: MethodNames.WikiArticle,
    feature: AppFeatures.Wiki,
    resource: TreeResource.Wiki,
    page: 'article',
    text: marker('method.article') || marker('method.fachinfo'),
    icon: 'book-alt'
  },

  Video: {
    name: MethodNames.Video,
    feature: AppFeatures.Video,
    resource: TreeResource.Video,
    page: 'video',
    text: marker('method.video'),
    icon: 'video'
  },
  Podcast: {
    name: MethodNames.Podcast,
    feature: AppFeatures.Podcast,
    resource: TreeResource.Media,
    page: 'podcast',
    text: marker('method.podcast'),
    icon: 'volume'
  },
  Document: {
    name: MethodNames.Document,
    feature: AppFeatures.PDFDownload,
    resource: TreeResource.Media,
    page: 'document',
    text: marker('method.document'),
    icon: 'file'
  },

  Legal: {
    name: MethodNames.Legal,
    feature: AppFeatures.Legal,
    resource: TreeResource.Legal,
    page: 'legal',
    text: marker('method.legal'),
    icon: 'balance-scale-right'
  },
  Glossary: {
    name: MethodNames.Glossary,
    feature: AppFeatures.Glossary,
    resource: TreeResource.Glossary,
    page: 'glossary',
    text: marker('method.glossary'),
    icon: 'spell-check'
  },
  Impuls: {
    name: MethodNames.Impuls,
    feature: AppFeatures.Impuls,
    resource: TreeResource.Impuls,
    page: 'impuls',
    text: marker('method.impuls'),
    icon: 'list'
  },
  Casestudy: {
    name: MethodNames.Casestudy,
    feature: AppFeatures.Casestudy,
    resource: TreeResource.Casestudy,
    page: 'casestudy',
    text: marker('method.casestudy'),
    icon: 'file-edit'
  },
  CasestudyIndex: {
    name: MethodNames.CasestudyIndex,
    feature: AppFeatures.Casestudy,
    resource: TreeResource.Casestudy,
    page: 'casestudy-index',
    text: marker('method.casestudy'),
    icon: 'file-edit'
  },
  Achievements: {
    name: MethodNames.Achievements,
    feature: AppFeatures.Achievement,
    resource: null,
    page: 'achievements',
    text: marker('method.achievements'),
    icon: 'sticky-note'
  },
  Journey: {
    name: MethodNames.Journey,
    feature: AppFeatures.Journey,
    resource: null,
    page: 'journey',
    text: marker('method.journey'),
    icon: 'history'
  },
  Ranking: {
    name: MethodNames.Ranking,
    feature: AppFeatures.QuizRanking,
    resource: null,
    page: 'ranking',
    text: marker('method.ranking'),
    icon: 'medal'
  }
};

export const AutoOpenMethods = [
  AppFeatures.QuizSp,
  AppFeatures.QuizMp,
  AppFeatures.QuizExam,
  AppFeatures.QuizReview,
  AppFeatures.Flashcard,
  AppFeatures.Wiki,
  AppFeatures.Wiki,
  AppFeatures.Video,
  AppFeatures.Podcast,
  AppFeatures.PDFDownload,
  AppFeatures.Legal,
  AppFeatures.Glossary,
  AppFeatures.Impuls,
  AppFeatures.Casestudy
];

export interface MenuMethod extends Method {
  path?: Path;
}
