import { Path } from '@s8l/client-tree-lib';

export enum MediaProvider {
  MEDIA = 'media',
  VIMEO = 'vimeo',
  BUNNY = 'bunny' //soon (tm)
}

// single item in a playlist
export interface MediaItem {
  name: string;
  provider: MediaProvider;
  id: string; // identifier passed to the provider
  path: Path;
}

// common inteface for all players,
// should be emited from all methods of passing data to them
export class MediaPlaylist {
  public entries: MediaItem[];
  public currentID: string; // id of the current item

  public get currentItem(): MediaItem {
    let e = this.entries[0];
    if (this.currentID) {
      e = this.entries.find(e => e.id == this.currentID);
    }
    return e;
  }

  constructor(entries: MediaItem[], currentID: string) {
    this.entries = entries;
    if (currentID) {
      this.currentID = currentID;
    } else if (entries.length) {
      this.currentID = entries[0].id;
    }
  }
}
