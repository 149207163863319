import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService, Path } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface SettingsNodeSettings {
  path?: string;
  meta?: any;
  labels?: any;
  uuid?: string;
}

export interface RecursivePathRequest {
  path?: string;
  parent?: boolean;
  recursive?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserNodeContentServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'UserNodeContentServerService';
  }

  public async nodeGetLabel(path: Path, label: string): Promise<any> {
    return this.message(
      this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`),
      'get-label',
      { path: path.uri(), label },
      true,
      CacheKey
    );
  }

  public async nodeGetLabels(path: Path): Promise<any> {
    return this.message(this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`), 'get-labels', { path: path.uri() }, true, CacheKey);
  }

  public async nodeGetMeta(path: Path): Promise<any> {
    return this.message(this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`), 'get-meta', { path: path.uri() }, true, CacheKey);
  }

  public async nodeSetLabels(path: Path, labels: any): Promise<void> {
    return this.message(this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`), 'set-labels', { path: path.uri(), labels });
  }

  public async nodeSetMeta(path: Path, meta: any): Promise<void> {
    return this.message(this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`), 'set-meta', { path: path.uri(), meta });
  }

  public async nodeGetByLabel(label: string): Promise<SettingsNodeSettings[]> {
    return this.message(
      this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`),
      'get-by-label',
      {
        language: this.env.language,
        label
      },
      true
    );
  }

  public async nodeGetRecursive(params: RecursivePathRequest): Promise<SettingsNodeSettings[]> {
    return this.message(this.env.getServerUrl('USER_NODE_CONTENT_SERVER', `/node`), 'get-children', { ...params }, true);
  }
}
