import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Path, TreeNode } from '@s8l/client-tree-lib';

import { MenuMethod, MethodNames } from 'src/app/models/methods';
import { MenuService } from 'src/app/services/menu.service';
import { UserJourneyService } from 'src/app/services/user-journey.service';

const METHOD_WHITELIST = [MethodNames.QuizSp, MethodNames.WikiArticle];

interface LatestNode {
  node: TreeNode;
  methods: MenuMethod[];
}

@Component({
  selector: 'dashboard-latest',
  templateUrl: './latest.component.html',
  styleUrls: ['./latest.component.scss']
})
export class LatestComponent implements OnInit {
  @Input() public user = null;

  @Output() public navigate = new EventEmitter<MenuMethod>();

  public latest: LatestNode[] = [];

  constructor(private menu: MenuService, private userJourney: UserJourneyService) {}

  public async ngOnInit() {
    const nodes = await this.userJourney.getLastNodes();

    this.latest = nodes.map(node => {
      return {
        node,
        methods: this.menu.getMethods(node).filter(m => METHOD_WHITELIST.includes(m.name))
      };
    });
  }

  public navigateToMethod(n: TreeNode, method: MenuMethod) {
    return this.navigate.emit({ ...method, path: Path.parse(n.path).removeFirst() });
  }
}
