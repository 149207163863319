<div class="py-4" *ngIf="chartOptions">
  <overview-duration-chips [duration]="duration" (durationChanged)="durationChanges($event)"></overview-duration-chips>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [legend]="chartOptions.legend"
    [fill]="chartOptions.fill"
    [stroke]="chartOptions.stroke"
    [tooltip]="chartOptions.tooltip"
    [xaxis]="chartOptions.xaxis"
  ></apx-chart>
</div>
