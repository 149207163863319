<div class="mb-16 pt-16" #container *transloco="let t;">
  <div class="container mx-auto mb-4 flex pb-2">
    <div class="mr-6 flex w-1/5 shrink-0 flex-col justify-between">
      <app-date-range [startDate]="event.startTime" [endDate]="event.endTime"></app-date-range>
      <button class="s8l-button s8l-b-clear h-min shadow" *ngIf="isBooked && !canceling" (click)="cancelingEvent()">
        {{t('booking.cancel')}} <fa-icon [icon]="['fal', 'sign-out-alt']" [fixedWidth]="true"></fa-icon>
      </button>
      <button class="s8l-button s8l-b-primary" *ngIf="!isBooked && !booking" (click)="startBook(container)">
        {{isFull ? t('booking.fully-booked') : t('booking.book-event')}}
      </button>
    </div>
    <div class="grow">
      <h3>{{event.name}}</h3>
      <div>{{event.description}}</div>
      <div class="font-bold">{{event.location}}</div>
    </div>
  </div>

  <div class="mb-12 mt-4 bg-light-grey py-4 shadow-inner-xl" *ngIf="booking">
    <form class="container mx-auto" (ngSubmit)="confirmBook()" #bookForm="ngForm" autocapitalize="on" autocorrect="off" autocomplete="on">
      <button class="s8l-button s8l-b-clear ml-auto" type="button" (click)="cancelBooking()">
        {{ t('app.cancel') }}<fa-icon [icon]="['fal','times-circle']"></fa-icon>
      </button>
      <h3>{{t('booking.book-event')}}</h3>
      <input style="width: 0; visibility: hidden" type="submit" />
      <div class="p-4 px-2 sm:px-4">
        <div class="s8l-input-container">
          <div class="font-bold">{{t('profile.email')}}</div>
          <input
            class="s8l-input"
            id="mail-input"
            [(ngModel)]="user.email"
            [placeholder]="t('profile.email')"
            name="mail"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="user.mail"
            required
          />
        </div>
      </div>
      <div class="flex p-4 px-2 sm:px-4">
        <div class="s8l-input-container !mr-4 !w-1/2">
          <div class="font-bold">{{t('profile.firstname')}}</div>
          <input
            class="s8l-input"
            id="firstname-input"
            [(ngModel)]="user.firstname"
            [placeholder]="t('profile.firstname')"
            name="firstname"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="user.firstname"
            required
            type="text"
          />
        </div>
        <div class="s8l-input-container !w-1/2">
          <div class="font-bold">{{t('profile.lastname')}}</div>
          <input
            class="s8l-input"
            id="lastname-input"
            [(ngModel)]="user.lastname"
            [placeholder]="t('profile.lastname')"
            name="lastname"
            type="text"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="user.lastname"
            required
          />
        </div>
      </div>
      <div class="mb-4 flex w-full justify-end p-4 px-2 sm:px-4">
        <button class="s8l-button s8l-b-primary" type="submit" [disabled]="!bookForm.form.valid">
          {{ t('booking.book-event') }}<fa-icon [icon]="['fal','arrow-right']"></fa-icon>
        </button>
      </div>
    </form>
  </div>
  <div class="container mx-auto mb-12 mt-4 py-4" *ngIf="canceling">
    <h3>{{t('booking.confirm-unbook-message')}}</h3>
    <div class="flex justify-end">
      <button class="s8l-button s8l-b-clear" (click)="cancelCancelingEvent()">{{t('app.cancel')}}</button>
      <button class="s8l-button s8l-b-primary" (click)="unbookEvent()">{{t('booking.cancel')}}</button>
    </div>
  </div>
</div>
