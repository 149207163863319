import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoModule } from '@ngneat/transloco';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AchievementsComponent } from './achievements/achievements.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BookmarkComponent } from './bookmarks/bookmark.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { DurationChips } from './duration-chips/duration-chips.component';
import { EventsComponent } from './events/events.component';
import { LatestComponent } from './latest/latest.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { DashboardProfileComponent } from './profile/profile.component';
import { ProfileIconComponent } from './profile-icon/profile-icon.component';
import { QuizOverviewComponent } from './quiz-overview/quiz-overview.component';
import { ComponentsModule } from '../../components/components.module';
import { BookingModule } from '../booking/booking.module';

const modules = [
  LatestComponent,
  LineChartComponent,
  DurationChips,
  BarChartComponent,
  DashboardProfileComponent,
  LeaderboardComponent,
  BookmarkComponent,
  QuizOverviewComponent,
  ChatMessagesComponent,
  ProfileIconComponent,
  EventsComponent,
  AchievementsComponent
];

@NgModule({
  declarations: [...modules],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    ComponentsModule,
    BookingModule,
    NgApexchartsModule
  ],
  exports: [...modules]
})
export class DashboardComponentModule {}
