<div class="flex flex-wrap justify-end" *transloco="let t;">
  <div
    class="m-0.5 cursor-pointer rounded-full px-3 py-0.5 text-sm hover:shadow-lg"
    [class.bg-primary]="duration === i.dur"
    [class.text-primary-contrast]="duration === i.dur"
    *ngFor="let i of durations"
    (click)="changeDuration(i.dur)"
  >
    {{i.desc}}
  </div>
</div>
