import { Component, Input } from '@angular/core';

import { PopupService } from 'src/app/modules/popup/popup.service';

@Component({
  selector: 'app-alias-modal',
  templateUrl: './alias-modal.component.html',
  styleUrls: ['./alias-modal.component.scss']
})
export class AliasModalComponent {
  @Input() public extraMessage: string;

  public alias: string;

  constructor(public pop: PopupService) {}

  public onSubmit() {
    return this.pop.dismissModal({
      alias: this.alias
    });
  }

  public dismiss() {
    return this.pop.dismissModal();
  }
}
