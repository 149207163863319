import { Component, Input } from '@angular/core';

import { PopupService } from '../../popup/popup.service';

@Component({
  selector: 'app-quiz-invitee-modal',
  templateUrl: './quiz-invitee-modal.component.html',
  styleUrls: ['./quiz-invitee-modal.component.scss']
})
export class QuizInviteeModalComponent {
  @Input() public alias: string;

  constructor(public pop: PopupService) {}

  public decline() {
    return this.pop.dismissModal(false);
  }

  public accept() {
    return this.pop.dismissModal(true);
  }
}
