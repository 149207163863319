import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCService } from '@s8l/client-tree-lib';

import { CacheKey, EnviromentService } from '../environment.service';

export interface WikiSearchRequest {
  searchQuery?: string;
  searchAllLanguages?: boolean;
  fields?: string[];
  path?: string;
  language?: string;
}

export interface WikiSearchEntry {
  score?: number;
  result?: any;
}

export interface WikiPathRequest {
  path?: string;
  language?: string;
}

export interface WikiListRequest {
  recursive?: boolean;
  path?: string;
  language?: string;
}

export interface WikiListResponse {
  path?: string;
  hasContent?: boolean;
  title?: string;
}

export interface WikiPutRequest {
  fields?: WikiField[];
  referencePath?: string;
  referenceLanguage?: string;
  path?: string;
  language?: string;
}

export interface WikiField {
  name?: string;
  type?: string;
  content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WikiServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'WikiServerService';
  }

  public async wikiSearch(mode: string, params: WikiSearchRequest): Promise<WikiSearchEntry[]> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'search', params, true, CacheKey);
  }

  public async wikiGetRaw(mode: string, params: WikiPathRequest): Promise<any> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'get-raw', params, true, CacheKey);
  }

  public async wikiGet(mode: string, params: WikiPathRequest): Promise<any> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'get', params, true, CacheKey);
  }

  public async wikiGetChildren(mode: string, params: WikiListRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'get-children', params, true, CacheKey);
  }

  public async wikiGetChildrenRaw(mode: string, params: WikiListRequest): Promise<any[]> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'get-children-raw', params, true, CacheKey);
  }

  public async wikiList(mode: string, params: WikiListRequest): Promise<WikiListResponse[]> {
    return this.message(this.env.getServerUrl(this.getServerName(mode), `/wiki`), 'list', params, true, CacheKey);
  }

  private getServerName(mode: string) {
    switch (mode) {
      case 'wiki':
        return 'WIKI_SERVER';
      case 'glossary':
        return 'GLOSSARY_SERVER';
      case 'impuls':
        return 'IMPULS_SERVER';
      case 'casestudy':
        return 'CASESTUDY_SERVER';
      case 'legal':
        return 'LEGAL_SERVER';
      case 'video':
        return 'VIDEO_SERVER';
      case 'description':
        return 'DESCRIPTION_SERVER';
      case 'flashcard':
        return 'FLASHCARD_SERVER';
      default:
        return 'WIKI_SERVER';
    }
  }
}
