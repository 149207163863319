import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { AppFeatures, Log, Path } from '@s8l/client-tree-lib';

import { PopupService } from 'src/app/modules/popup/popup.service';
import { MediaItem, MediaPlaylist, MediaProvider } from 'src/app/pages/media/media.model';
import { EnviromentService } from 'src/app/services/environment.service';

import { MediaServerService } from '../../services/jsonrpc/media-server.service';
import { WikiServerService } from '../../services/jsonrpc/wiki-server.service';
import { TreeService } from '../../services/tree.service';

export enum MediaType {
  VIDEO = 'video',
  PODCAST = 'podcast',
  DOCUMENT = 'document'
}

export class MediaListEntry {
  public get name(): string {
    return this.path.getLast();
  }

  public get basename(): string {
    return this.name.replace(/\.[^/.\s]+$/, '');
  }

  public get cleanname(): string {
    const match = this.name.match(/^(?:\d+__)(.+)$/);
    return (match && match.length > 1 ? match[1] : this.name).replace(/\.[^/.]+$/, '');
  }

  constructor(public provider: MediaProvider, public id: string, public category: string, public path: Path, public preview: string) {}

  public static sort(dirs: MediaListEntry[]): MediaListEntry[] {
    return dirs.sort((a, b) => {
      const lhs = a.basename;
      const rhs = b.basename;

      const numeric = parseInt(lhs, 10) - parseInt(rhs, 10);
      if (isNaN(numeric)) {
        return lhs.localeCompare(rhs);
      }
      return numeric;
    });
  }
}

export type MediaList = { [category: string]: MediaListEntry[] };

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit {
  @Output() public onMedia = new EventEmitter<MediaPlaylist>();

  @Input() public fileType: MediaType;

  public loading = false;
  public hasCategories = false;
  public hasPreview = false;

  public category = 'All';
  public categories: string[] = ['All'];
  public entries: MediaList = {};

  public hasEntries() {
    if (Object.keys(this.entries).length == 0) {
      return false;
    }

    return !Object.values(this.entries).some(e => e.length != 0);
  }

  constructor(
    private route: ActivatedRoute,
    private tree: TreeService,
    private media: MediaServerService,
    private wiki: WikiServerService,
    private pop: PopupService,
    private env: EnviromentService
  ) {}

  public ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      let path;
      if (params.path) {
        path = Path.parse(params.path).addFirst(this.tree.root.name);
      } else {
        path = new Path().addFirst(this.tree.root.name);
        this.hasCategories = true;
      }
      void this.fetch(path).finally(() => (this.loading = false));
    });
  }

  private async fetchVimeo(rootPath: Path): Promise<MediaListEntry[]> {
    const root = this.wiki
      .wikiGet(this.fileType, { path: rootPath.uri() })
      .then(a => [
        {
          ...a,
          path: rootPath.uri()
        }
      ])
      .catch(e => {
        Log.warn(e);
        return [];
      });

    const children = await this.wiki
      .wikiGetChildren(this.fileType, {
        path: rootPath.uri(),
        recursive: true
      })
      .catch(e => {
        Log.warn(e);
        return [];
      });

    return Promise.all([root, children])
      .then(res => res.flat())
      .then(articles => {
        return articles
          .map(a => {
            const video = a.fields?.content.value || [];
            return video.map(v => {
              return { ...v, path: a.path };
            });
          })
          .flatMap(videos => {
            return videos.map(v => {
              const path = Path.parse(v.path).addLast(v.name);
              const category = path.length > rootPath.length + 1 ? path.get()[rootPath.length] : undefined;
              return new MediaListEntry(MediaProvider.VIMEO, v.url, category, path, v.image);
            });
          });
      });
  }

  private async fetchMediaServer(rootPath: Path): Promise<MediaListEntry[]> {
    return this.media
      .mediaList({
        path: rootPath.uri(),
        types: [this.fileType],
        recursive: true
      })
      .then(files => {
        return files
          .filter(f => f.type != 'folder')
          .flatMap(f => {
            const path = Path.parse(f.path);
            const category = path.length > rootPath.length + 1 ? path.get()[rootPath.length] : undefined;
            return new MediaListEntry(MediaProvider.MEDIA, f.uuid, category, path, null);
          });
      });
  }

  private async fetch(rootPath: Path) {
    const messages = {
      [MediaType.VIDEO]: translate('media.video.loading'),
      [MediaType.PODCAST]: translate('media.podcast.loading'),
      [MediaType.DOCUMENT]: translate('media.document.loading')
    };
    const loading = await this.pop.createLoading({ message: messages[this.fileType] });
    await loading.present();

    const promises: Promise<MediaListEntry[]>[] = [];

    promises.push(this.fetchMediaServer(rootPath));

    if (this.env.hasFeature(AppFeatures.Vimeo) && this.fileType === MediaType.VIDEO) {
      promises.push(this.fetchVimeo(rootPath));
    }

    return Promise.all(promises)
      .then(res => res.flat())
      .then(res => MediaListEntry.sort(res))
      .then(res => {
        const entries = {};

        for (const e of res) {
          for (const c of ['All', e.category]) {
            if (c) {
              entries[c] = {
                ...entries[c],
                [e.id]: e
              };
            }
          }
        }

        this.categories = Object.keys(entries);
        this.entries = this.categories.reduce((prev, next) => {
          return {
            ...prev,
            [next]: Object.values(entries[next])
          };
        }, {});

        this.hasPreview = res.some(e => !!e.preview);
      })
      .finally(() => {
        void loading.dismiss();
      });
  }

  public onMediaClicked(file: MediaListEntry) {
    const entries: MediaItem[] = this.entries[this.category].map(e => {
      return {
        name: e.cleanname,
        provider: e.provider,
        id: e.id,
        path: e.path
      };
    });

    this.onMedia.emit(new MediaPlaylist(entries, file.id));
  }
}
