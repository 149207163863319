import { Component, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Path } from '@s8l/client-tree-lib';

import { StatsServerService } from 'src/app/services/jsonrpc/stats-server.service';
import { TreeService } from 'src/app/services/tree.service';

@Component({
  selector: 'dashboard-quiz',
  templateUrl: './quiz-overview.component.html',
  styleUrls: ['./quiz-overview.component.scss']
})
export class QuizOverviewComponent implements OnInit {
  public lastScores = [];

  public resolvedTests;
  public score = 0;
  public scoreMax = 0;
  public scoreScale = '0%';
  public status = 'Expert';

  public displayDetails = false;

  constructor(private stats: StatsServerService, private tree: TreeService) {}

  public ngOnInit() {
    return this.stats.statsRecentGames({}).then(res => {
      this.lastScores = res.slice(0, 5);
      for (const score of this.lastScores) {
        score.node = this.tree.getByUUID(score.round);
        score.score.precentage = Math.floor((score.score.score / score.score.total_score) * 100);
        score.title = this.getTitle(score);

        this.score += score.score.score;
        this.scoreMax += score.score.total_score;
      }

      const scorePc = (this.score / this.scoreMax) * 100;
      this.scoreScale = scorePc.toString() + '%';

      if (scorePc <= 33) {
        this.status = translate('quiz.beginner');
      } else if (scorePc > 33 && scorePc <= 66) {
        this.status = translate('quiz.intermediate');
      } else {
        this.status = translate('quiz.expert');
      }
    });
  }

  private getTitle(score) {
    if (score.type != 'default') {
      return score.type;
    }

    const path = score?.node?.path || score?.meta?.path;
    if (!path) {
      return score.type;
    }

    return Path.parse(path).removeFirst().removeLast().getLast();
  }

  public toggleDetails() {
    return (this.displayDetails = !this.displayDetails);
  }
}
